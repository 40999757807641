import {
    SET_PROJECT,
    SET_PROJECTS,
    SET_PROJECT_COMPANY,
    UPDATE_PROJECT_COMPANY_FIELD,
} from "./mutation.types";

const mutations = {
    [SET_PROJECT](state, project) {
        state.project = project;
    },
    [SET_PROJECTS](state, projects){
        state.projects = projects;
    },
    [SET_PROJECT_COMPANY](state, projectCompany){
        state.project.company = projectCompany;
    },
    [UPDATE_PROJECT_COMPANY_FIELD](state, {fieldObjectPath, value}){

        console.log(fieldObjectPath);
        console.log(value);

        fieldObjectPath.split(/[.[\]]+/).reduce((prev, key, index, array) => {
            if (array.length === index + 1) {
              // eslint-disable-next-line no-param-reassign
              prev[key] = value;
            }
        
            return prev[key];
          }, state.project);

        // Object.assign({}, state.project[field], value);
    },
}

export default mutations;