import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "select_container"
}
const _hoisted_2 = { class: "checkbox_container" }
const _hoisted_3 = ["onChange", "onUpdate:modelValue"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ControlWrapper = _resolveComponent("ControlWrapper")!

  return (_openBlock(), _createBlock(_component_ControlWrapper, {
    details: _ctx.details,
    errors: _ctx.errors
  }, {
    default: _withCtx(() => [
      (_ctx.localResponseData)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localResponseData, (item, index) => {
              return (_openBlock(), _createElementBlock("div", { key: index }, [
                _createElementVNode("div", _hoisted_2, [
                  _withDirectives(_createElementVNode("input", {
                    type: "checkbox",
                    class: "ControlElement",
                    name: "check",
                    onChange: ($event: any) => (_ctx.setSelectedValue(item)),
                    "onUpdate:modelValue": ($event: any) => ((item.isChecked) = $event)
                  }, null, 40, _hoisted_3), [
                    [_vModelCheckbox, item.isChecked]
                  ]),
                  _createTextVNode(" " + _toDisplayString(item.name), 1)
                ])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["details", "errors"]))
}