import {
    SET_INJURIES,
    SET_INJURY_BODY_PART_TYPES_STATISTICS,
} from "./mutation.types";
import {
    GET_INJURIES,
    GET_INJURY_BODY_PART_TYPES_STATISTICS,
} from "./action.types";
import InjuryServiceApi from "@/services/injury.service";

const actions = {
    async [GET_INJURIES]({ commit }, params) {
        InjuryServiceApi.get(params)
            .then(function (response) {
                commit(SET_INJURIES, response.data);
            })
            .catch(function (err) {
                commit(SET_INJURIES, []);
            });
    },
    async [GET_INJURY_BODY_PART_TYPES_STATISTICS]({ commit }, params) {
        InjuryServiceApi.getBodyPartTypesStatistics(params)
            .then(function (response) {
                commit(SET_INJURY_BODY_PART_TYPES_STATISTICS, response.data);
            })
            .catch(function (err) {
                commit(SET_INJURY_BODY_PART_TYPES_STATISTICS, []);
            });
    },
}

export default actions;