
import {
    computed,
    defineComponent,
    ref,
} from "vue";

export default defineComponent({
    name: "Input",
    props: {
        details: Object,
        errors: Array,
    },
    setup(props) {
        const details = computed(() => props.details);

        const errors = computed((): Array<object> => props.errors as Array<Object>);

        const hasError = computed((): boolean => {
            return errors != null && errors.value.length != null && errors.value.length > 0
        });

        return {
            details,
            errors,
            hasError,
        };
    },
});
