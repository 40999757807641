import {
    SET_FORM_SECTION_DATA,
    SET_FORM_SECTION_DATA_FORM_DATA,
    SET_FORM_SECTION_DATA_FIELD,
    SET_MULTIPLE_CHANGES,
    SET_FORM_DATA_ERRORS,
    DELETE_FORM_DATA_ERROR,
    FORM_DATA_SAVED,
} from "./mutation.types";

const mutations = {
    [SET_FORM_SECTION_DATA](state, formSectionData) {
        console.log("Nastavuji data", formSectionData);

        state.formSectionData = formSectionData;
        state.formSectionDataLoading = false;

        state.lastFormSavedDate = formSectionData.updatedDate;
    },
    [SET_FORM_SECTION_DATA_FORM_DATA](state, { name, value }) {
        console.log("Nastavuji data 2");

        if (state.formSectionData.formData == null) {
            state.formSectionData.formData = {};
        }

        state.formSectionData.formData[name] = value;

    },
    [SET_FORM_SECTION_DATA_FIELD](state, model) {
        console.log("nastavuji data field ", model);
        const currentFormData = state.formSectionData.formData;

        Object.assign(currentFormData, { [model.name]: model.data });

        console.log(state.formSectionData);
    },
    [SET_MULTIPLE_CHANGES](state, boolState) {
        state.multipleChanges = boolState;
    },
    [SET_FORM_DATA_ERRORS](state, formDataErrors) {
        state.formDataErrors = formDataErrors;
        state.formDataErrors2 = [...formDataErrors];
        console.log(state.formDataErrors2);
    },
    [DELETE_FORM_DATA_ERROR](state, model) {
        // console.log(state.formDataErrors2);

        let newErrors = state.formDataErrors.filter(s => s.fieldId != model.fieldId);
        // state.formDataErrors = state.formDataErrors.splice(state.formDataErrors.findIndex(s => s.fieldId != model.fieldId), 1);
        state.formDataErrors = [...newErrors];


        // console.log("cRrrrrrrrrrrrrrrrr", state.formDataErrors2);

    },
    [FORM_DATA_SAVED](state) {
        state.lastFormSavedDate = new Date();
    },
}

export default mutations;