import {
    ref,
    onMounted,
    computed,
    watch,
    onUpdated,
    onUnmounted,
} from 'vue';
import {
    useStore
} from "vuex";

import store from "@/store/index.js";


const getObjectDataField = function getObjectDataField(objectSchemeId, fieldId, DATA_TYPE) {
    // states
    // const store = useStore();
    DATA_TYPE = DATA_TYPE == null ? "storage" : DATA_TYPE;
    const loading = ref(true);

    // get object scheme (if not load set loading true)
    const getValue = () => {
        console.log("TADY ", objectSchemeId);
        let object = getObjectDataForObjectScheme(objectSchemeId);
        if (!object)
            return null;
        console.log("TADY ", object);

        let objectData = object["data"];
        if (!objectData)
            return null;

        let dataType = objectData[DATA_TYPE];
        if (!dataType)
            return null;

        var objectSchemeField = getObjectSchemeField(objectSchemeId, fieldId);
        if (!objectSchemeField || !objectSchemeField.name)
            return null;

        let value;
        if (objectSchemeField.name == "dataset") {
            value = dataType;
        } else {
            value = dataType[objectSchemeField.name];
        }

        loading.value = false;
        return value;
    }

    const fieldValueComputed = computed(() => {
        return getValue();
    });


    const getObjectSchemeField = (objectSchemeId, fieldId) => {
        console.log(store.getters.formTabObjects);
        const objectScheme = store.getters.formTabObjects.filter(
            (e) => e.id == objectSchemeId
        )[0];

        const field = objectScheme.fields.filter(
            (e) => e.id == fieldId
        )[0];

        return field;
    };

    const getObjectDataForObjectScheme = (objectSchemeId) => {
        if (!store || !store.getters)
            return null;

        if (!store.getters.objectData || store.getters.objectData.length <= 0)
            return null;

        const objectData = store.getters.objectData[objectSchemeId];
        return objectData;
    };


    return {
        getValue,
        loading,
        fieldValueComputed,
    }
}

export default {
    getObjectDataField,
}