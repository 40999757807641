
import { defineComponent, ref } from "vue";

import ApplicationCard from "./ApplicationCard.vue";

const props = {
  team: {
    type: Object,
  },
};

export default defineComponent({
  name: "Form",
  components: { ApplicationCard },
  props,
  setup(props) {
    const team = ref(props.team);

    return {
      team,
    };
  },
});
