
// import { mapActions, mapGetters } from "vuex";
import { defineComponent } from "vue";

export default defineComponent({
  name: "FormSetting",
  data() {
    return {
      open: false,
    };
  },
  methods: {
    closeWindow() {
      this.open = false;
    },
  },
});
