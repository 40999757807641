import { createRouter, createWebHistory, RouteRecordRaw, RouterView } from 'vue-router';
import { userService } from "@/services/user.service";

import Dashboard from '../views/dashboard/Dashboard.vue';
import FormPackage from '../views/form/FormPackage.vue';
import Login from '../views/login/Login.vue';
import InjuryTable from '../views/injury/InjuryTable.vue';
import InjuryData from '../views/injury/InjuryData.vue';
import FormBase from "../views/form/Form.vue";
import FormInfo from "../views/form/FormInfo.vue";
import FormAdd from "../views/form/FormAdd.vue";
import App from "../views/app/App.vue";
import UserProfile from "../views/user/UserProfile.vue";
import ProjectSetting from "../views/project/setting/ProjectSetting.vue";
import UserSetting from "../views/project/user/UserSetting.vue";
import UserAdd from "../views/project/user/UserAdd.vue";
import FormSection from "../views/form/FormSection.vue";
import FormSchemeInfo from "../views/form/FormSchemeInfo.vue";
import FormPackages from "../views/form/FormPackages.vue";
import Team from "../views/team/Team.vue";
import TeamSetting from "../views/team/TeamSetting.vue";

// import AppCompany from "../AppCompany.vue";

import CompanyTable from "@/views/superAdmin/CompanyTable.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    meta: { layout: "Login" },
    component: Login
  },
  {
    path: '/chooseTeam',
    alias: '/',
    name: 'chooseTeam',
    meta: { breadcrumb: 'ChooseTeam', layout: "App" },
    component: Team,
  },
  {
    path: '/CompanyTable',
    name: "companyTable",
    meta: { breadcrumb: 'Applikace', layout: "App" },
    component: CompanyTable
  },
  {
    path: '/404',
    name: '404',
    meta: { layout: "Login" },
    component: Login
  },
  {
    path: '/:team/nastaveni',
    name: 'teamSetting',
    meta: { breadcrumb: 'Nastevení team', layout: "App" },
    component: TeamSetting,
  },
  {
    path: '/moje-osoba/profile',
    name: 'userProfile',
    meta: { breadcrumb: 'UserProfile', layout: "App" },
    component: UserProfile,
  },
  {
    path: '/:team/:application/projects',
    name: 'app',
    meta: { breadcrumb: 'Applikace', layout: "App" },
    component: App
  },
  {
    path: '/:team/:application/projects/:projectId',
    component: RouterView,
    name: 'company',
    meta: { breadcrumb: 'Projekty' },
    beforeEnter: async (to, from, next) => {
      // controll if object name is existing company name and USER HAS RIGHT FOR THAT COMPANY
      const objectName = to.params.company;
      // if (objectName == "safeko")
      return next();
    },
    redirect: {
      name: 'app'
    },
    children: [
      {
        path: 'dashboard',
        alias: '',
        name: 'dashboard',
        meta: { breadcrumb: 'Dashboard' },
        component: async () => {
          return Dashboard;
        },
      },
      {
        path: 'settings',
        name: 'ProjectSetting',
        meta: { breadcrumb: 'Nastavení' },
        component: ProjectSetting,
      },
      {
        path: 'users',
        name: 'ProjectUsers',
        meta: { breadcrumb: 'Uživatelé' },
        component: UserSetting,
      },
      {
        path: 'users/add',
        name: 'ProjectUserAdd',
        meta: { breadcrumb: 'Přídání uživatele' },
        component: UserAdd,
      },
      {
        path: 'package',
        name: 'FormPackages',
        component: FormPackages,
      },
      {
        path: 'package/:formPackageId',
        name: 'Package',
        component: FormPackage,
        meta: { breadcrumb: 'Form package' },
        redirect: {
          name: 'FormInfo'
        },
        children: [
          {
            path: 'form/:fpfsid',
            name: 'FormScheme',
            component: FormBase,
            meta: { breadcrumb: 'Form' },
            children: [
              {
                path: 'pepa',
                name: 'FormSection',
                component: FormSection,
                meta: { breadcrumb: 'Form section' },
              },
              // {
              //   path: 'info',
              //   name: 'FormSchemeInfo',
              //   component: FormSchemeInfo,
              //   meta: { breadcrumb: 'Form scheme info' },
              // }
            ],
          },
          {
            path: 'form/:fpfsid/info',
            name: 'FormSchemeInfo',
            component: FormSchemeInfo,
            meta: { breadcrumb: 'Form scheme info' },
          },
          {
            path: 'info',
            // alias: '',
            name: 'FormInfo',
            component: FormInfo,
            meta: { breadcrumb: 'Form info' },
          },
          {
            path: 'add',
            name: 'FormAdd',
            component: FormAdd,
            meta: { breadcrumb: 'Form add' },
          }
        ]
      },
      {
        path: 'knihaurazu',
        name: 'injuryTable',
        component: InjuryTable,
        meta: { breadcrumb: 'Kniha úrazů' },
      },
      {
        path: 'injurydata',
        name: 'injuryData',
        component: InjuryData,
        meta: { breadcrumb: 'Data úrazů' },
      },
    ]
  },

  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  // Control project url for user 
  // console.log(to.params);
  // if(!to.params.project){
  //   next('/safeko/dashboard')
  // }

  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = userService.getUser() != null;

  // if page require authentication and user 
  // is not logged in - redirect to login page
  if (authRequired && !loggedIn) {

    return next({
      path: '/login',
      query: {
        returnUrl: encodeURIComponent(to.fullPath),
      }
    });
  }

  // if page doesn't exist in routes
  // redirect to main / default page
  if (to.matched.length <= 0) {
    return next("/")
  }

  next();
});

export default router
