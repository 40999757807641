/* eslint-disable */

import {
    API_URL
} from "@/common/config";
import client from '@/helpers/axios.api';

const getPackages = function getPackages() {
    return client.get(`${API_URL}/form-manager/form-packages`);
};

const getPackage = function getPackage(formPackageId) {
    return client.get(`${API_URL}/form-manager/form-packages/${formPackageId}`);
};

const getPackageSchemes = function getPackageSchemes(formPackageId) {
    return client.get(`${API_URL}/form-manager/form-packages/${formPackageId}/form-schemes`);
};

const getPackageScheme = async function getPackageScheme(formPackageFormSchemeId) {
    try {
        return await client.get(`${API_URL}/form-manager/form-packages/form-schemes/${formPackageFormSchemeId}`);
    } catch (e) {
        return null;
    }
};

const deletePackageScheme = function deletePackageScheme(formPackageFormSchemeId) {
    return client.delete(`${API_URL}/form-manager/form-packages/form-schemes/${formPackageFormSchemeId}`);
};


const getPackageSchemeVersion = function getPackageSchemeVersion(formPackageFormSchemeVersionId) {
    return client.get(`${API_URL}/form-manager/form-packages/form-scheme-versions/${formPackageFormSchemeVersionId}`);
};

const addFormPackage = function addFormPackage(formPackage) {
    return client.post(`${API_URL}/form-manager/form-packages`, formPackage);
};

const addFormSchemeToPackage = function (model) {
    return client.post(`${API_URL}/form-manager/form-packages/${model.formPackageId}/form-schemes`, model);
}

const addFormPackageWithFormSchemes = function addFormPackageWithFormSchemes(model) {
    return client.post(`${API_URL}/form-manager/form-packages-form-schemes`, model);
};

const deleteFormPackage = async function deleteFormPackage(formPackageId) {
    try {
        return await client.delete(`${API_URL}/form-manager/form-packages/${formPackageId}`);
    } catch (e) {
        return null;
    }
};

const getFormTabObjects = async function getFormTabObjects(formTabId) {
    if (!formTabId)
        return null;

    try {
        return await client.get(`${API_URL}/form-manager/form-packages/form-tab/${formTabId}/objects`);
    } catch (e) {
        return null;
    }
};

const getObjects = async function getObjects(formPackagesId) {
    try {
        return await client.post(`${API_URL}/form-manager/form-packages/objects-data`,
            {
                injuryState: 2
            });
    } catch (e) {
        return null;
    }
};

export default {
    getPackage,
    getPackageSchemes,
    getPackageScheme,
    getPackageSchemeVersion,
    getPackages,
    addFormPackage,
    addFormSchemeToPackage,
    addFormPackageWithFormSchemes,
    deleteFormPackage,
    deletePackageScheme,
    getFormTabObjects,
    getObjects,
};