/* eslint-disable */
// UserLocalStorageService.js
const UserLocalStorageService = (function () {
    const USER_LOCAL_STORAGE_NAME = "user";

    var _service;

    function _getService() {
        if (!_service) {
            _service = this;
            return _service
        }
        return _service
    }

    function _setUser(user) {
        localStorage.setItem(USER_LOCAL_STORAGE_NAME, JSON.stringify(user));
    }

    function _getRefreshToken() {
        var user = _getUser();
        return user.refreshToken;
    }

    function _getUser() {
        var userStringify = localStorage.getItem(USER_LOCAL_STORAGE_NAME);
        var user = JSON.parse(userStringify);
        return user;
    }


    function _clearUser() {
        localStorage.removeItem(USER_LOCAL_STORAGE_NAME);
    }

    return {
        getService: _getService,
        setUser: _setUser,
        getRefreshToken: _getRefreshToken,
        getUser: _getUser,
        clearUser: _clearUser
    }
})();

export default UserLocalStorageService;