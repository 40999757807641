
import {
  API_URL
} from "@/common/config";
import client from '@/helpers/axios.api';

import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  watch,
  computed,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { mapActions, mapGetters, useStore } from "vuex";
// import FormBase from "./Form.vue";

import {
  GET_FORM_PACKAGE,
  GET_FORM_PACKAGE_SCHEME,
  DELETE_FORM_PACKAGE_SCHEME,
} from "@/store/formPackage/action.types";

import { SET_FORM_PACKAGE_SCHEME } from "@/store/formPackage/mutation.types";
import { SET_FORM_DATA_ERRORS } from "@/store/formData/mutation.types";

import {
  GET_FORM_SCHEME,
  GET_FORM_SECTION,
} from "@/store/formStructure/action.types";

import { SET_FORM_SECTION, SET_FORM_SCHEME } from "@/store/formStructure/mutation.types";

import { GET_FORM_DATA } from "@/store/formData/action.types";

import { GET_OBJECT_SCHEMAS } from "@/store/formObjectStructure/action.types";

import FormHistoryTab from "./FormHistoryTab.vue";

import { stopObjectsScope } from "./objectServices/formObject2.js";

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();

    const fpfsid = computed(() => route.params.fpfsid);
    const fpfsvid = computed(() => route.query.fpfsvid);
    const formPackageId = computed(() => route.params.formPackageId);
    const formSectionId = computed(() => route.params.formSectionId);
    const formSectionId2 = computed(() => route.query.sid);

    const routeName = computed(() => route.name);

    const store = useStore();

    const formSchemes = computed(() => store.getters.formSchemes);

    const formPackageSchemeVersions = computed(
      () => store.getters.formPackageSchemeVersions
    );

    const currentCompanyComputed = computed(() => store.getters.currentCompany);
    const currentCompany = currentCompanyComputed.value;

    onMounted(() => {
      console.log("MOUNTED PACKAGE");
      store.dispatch(GET_FORM_PACKAGE, formPackageId.value);

      if (fpfsid.value != null) {
        const formSchemeRequest = store.dispatch(
          GET_FORM_PACKAGE_SCHEME,
          fpfsid.value
        );
      }
    });

    let controller = new AbortController();
    const getVersionScheme = (fsvid) => {

      controller.abort();
      controller = new AbortController();

      const formVersionSchemeRequest = store.dispatch(GET_FORM_SCHEME, {
        formSchemeVersionId: fsvid,
        controller: controller,
      });

      return formVersionSchemeRequest;
    };

    const getSection = (FPFSVid, Sid) => {
      const query2 = Object.assign({}, route.query, {
        sid: Sid,
      });

      router.push({
        name: "FormSection",
        query: query2,
      });

      const sectionRequest = store.dispatch(GET_FORM_SECTION, {
        formSchemeVersionId: FPFSVid,
        sectionId: Sid,
      });

      return sectionRequest;
    };

    const getData = (FPFSVid, Sid) => {
      const sectionRequest = store.dispatch(GET_FORM_DATA, {
        formSchemeVersionId: FPFSVid,
        sectionId: Sid,
      });
      return sectionRequest;
    };


    watch(fpfsvid, (val) => {
      store.commit(SET_FORM_DATA_ERRORS, []);
    });

    watch(
      () => route.query.fsvid,
      (newVal) => {
        if (!newVal) return;
        const formVersionSchemeRequest = getVersionScheme(newVal);
      },
      { immediate: true }
    );


    // this open section in form - navagitaion + fields
    let formSections = computed(() => store.getters.formSections);
    watch(
      formSections,
      (newVal) => {
        if (!newVal || newVal.length <= 0)
          return;

        if (formSectionId2.value != null) return;

        const firstSection = newVal[0];

        if (firstSection == null) {
          store.commit(SET_FORM_SECTION, {});
          return;
        }

        if (!fpfsvid)
          return;

        const query2 = Object.assign({}, route.query, {
          sid: firstSection.id,
        });

         router.replace({
           name: "FormSection",
           query: query2,
         });
      }
    );

    watch(
      () => route.query.sid,
      (newVal, oldVal) => {
        if (!newVal) return;

        const FPFSVid = route.query.fpfsvid;
        const FSVid = route.query.fsvid;
        const Sid = route.query.sid;

        if (!(FPFSVid && FSVid && Sid)) return;

        console.log(
          "Spouštím getters sid pro data",
          FPFSVid,
          Sid,
          route.query.sid
        );

        stopObjectsScope();

        const sectionRequest = getSection(FSVid, Sid); // SPATN2 FSVID
        const dataRequest = getData(FPFSVid, Sid);
      },
      { immediate: true }
    );


    watch(
      () => route.query.fid,
      (newVal) => {
        if (!newVal) return;

        const Sid = route.query.sid;
        const FPFSVid = route.query.fpfsvid;
        if (!Sid) return;

        const dataRequest = getData(FPFSVid, Sid);
      }
    );

    // after click formScheme (info .vue)
    watch(fpfsid, (fpfsid) => {
      if (!fpfsid)
        return;

      const formSchemeRequest = store.dispatch(
        GET_FORM_PACKAGE_SCHEME,
        fpfsid,
      );
    });

    const changeFormScheme = async (formSchemeId) => {
      // stop change url (route push) if path is same
      if (fpfsid.value == formSchemeId)
        return;

      controller.abort();
      store.commit(SET_FORM_PACKAGE_SCHEME, { schemeVersions: [] });
      store.commit(SET_FORM_SCHEME, {});

      router.push({
        name: "FormSchemeInfo",
        params: {
          fpfsid: formSchemeId,
        },
      });
    };

    const changeToFormPackageInfo = (formPackageId) => {
      router.push({
        name: "FormInfo",
        params: {
          formPackageId: formPackageId,
        },
      });
    };

    const changeToAddForm = (formPackageId) => {
      router.push({
        name: "FormAdd",
        params: {
          formPackageId: formPackageId,
        },
      });
    };

    const deletePackageScheme = (formPackageFormSchemeId) => {
      let deleteFormPackageScheme = store.dispatch(DELETE_FORM_PACKAGE_SCHEME, formPackageFormSchemeId);
      deleteFormPackageScheme.then(() => {
        if (fpfsid.value == formPackageFormSchemeId) {
          changeToFormPackageInfo(formPackageId.value);
        }
        store.dispatch(GET_FORM_PACKAGE, formPackageId.value);
      });
    };

    const formSchemeMenuTabContext = () => {
      console.log("show context menu!");
    }


    return {
      fpfsid,
      fpfsvid,
      formPackageId,
      formSectionId,
      formSectionId2,
      changeFormScheme,
      changeToFormPackageInfo,
      routeName,
      changeToAddForm,
      formSchemeMenuTabContext,
      deletePackageScheme,
      formPackageSchemeVersions,
      formSchemes,
      formSections,
    };
  },
  name: "FormPackage",
  components: {
    FormHistoryTab,
  },
});
