import formObject from "../objectServices/formObject.js";
import {
  computed,
} from 'vue';

const getObjectData = function (field, DATA_TYPE) {
  if (!(field.objectFieldJoins && field.objectFieldJoins.length > 0))
    return;

  let objectField = field.objectFieldJoins[0];

  const {
    getValue,
    loading
  } = formObject.getObjectDataField(
    objectField.objectId,
    objectField.id,
    DATA_TYPE,
  );

  return {
    value: getValue,
    loading: loading
  };
};

const getObjectData2 = function (objectField, DATA_TYPE) {
  if (objectField == null)
    return;

  const {
    getValue,
    loading,
    fieldValueComputed,
  } = formObject.getObjectDataField(
    objectField.objectId,
    objectField.id,
    DATA_TYPE,
  );

  return {
    value: getValue,
    loading: loading
  };
};

import {
  SET_OBJECT_DATA_FIELD
} from "@/store/formObjectData/mutation.types";

const setObjectData = function (field, DATA_TYPE, value, store) {
  if (!(field.objectFieldJoins && field.objectFieldJoins.length > 0))
    return;

  let objectField = field.objectFieldJoins[0];

  let model = {
    [objectField.name]: value
  };

  store.commit(SET_OBJECT_DATA_FIELD, {
    type: DATA_TYPE,
    objectId: objectField.objectId,
    data: model,
  });
}

const setObjectData2 = function (fieldJoin, DATA_TYPE, value, store) {
  if (!fieldJoin)
    return;

  let model = {
    [fieldJoin.name]: value
  };

  store.commit(SET_OBJECT_DATA_FIELD, {
    type: DATA_TYPE,
    objectId: fieldJoin.objectId,
    data: model,
  });
}

export default {
  getObjectData,
  setObjectData,
  getObjectData2,
  setObjectData2,
}