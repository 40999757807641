/* eslint-disable */

import {
    API_URL
} from "@/common/config";
import client from '@/helpers/axios.api';


const getProject = async function getProject(key) {
    try {
        return await client.get(`${API_URL}/project-manager/projects/${key}`);
    } catch (e) {
        return null;
    }
};

const getProjects = async function getProject(team) {
    try {
        return await client.get(`${API_URL}/project-manager/projects`, {
            params: {
                team: team
            }
        });
    } catch (e) {
        return null;
    }
};

const updateProject = async function updateProject(project) {
    try {
        return await client.post(`${API_URL}/project-manager/projects/${project.key}`, project);
    } catch (e) {
        return null;
    }
}

const updateProjectCompany = async function updateProjectCompany(project) {
    try {
        return await client.post(`${API_URL}/project-manager/projects/${project.key}/company`, project.company);
    } catch (e) {
        return null;
    }
}

const getProjectUsers = async function getProjectUsers(model) {
    try {
        return await client.get(`${API_URL}/project-manager/projects/users`, {
            params: {
                ...model,
            }
        });
    } catch (e) {
        return null;
    }
}

export default {
    getProject,
    getProjects,
    updateProject,
    getProjectUsers,
    updateProjectCompany,
};