import {
    SET_OBJECT_SCHEMAS
} from "./mutation.types";

const mutations = {
    [SET_OBJECT_SCHEMAS](state, objectSchemas) {
        state.objectSchemas = objectSchemas;
    },
}

export default mutations;