/* eslint-disable */

import {
    API_URL
} from "@/common/config";
import client from '@/helpers/axios.api';


const searchCompany = async function searchCompany(key) {
    try {
        return await client.get(`${API_URL}/ares-manager/search`, { params: { name: key } });
    } catch (e) {
        return null;
    }
};

export default {
    searchCompany
};