/* eslint-disable */

import {
    API_URL
} from "@/common/config";
import client from '@/helpers/axios.api';

const get = async function get(params) {
    return client.get(`${API_URL}/injuries`, {
        params: params
    });
};

const getStatisticsData = async function getStatisticsData(params) {
    try {
        return client.get(`${API_URL}/injury-manager/injury-statistics`, {
            params: params
        });
    } catch (e) {
        return null;
    }
};

const getDynamic = async function getInjuryDynamic() {
    try {
        return client.get(`${API_URL}/injury-manager/injuriesDynamic`);
    } catch (e) {
        return null;
    }
}

const getBodyPartTypesStatistics = async function getBodyPartTypesStatistics() {
    try {
        return client.get(`${API_URL}/injury-manager/getBodyPartTypesStatistics`);
    } catch (e) {
        return null;
    }
}

export default {
    get,
    getStatisticsData,
    getDynamic,
    getBodyPartTypesStatistics,
}