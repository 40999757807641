import {
    LOGIN_FAILURE,
    LOGIN_SUCCESS,
    LOGOUT_REQUEST,
} from "./mutation.types";
import {
    LOGIN,
    LOGOUT
} from "./action.types";
import {userService} from "@/services/user.service";
import router from  '@/router/index';

const actions = {
    async [LOGIN](context, { username, password }){
        const login = userService.login(username, password);
        login.then(
            user => {
                context.commit(LOGIN_SUCCESS, user);
            },
            error => {
                context.commit(LOGIN_FAILURE, error);
            }
        );
        return login;    
    },
    async [LOGOUT](context){
        userService.logout();
        router.push('/login');
        context.commit(LOGOUT_REQUEST);
    }, 
}

export default actions;