
import { defineComponent, onMounted, computed, ref, watch, watchEffect } from "vue";
import { useStore } from "vuex";
import objectControl from "./object.js";


import controlBase from "./base/controlBase";
import ControlWrapper from "./ControlWrapper.vue";
import MultiCheckboxes from "./types/MultiCheckboxes";
import { ICheckBox } from "./interfaces/ICheckBox";

import { objects, changeObjectData } from "../objectServices/formObject2.js";

export default defineComponent({
  name: "MultiCheckbox",
  props: {
    details: Object,
    errors: Array,
  },
  components: {
    ControlWrapper,
  },
  emits: ["formDataChange", "formDataSave"],
  setup(props, { emit }) {
    const store = useStore();
    let localResponseData = ref();

    let multiCheckboxes: MultiCheckboxes = new MultiCheckboxes(props, emit);

    let {
      field,
      refData,
      localData,
      save,
    } = controlBase<MultiCheckboxes, ICheckBox[]>(multiCheckboxes);

    onMounted(() => {
    });

    var joinedObject = field.objectFieldJoins.filter(s => s.objectFieldType == "response")[0];
    const objectIsLoad = computed(() => {
      let objects2 = objects.value;
      if (!objects2)
        return null;

      const object = objects2[joinedObject.objectId];
      if (!object) 
        return null;

      return object.isLoaded;
    });

    const objectData = computed(() => {
      let objects2 = objects.value;
      if (!objects2)
        return null;

      const object = objects2[joinedObject.objectId];
      if (!object) 
        return null;

      return object.data;
    });

    let wtch = watch(
      () => [objectData, objectIsLoad],
      ([data, isLoad]) => {
        if (!isLoad.value)
          return;

        if(data.value && data.value.response) {
          localResponseData.value = data.value.response;
          wtch();
        }
        else {
          console.log("JSEMTUAMENIMLOL")
          changeObjectData({
            objectSchemeId: joinedObject.objectId,
            objectData: { data: { id: 1 } },
            isHard: true,
            dataType: "query"
          });
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );

    const setSelectedValue = (item) => {
      console.log(item);
      // item.isChecked =  item.isChecked == null ? false: !item.isChecked;
      localData.value = localResponseData.value.filter(s => s.isChecked);
      save();
    };

    let unwatchPepa = watchEffect(() => {
      if (localData.value != null && Array.isArray(localData.value) && localResponseData.value != null) {
        console.log("pupadupy", localData.value);

        localData.value.forEach((item) => {
          localResponseData.value.find(s => s.id == item.id).isChecked = item.isChecked;
        });
        unwatchPepa();
      }
    });

    return {
      save,
      localData,
      refData,
      setSelectedValue,
      localResponseData,
    };
  },
});
