
import { mapActions, mapGetters, mapMutations, useStore } from "vuex";
import { defineComponent, computed, onUnmounted, watch, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import Input from "./controls/Input.vue";
import Textarea from "./controls/Textarea.vue";
import Select from "./controls/Select.vue";
import MultiCheckbox from "./controls/MultiCheckbox.vue";
import MultiSelect from "./controls/MultiSelect.vue";
import MultiSelectAsync from "./controls/MultiSelectAsync.vue";
import InputDate from "./controls/InputDate.vue";
import InputDatetime from "./controls/InputDatetime.vue";
import Checkbox from "./controls/Checkbox.vue";
import Number from "./controls/Number.vue";

import { loadObjects } from "./objectServices/formObject2.js";

import { GET_FORM_SECTION } from "@/store/formStructure/action.types";
import { SET_FORM_SECTION } from "@/store/formStructure/mutation.types";

import { GET_FORM_TAB_OBJECTS } from "@/store/formPackage/action.types";


import { SAVE_FORM_DATA, GET_FORM_DATA } from "@/store/formData/action.types";
import {
  SET_FORM_SECTION_DATA_FORM_DATA,
  SET_FORM_SECTION_DATA,
} from "@/store/formData/mutation.types";
import object from "./controls/object";

export default defineComponent({
  name: "FormSection",
  components: {
    Input,
    Textarea,
    Select,
    MultiCheckbox,
    MultiSelect,
    MultiSelectAsync,
    InputDate,
    InputDatetime,
    Checkbox,
    Number,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const formSectionId = computed(() => route.query.sid);
    const formTabId = computed(() => route.query.fpfsvid);

    const objectSchemes = computed(() => store.getters.formTabObjects);

    const formDataErrors = computed(() => store.getters.formDataErrors);

    const formSectionData = computed(() => store.getters.formSectionData);
    const formSection = computed(() => store.getters.formSection);

    const debugMode = ref(false);

    const controls = {
      input: "Input",
      textarea: "Textarea",
      select: "Select",
      // multiCheckbox: "Select",
      multiCheckbox: "MultiCheckbox",
      multiSelect: "MultiSelect",
      multiSelectAsync: "MultiSelectAsync",
      date: "InputDate",
      datetime: "InputDatetime",
      checkbox: "Checkbox",
      number: "Number",
    };

    onMounted(() => {
      console.log("SECTION MOUNTED");
      store.dispatch(GET_FORM_TAB_OBJECTS, formTabId.value);
    });

    const debugModeSwitch = () => {
      debugMode.value = !debugMode.value;
    };

    const getErrors = (field) => {
      let errors = formDataErrors.value.filter(s => s.sectionId == formSectionId.value && s.fieldId == field.id);
      return errors;
    }

    const saveSectionData = () => {
      store.dispatch(SAVE_FORM_DATA, {
        formId: formTabId.value,
        data: formSectionData.value,
        formSectionId: formSectionId.value,
      });
    };

    const formDataChange = (input) => {
      store.commit(SET_FORM_SECTION_DATA_FORM_DATA, input);
    };


    loadObjects();

    onUnmounted(() => {
      console.log("UNOUMTEMDM");
      store.commit(SET_FORM_SECTION, {});
    });

    watch(formSectionId, () => {
      store.commit(SET_FORM_SECTION, {});
    });

    return {
      objectData: computed(() => store.getters.objectData),
      objectSchemes,
      formSectionId,
      formDataErrors,
      saveSectionData,
      formDataChange,
      formSection,
      controls,
      getErrors,
      debugMode,
      debugModeSwitch,
    };
  },
});
