<template>
  <div class="team_setting_container">
    <div class="UserPhoto">
      <img src="@/assets/icons/layout/head/avatar.png" />
      <div class="UserPhoto__title">
        Zeman team
      </div>
    </div>
    <div class="UserProfilePage">
      <div class="UserProfilePage__title">Základni informace o týmu</div>
      <div class="UserProfilePage__description">
        Informace o týmu
      </div>
    </div>
    <div class="CardForm UserBasics">
      <!-- <div class="CardForm__title">Základní informace</div> -->
      <div class="CardForm__description"></div>
      <div
        class="CardForm__input CardFormInput"
        v-for="i in 2"
        :key="i"
      >
        <div class="CardFormInput__title">Název</div>
        <div class="CardFormInput__text">
          <input type="text" value="Zeman" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
/* eslint-disable */

import { defineComponent, computed } from "vue";

import { useStore } from "vuex";

import '@/assets/setting.scss';

export default defineComponent({
  name: "UserProfile", 
  setup() {
    const store = useStore();

    return {
    }
  }
});
</script>

<style lang="scss">

.team_setting_container {
  max-width: 800px;
  background: white;
  margin: auto;
  padding: 25px;

  .UserPhoto {
    margin: auto;
    text-align: center;

    img {
      width: 100px;
    }

    &__title {
      font-size: 1.4em;
      font-weight: 600;
      margin-top: 10px;
    }
  }

  .UserProfilePage {
    margin-top: 30px;

    &__title {
      font-size: 1.4em;
      font-weight: 600;
    }

    &__description {
      margin-top: 5px;
    }
  }
}
</style>