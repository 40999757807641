import { IControl } from "../interfaces/IControl";

export default class DateControl implements IControl<Date> {
    props: any;
    emit: any;

    value: Date;

    constructor(props: any, emit: any) {
        this.props = props;
        this.emit = emit;
    }

    checkData(date: Date): boolean {
        let isValid: boolean = false;

        console.log("TTTTTTTTTTTTTTTTTTTTTTTTTT ", date);

        if (!date)
            return isValid;

        if (!this.isValidDate(new Date(date)))
            return isValid;

        isValid = true;
        return isValid;
    }

    isValidDate(date: Date): boolean {
        console.log(date);
        console.log(typeof date);
        console.log(date.valueOf());

        console.log(date instanceof Date);
        console.log(!isNaN(date.valueOf()));
        
        return date instanceof Date && !isNaN(date.valueOf());
    }
}
