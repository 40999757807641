/* eslint-disable */

import {
    API_URL
} from "@/common/config";
import client from '@/helpers/axios.api';
import UserLocalStorageService from "./user.local.storage";

export const userService = {
    login,
    logout,
    getUser,
    getPerson,
};

const userLocalStorageService = UserLocalStorageService.getService();

function login(username, password) {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json'
        },
    };
    return client.post(`${API_URL}/auth-manager/Login`, {
            Email: username,
            Password: password
        }, requestOptions)
        .then(response => {
            const user = response.data;
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                userLocalStorageService.setUser(user);
            }

            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    userLocalStorageService.clearUser();
}

function getUser(){
    return userLocalStorageService.getUser();
}

async function getPerson(userId) {
    try {
        return await client.get(`${API_URL}/user-manager/users/${userId}/person`);
    } catch (e) {
        return null;
    }
}