
import {
  computed,
  defineComponent,
  ref,
} from "vue";

import { useStore } from "vuex";

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import { cs } from 'date-fns/locale';

import controlBase from "./base/controlBase";
import ControlWrapper from "./ControlWrapper.vue";
import DateControl from "./types/DateControl";

export default defineComponent({
  name: "Input",
  props: {
    details: Object,
    errors: Array,
  },
  components: {
    ControlWrapper,
    VueDatePicker,
  },
  emits: ["formDataChange", "formDataSave"],
  setup(props, { emit }) {
    // states
    const store = useStore();
    const date = ref();

    let dateControl : DateControl = new DateControl(props, emit);

    let {
      field,
      getStorageObjectData,
      refData,
      errors,
      localData,
      save,
      hasError,
      setDataFromStorage,
    } = controlBase(dateControl);


    const formatDate = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      const hour = date.getHours();
      const minute = date.getMinutes();
      const second = date.getSeconds();

      return `${day}.${month}. ${year} ${hour}:${minute}:${second}`;
    }

    return {
      refData,
      localData,
      field,
      save,
      date,
      formatDate,
      cs,
      errors,
      getStorageObjectData,
      setDataFromStorage,
    };
  },
});
