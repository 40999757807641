import {
    onMounted,
    computed,
    watch,
    onUnmounted,
    effectScope,
    ref,
} from 'vue';

import formObjectLoad from "./formObjectLoad.js";
import formObjectStorage from "./formObjectStorage.js";
import formObjectSearch from "./formObjectSearch.js";

import {
    CLEAR_OBJECT_DATA,
} from "@/store/formObjectData/mutation.types";

import {
    SET_FORM_TAB_OBJECTS,
} from "@/store/formPackage/mutation.types";


import store from "@/store/index.js";

export let objects = ref({});

let scope = effectScope(true);


export const changeObjectData = ({ objectSchemeId, objectData, isHard, dataType }) => {
    let object = objects.value[objectSchemeId];

    console.log("že by jo", object);
    console.log("že by jo", objects.value);
    console.log("že by jo", objectData);

    console.log(objectSchemeId);

    objects.value[objectSchemeId].changeData({
        data: objectData.data,
        isHard: isHard,
        dataType: dataType,
    });
}


export const stopObjectsScope = function() {
    objects.value = {};
    scope.stop();

    console.log("UNMOUNTED CLEAR OBJECT DATAAAA");
    store.commit(CLEAR_OBJECT_DATA);
    store.commit(SET_FORM_TAB_OBJECTS, []);
}

export const loadObjects = function() {
    console.log("LOAUDUJU nové objects scope");
    const objectSchemas = computed(() => store.getters.formTabObjects);
    scope = effectScope(true);

    // onUnmounted(() => {
    //    stopObjectsScope();
    // });

    watch(
        () => objectSchemas,
        (val) => {

            if (!val.value || Object.keys(val.value).length == 0)
                return;

            for (const objectSchema of val.value) {

                if (objectSchema.type == "Load") {
                    scope.run(() => {
                        objects.value[objectSchema.id] = formObjectLoad.useFormObjectLoad(objectSchema, changeObjectData);
                        objects.value[objectSchema.id].options.loadObjectData = () => objects.value[objectSchema.id].loadObjectData(store);
                        objects.value[objectSchema.id].load();
                        objects.value[objectSchema.id].pepa = "ano je tu";
                    });
                }
                if (objectSchema.type == "Save") {
                    scope.run(() => {
                        objects.value[objectSchema.id] = formObjectStorage.useFormObjectStorage(objectSchema);
                        objects.value[objectSchema.id].loadObjectData();
                    });
                }
                if (objectSchema.type == "Search") {
                    scope.run(() => {
                        objects.value[objectSchema.id] = formObjectSearch.useFormObjectSearch(objectSchema, changeObjectData);
                        objects.value[objectSchema.id].options.loadObjectData = () => objects.value[objectSchema.id].loadObjectData(store);
                        objects.value[objectSchema.id].load();
                    });
                }
            }
        }, {
        deep: true,
        immediate: true,
    }
    );

}


