<template>
  <router-link :to="item.link == null ? '/404' : item.link" class="menu_item">
    <div
      v-if="!item.icon"
      class="icon"
      v-html="require(`../../../assets/icons/layout/menu/edit.svg`)"
    ></div>
    <div
      v-else
      class="icon"
      v-html="require(`@/assets${item.icon}`)"
    ></div>
    <div class="title">
      {{ item.name }}
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    item: Object,
  },
};
</script>

<style lang="scss">
.menu_item {
  padding: 12px 15px;
  transition: 0.5s;
  font-weight: 400;
  cursor: pointer;
  display: block;
  border-left: 4px solid transparent;



  &.router-link-active, &.router-link-exact-active {
    background: #f0f0f0;
  border-left: 4px solid #aa1f27;
  font-weight: 600;

    // background: #aa1f27;
    // color: white !important;

    // .icon {
    //   svg {
    //     path {
    //       fill: white;
    //     }
    //   }
    // }
  }

  &:hover,
  .router-link-active {
    background: #F5F5F5;
    // background: #aa1f27;
    // color: white !important;

    // .icon {
    //   svg {
    //     path {
    //       fill: white;
    //     }
    //   }
    // }
  }

  .icon {
    display: inline-block;
    padding-right: 25px;

    svg {
      width: 20px;
      height: auto;
      vertical-align: middle;
      transition: 5s;

      path {
        transition: 0.5s;

        fill: black;
      }
    }
  }

  .title {
    display: inline-block;
    vertical-align: bottom;
    font-size: 1em;
  }
}
</style>