import {
    SET_FORM_PACKAGE,
    SET_FORM_PACKAGE_SCHEME,
    SET_FORM_PACKAGE_SCHEMES,
    SET_FORM_PACKAGE_SCHEME_VERSION,
    SET_FORM_PACKAGES,
    SET_FORM_TAB_OBJECTS,
    SET_FORM_PACKAGES_OBJECTS,
} from "./mutation.types";

const mutations = {
    [SET_FORM_PACKAGE](state, formPackage) {
        state.formPackage = formPackage;
    },
    [SET_FORM_PACKAGE_SCHEME](state, formPackageScheme){
        console.log("nastavuji SET_FORM_PACKAGE_SCHEME ", formPackageScheme);
        state.formPackageScheme = formPackageScheme;
    },
    [SET_FORM_PACKAGE_SCHEME_VERSION](state, formPackageSchemeVersion){
        state.formPackageSchemeVersion = formPackageSchemeVersion;
    },
    [SET_FORM_PACKAGES](state, formPackages){
        state.formPackages = formPackages;
    },
    [SET_FORM_TAB_OBJECTS](state, formTabObjects){
        state.formTabObjects = formTabObjects;
    },
    [SET_FORM_PACKAGES_OBJECTS](state, formPackagesObjects){
        console.log(formPackagesObjects);
        state.formPackagesObjects = formPackagesObjects;
    },
}

export default mutations;