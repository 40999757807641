import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "history_tab" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formPackageSchemeVersions, (version, key) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["tab", { active: version.id == _ctx.fpfsvid }]),
        key: key,
        onClick: ($event: any) => (_ctx.changeForm(version))
      }, _toDisplayString(new Date(version.createdDate).toLocaleString()), 11, _hoisted_2))
    }), 128))
  ]))
}