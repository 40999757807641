<template>
  <div class="user_profile_container" v-if="profile && userProfile">
    <div class="UserPhoto">
      <img :src="userProfile.imagePath" />
      <div class="UserPhoto__title">
        Vítejte, {{ profile.firstName }} {{ profile.lastName }}
      </div>
    </div>
    <div class="CardFormPage">
      <div class="CardFormPage__title">Osobní údaje</div>
      <div class="CardFormPage__description">
        Informace o vás a vašich preferencích v aplikacích Safeko software
      </div>
    </div>
    <div class="CardForm UserBasics" v-if="userProfile">
      <div class="CardForm__title">Základní informace</div>
      <div class="CardForm__description"></div>
      <div class="CardForm__input CardFormInput">
        <div class="CardFormInput__title">Jméno</div>
        <div class="CardFormInput__text">
          <input type="text" :value="userProfile.firstName" />
        </div>
      </div>
       <div class="CardForm__input CardFormInput">
        <div class="CardFormInput__title">Příjmení</div>
        <div class="CardFormInput__text">
          <input type="text" :value="userProfile.lastName" />
        </div>
      </div>
        <div class="CardForm__input CardFormInput">
        <div class="CardFormInput__title">Datum Narození</div>
        <div class="CardFormInput__text">
          <input type="datetime-local" :value="userProfile.birthDate" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
/* eslint-disable */

import { defineComponent, computed, ref, onMounted } from "vue";

import { useStore } from "vuex";

import '@/assets/setting.scss';

import {userService} from "@/services/user.service.js";

export default defineComponent({
  name: "UserProfile", 
  setup() {
    const store = useStore();
    let userProfile = ref();
    let user = computed(() => store.getters.user);
    let profile = ref(user.value.profile);


  onMounted(async () => {
    userProfile.value = (await userService.getPerson(user.value.userId)).data;
  });

    return {
      user,
      profile,
      userProfile,
    }
  }
});
</script>

<style lang="scss">
.user_profile_container {
  max-width: 800px;
  background: white;
  margin: auto;
  padding: 25px;

  .UserPhoto {
    margin: auto;
    text-align: center;

    img {
      width: 100px;
    }

    &__title {
      font-size: 1.4em;
      font-weight: 600;
      margin-top: 10px;
    }
  }
}
</style>