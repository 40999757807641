
import {
  computed,
  defineComponent,
} from "vue";

import { useStore } from "vuex";

import controlBase from "./base/controlBase";
import ControlWrapper from "./ControlWrapper.vue";
import TextControl from "./types/TextControl";


export default defineComponent({
  name: "Input",
  props: {
    details: Object,
    errors: Array,
  },
  components: {
    ControlWrapper,
  },
  emits: ["formDataChange", "formDataSave"],
  setup(props, { emit }) {
    // states
    const store = useStore();

    let textControl: TextControl = new TextControl(props, emit);

    let {
      field,
      getStorageObjectData,
      refData,
      localData,
      save,
      setDataFromStorage,
    } = controlBase(textControl);


    return {
      localData,
      save,
      getStorageObjectData,
      setDataFromStorage,
    };
  },
});
