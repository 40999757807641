import actions from './actions';
import mutations from './mutations';
import state from './state';

import router from "@/router/index.ts";

const getters = {
    formPackage(state) {
        return state.formPackage;
    },
    formSchemes(state) {
        return state.formPackage.formSchemes;
    },
    formPackageScheme(state) {
        return state.formPackageScheme;
    },
    formPackageSchemeVersions(state) {
        return state.formPackageScheme.schemeVersions;
    },
    formPackageSchemeVersionLatest(state) {
        if (state.formPackageScheme == null || state.formPackageScheme.schemeVersions == null || router.currentRoute.value == null)
            return null;
        return state.formPackageScheme.schemeVersions.find(element => element.id == router.currentRoute.value.query.fpfsvid);
    },
    formPackageSchemeVersion(state) {
        return state.formPackageSchemeVersion;
    },
    formPackageSchemeVersionSections(state) {
        return state.formPackageSchemeVersion;
    },
    formPackages(state) {
        return state.formPackages;
    },
    formTabObjects(state){
        return state.formTabObjects;
    },
    formPackagesObjects(state){
        return state.formPackagesObjects;
    },
}

export default {
    state,
    actions,
    mutations,
    getters
};