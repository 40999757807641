
import { defineComponent, onMounted, ref } from "vue";
import { useRouter, useRoute } from 'vue-router';
import { useStore } from "vuex";

import Table from "@/views/table/Table.vue";

import formPackageService from "@/services/formPackage.service";

import { DELETE_FORM_PACKAGE } from "@/store/formPackage/action.types";

export default defineComponent({
  name: "InjuryTable",
  setup() {
    const store = useStore();

    const router = useRouter();
    const route = useRoute();

    let injury = ref(null);

    const getInjuries = async () => {
      let injuryRequest = await formPackageService.getObjects();
      if (injuryRequest != null && injuryRequest.data != null) {
        injury.value = injuryRequest.data;
      }
    };

    onMounted(async () => {
      await getInjuries();
    });

    const structure = [
      {
        name: "Jméno",
        getLabel: (option) => {
          console.log(option.data);
          return option?.data?.PersonStorage?.name || "";
        },
      },
      {
        name: "Datum úrazu",
        getLabel: (option) => option.data?.injuryStorage?.Date || "",
        render: (option) => {
          let dateString = option.data?.injuryStorage?.Date
          if (!dateString)
            return "";

          let date = new Date(dateString);
          if (isNaN(date.getTime()))
            return "";

          return date.toLocaleString()
        },
      },
      {
        name: "Datum narozeni",
        getLabel: (option) => option.data?.PersonStorage?.birthDate || "",
        render: (option) => {
          let birthDateString = option.data?.PersonStorage?.birthDate
          if (!birthDateString)
            return "";

          let birthDate = new Date(birthDateString);
          if (isNaN(birthDate.getTime()))
            return "";

          return birthDate.toLocaleDateString()
        },
      },
      {
        name: "Osobní číslo",
        getLabel: (option) => option.data?.PersonStorage?.personNumber || "",
      },
      {
        name: "Trvalé bydliště",
        getLabel: (option) => option.data?.PersonStorage?.residenceAddress || "",
      },
      {
        name: "Datum úmrtí",
        getLabel: (option) => option.data?.injuryStorage?.DateOfDeath || "",
      },
      {
        name: "Form package",
        getLabel: (option) => option.formPackageId,
        click: (column) => {
          route.params.formPackageId = column.formPackageId;
          router.push({
            name: 'Package',
            params: {
              ...route.params,
            },
          });
        },
      },
    ];

    const actions = [
      {
        name: "Otevřít",
        click: (column) => {
          route.params.formPackageId = column.formPackageId;
          router.push({
            name: 'Package',
            params: {
              ...route.params,
            },
          });
        },
      },
      {
        name: "Smazat",
        click: (column) => {
          let formPackageId = column.formPackageId;
          var addFormPackage = store.dispatch(DELETE_FORM_PACKAGE, formPackageId);
          addFormPackage.then(async () => {
            await getInjuries();
          });
        },
      }
    ];

    const tableSetting = {
      title: "Úrazy",
    }

    return {
      injury,
      structure,
      actions,
      tableSetting,
    };
  },
  components: {
    Table,
  },
});
