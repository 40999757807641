import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "head_account_container" }
const _hoisted_2 = { class: "photo" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "text" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "UserWindow" }
const _hoisted_7 = { class: "UserWindow__content" }
const _hoisted_8 = { class: "UserProfile" }
const _hoisted_9 = { class: "UserProfile__photo" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "UserProfile__detail" }
const _hoisted_12 = { class: "UserProfile__name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "account",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open = !_ctx.open))
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.userProfile)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.userProfile.imagePath
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.user.profile.firstName) + " " + _toDisplayString(_ctx.user.profile.lastName), 1),
      _createElementVNode("div", {
        class: "down",
        innerHTML: require(`@/assets/icons/menu/down.svg`)
      }, null, 8, _hoisted_5)
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            (_ctx.userProfile)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _ctx.userProfile.imagePath
                }, null, 8, _hoisted_10))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.user.profile.firstName) + " " + _toDisplayString(_ctx.user.profile.lastName), 1),
            _createVNode(_component_router_link, {
              to: {
              name: 'userProfile',
            },
              class: "UserProfile__user-setting-link",
              target: "_blank"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeWindow && _ctx.closeWindow(...args)))
                }, "Spravovat účet")
              ]),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", {
          class: "UserWindow__logout-button",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)))
        }, " Odhlásit ")
      ])
    ], 512), [
      [_vShow, _ctx.open]
    ])
  ])), [
    [_directive_click_outside, _ctx.closeWindow]
  ])
}