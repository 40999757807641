<template>
  <div id="empty">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
html,
body {
  /* Positioning */
  padding: 0;
  margin: 0;

  /* Other */
  color: #6d6d6d;
  background-color: #eee;
  background-color: #eff1f5;
  font-size: 12px;
  width: 100%;
  height: 100%;
}

input {
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
}

#app {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #5b5b5b;
  width: 100%;
  height: 100%;
}

#empty {
  width: 100%;
  height: 100%;
}
</style>
