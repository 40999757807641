
import {
  computed,
  defineComponent,
} from "vue";

import { useStore } from "vuex";

import controlBase from "./base/controlBase";
import ControlWrapper from "./ControlWrapper.vue";
import NumberControl from "./types/NumberControl";

export default defineComponent({
  name: "Input",
  props: {
    details: Object,
    errors: Array,
  },
  components: {
    ControlWrapper
  },
  emits: ["formDataChange", "formDataSave"],
  setup(props, { emit }) {
    // states
    const store = useStore();

    let numberControl : NumberControl = new NumberControl(props, emit);

    let {
      field,
      getStorageObjectData,
      refData,
      localData,
      save,
      setDataFromStorage,
    } = controlBase(numberControl);

    return {
      localData,
      save,
      getStorageObjectData,
      setDataFromStorage,
    };
  },
});
