import {
    SET_INJURIES,
    SET_INJURY_BODY_PART_TYPES_STATISTICS,
} from "./mutation.types";

const mutations = {
    [SET_INJURIES](state, injuries) {
        state.injuries = injuries;
    },
    [SET_INJURY_BODY_PART_TYPES_STATISTICS](state, injuryBodyPartTypesStatistics){
        state.injuryBodyPartTypesStatistics = injuryBodyPartTypesStatistics;
    },
}

export default mutations;