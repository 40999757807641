
import {
  computed,
  defineComponent,
  watchEffect,
} from "vue";

import { useStore } from "vuex";

import controlBase from "./base/controlBase";
import ControlWrapper from "./ControlWrapper.vue";
import CheckboxControl from "./types/CheckboxControl";

export default defineComponent({
  name: "Input",
  props: {
    details: Object,
    errors: Array,
  },
  components: {
    ControlWrapper
  },
  emits: ["formDataChange", "formDataSave"],
  setup(props, { emit }) {
    // states
    const store = useStore();

    let checkboxControl: CheckboxControl = new CheckboxControl(props, emit);

    let {
      field,
      getStorageObjectData,
      refData,
      localData,
      save,
      setDataFromStorage,
      changeData,
      isFormSectionDataLoaded,
    } = controlBase(checkboxControl);

    // init checkbox to false (not checked) 
    // default is false (not null)
    watchEffect(() => {
      if (localData.value == null && isFormSectionDataLoaded.value == true) {
        console.log("blemc ", isFormSectionDataLoaded.value); 
        console.log("blemc ", localData.value); 
        localData.value = false;
        changeData();
      }
    });

    return {
      localData,
      save,
      getStorageObjectData,
      setDataFromStorage,
    };
  },
});
