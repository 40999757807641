import {
    SET_FORM_SCHEME,
    SET_FORM_SECTION,
    SET_FORM_SCHEMES_TYPES,
} from "./mutation.types";
import {
    GET_FORM_SCHEME,
    GET_FORM_SECTION,
    GET_FORM_SCHEMES_TYPES,
} from "./action.types";
import FormStructureServiceApi from "@/services/formStructure.service";

const actions = {
    async [GET_FORM_SCHEME]({ commit }, { formSchemeVersionId, controller }) {
        const formStructureRequest = FormStructureServiceApi.getScheme(formSchemeVersionId, controller);
        console.log("nacitam form scheme (stuktura) ", formSchemeVersionId);
        formStructureRequest
            .then(function (response) {
                console.log("prišli data vole (struktura)");
                commit(SET_FORM_SCHEME, response.data);
            })
            .catch(function (err) {
                commit(SET_FORM_SCHEME, []);
            });

        return formStructureRequest;
    },
    async [GET_FORM_SECTION]({ commit }, { formSchemeVersionId, sectionId }) {
        const formSectionRequest = FormStructureServiceApi.getSection(formSchemeVersionId, sectionId);
        
        formSectionRequest
            .then(function (response) {
                commit(SET_FORM_SECTION, response.data);
            })
            .catch(function (err) {
                commit(SET_FORM_SECTION, []);
            });

        return formSectionRequest;
    },
    async [GET_FORM_SCHEMES_TYPES]({ commit }) {
        const formSchemesTypesRequest = FormStructureServiceApi.getSchemesTypes();

        formSchemesTypesRequest
            .then(function (response) {
                commit(SET_FORM_SCHEMES_TYPES, response.data);
            })
            .catch(function (err) {
                commit(SET_FORM_SCHEMES_TYPES, []);
            });

        return formSchemesTypesRequest;
    },
}

export default actions;