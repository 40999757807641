import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "injury_table_container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Table = _resolveComponent("Table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Table, {
      structure: _ctx.structure,
      data: _ctx.injury,
      actions: _ctx.actions,
      setting: _ctx.tableSetting
    }, null, 8, ["structure", "data", "actions", "setting"])
  ]))
}