/* eslint-disable */

import {
    API_URL
} from "@/common/config";
import client from '@/helpers/axios.api';


const getOperation = function getOperation(normalizedUrlName) {
    return client.get(`${API_URL}/organization-manager/operations/${normalizedUrlName}`);
};

const getMyUserOperations = function getMyUserOperations() {
    return client.get(`${API_URL}/my-user/operations`);
};

export default {
    getOperation,
    getMyUserOperations,
};
