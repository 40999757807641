
import Chart from "./Chart.vue";
import DashboardNotification from "./DashboardNotification.vue";
import DashboardInjuryTable from "./injuryTable/DashboardInjuryTable.vue";
import InjuryStatistics from "./InjuryStatistics.vue";
import Front from "@/assets/icons/figurine/front.vue";

import { GET_INJURY_BODY_PART_TYPES_STATISTICS } from "@/store/injury/action.types";

import { useStore } from "vuex";
import {
  defineComponent,
  computed,
  watch,
  ref,
  onMounted,
  onUnmounted,
} from "vue";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "Home",
  components: {
    DashboardInjuryTable,
    Chart,
    DashboardNotification,
    InjuryStatistics,
    Front,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    let injuryBodyPartTypesStatistics = computed(() => store.getters.injuryBodyPartTypesStatistics);

    onMounted(() => {
      store.dispatch(GET_INJURY_BODY_PART_TYPES_STATISTICS);
    });

    return {
      injuryBodyPartTypesStatistics,
    }
  },
});
