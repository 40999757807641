import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ControlWrapper = _resolveComponent("ControlWrapper")!

  return (_openBlock(), _createBlock(_component_ControlWrapper, {
    details: _ctx.details,
    errors: _ctx.errors
  }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("input", {
        class: "FormControl",
        type: "text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localData) = $event)),
        onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
      }, null, 544), [
        [_vModelText, _ctx.localData]
      ]),
      (_ctx.localData != _ctx.getStorageObjectData && (_ctx.localData != null && _ctx.getStorageObjectData != null))
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "InputControl__button",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.setDataFromStorage && _ctx.setDataFromStorage(...args)))
          }, "Načíst data ze storage"))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["details", "errors"]))
}