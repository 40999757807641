import {
    SET_OBJECT_DATA,
    SET_OBJECT_DATA_FIELD,
    CLEAR_OBJECT_DATA,
    CLEAR_OBJECT_FIELD_DATA,
    SET_OBJECT_DATA_UNION,
} from "./mutation.types";

const mutations = {
    [SET_OBJECT_DATA](state, {
        objectSchemeId,
        objectData
    }) {
        state.objectData[objectSchemeId] = Object.assign({}, state.objectData[objectSchemeId], objectData);
        state.objectDataLoading[objectSchemeId] = false;
    },
    [SET_OBJECT_DATA_UNION](state, {
        objectSchemeId,
        objectData
    }) {
        console.log("TOHLE JE SPRavně 3")

        state.objectData[objectSchemeId] = Object.assign({}, state.objectData[objectSchemeId], objectData);
        state.objectDataLoading[objectSchemeId] = false;
    },
    [CLEAR_OBJECT_DATA](state) {
        state.objectData = {};
    },
    [CLEAR_OBJECT_FIELD_DATA](state, objectSchemeId) {
        state.objectData[objectSchemeId] = Object.assign({}, state.objectData[objectSchemeId], {});
        state.objectDataLoading[objectSchemeId] = true;
    },
    [SET_OBJECT_DATA_FIELD](state, model) {
        const currentObject = state.objectData[model.objectId];
        if (!currentObject) {
            state.objectData[model.objectId] = {}
        }

        const currentObjectData = state.objectData[model.objectId]["data"];
        if (!currentObjectData) {
            state.objectData[model.objectId] = {
                ["data"]: {
                    [model.type]: null
                }
            };
        }

        if (model.type != null) {
            state.objectData[model.objectId]["data"][model.type] = Object.assign({}, state.objectData[model.objectId]["data"][model.type], model.data);
        } else {
            // currentObjectData.data = model.data;
        }
    },
}

export default mutations;