import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'

import LayoutDefault from "./views/layout/LayoutDefault.vue";
import LayoutLogin from "./views/layout/LayoutLogin.vue";
import LayoutApp from "./views/layout/LayoutApp.vue";

import clickOutside from "@/directives/ClickOutsideDirective.ts";

import Notifications from '@kyvg/vue3-notification';

const app = createApp(App)
    .component("LayoutDefault", LayoutDefault)
    .component("LayoutLogin", LayoutLogin)
    .component("LayoutApp", LayoutApp)
    .use(store)
    .use(router)
    .use(Notifications)
    .directive('click-outside', clickOutside)
    .mount('#app');