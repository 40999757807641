
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";


import Table from "./ApplicationTable.vue";

export default defineComponent({
  name: "AppSelect",
  components: {
    Table,
  },
  setup() {
    const store = useStore();

    const user = computed(() => store.getters.user);

    return {
      user,
    };
  },
});
