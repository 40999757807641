import {
    SET_PROJECT,
    SET_PROJECTS,
} from "./mutation.types";
import {
    GET_PROJECT,
    GET_PROJECTS,
    UPDATE_PROJECT,
    UPDATE_PROJECT_COMPANY,
} from "./action.types";
import ProjectServiceApi from "@/services/project.service";

const actions = {
    async [GET_PROJECT]({commit}, key){
        const getOperationRequest = ProjectServiceApi.getProject(key);
        getOperationRequest
            .then((response) => {
                commit(SET_PROJECT, response.data)
            })
            .catch((err)=>{
                commit(SET_PROJECT, {});
            });
        return getOperationRequest;
    },
    async [GET_PROJECTS]({commit}, team){
        const getProjectsRequest = ProjectServiceApi.getProjects(team);
        getProjectsRequest
            .then((response) => {
                commit(SET_PROJECTS, response.data)
            })
            .catch((err)=>{
                commit(SET_PROJECTS, {});
            });
        return getProjectsRequest;
    },
    async [UPDATE_PROJECT]({commit}, project){
        const updateProjectsRequest = ProjectServiceApi.updateProject(project);
        updateProjectsRequest
            .then((response) => {
                commit(SET_PROJECT, response.data)
            })
            .catch((err)=>{
                commit(SET_PROJECT, {});
            });
        return updateProjectsRequest;
    },
    async [UPDATE_PROJECT_COMPANY]({commit}, project){
        const updateProjectCompanyRequest = ProjectServiceApi.updateProjectCompany(project);
        
        return updateProjectCompanyRequest;
    },
}

export default actions;