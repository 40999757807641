import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "Application" }
const _hoisted_2 = { class: "Application__content" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "Application__text" }
const _hoisted_5 = { class: "Application__title" }
const _hoisted_6 = { class: "Application__url" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ProjectCard = _resolveComponent("ProjectCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: '/' + _ctx.team.nameUrl + '/' + _ctx.application.nameUrl + '/projects'
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "Application__logo",
            innerHTML: require(`@/assets/icons/logo-safeko.svg`)
          }, null, 8, _hoisted_3),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.application.name), 1),
            _createElementVNode("div", _hoisted_6, "safeko.cz/" + _toDisplayString(_ctx.team.nameUrl) + "/" + _toDisplayString(_ctx.application.nameUrl) + "/projects", 1)
          ])
        ])
      ]),
      _: 1
    }, 8, ["to"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.application.projects, (item, key) => {
      return (_openBlock(), _createElementBlock("div", { key: key }, [
        _createVNode(_component_ProjectCard, {
          project: item,
          team: _ctx.team,
          application: _ctx.application
        }, null, 8, ["project", "team", "application"])
      ]))
    }), 128))
  ]))
}