
import { defineComponent, ref } from "vue";

const props = {
  project: {
    type: Object,
  },
  application: {
    type: Object,
  },
  team: {
    type: Object,
  },
};

export default defineComponent({
  name: "ProjectCard",
  props,
  setup(props) {
    const project = ref(props.project);
    const application = ref(props.application);
    const team = ref(props.team);

    return {
      project,
      application,
      team,
    };
  },
});
