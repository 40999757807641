import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "select_container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueMultiselect = _resolveComponent("VueMultiselect")!
  const _component_ControlWrapper = _resolveComponent("ControlWrapper")!

  return (_openBlock(), _createBlock(_component_ControlWrapper, {
    details: _ctx.details,
    errors: _ctx.errors
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_VueMultiselect, {
          modelValue: _ctx.localData,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localData) = $event)),
            _ctx.save
          ],
          "deselect-label": "Kliknutím odebrat",
          "track-by": "title",
          label: "title",
          placeholder: "Vyberte",
          options: _ctx.responseData,
          selectLabel: "",
          selectedLabel: "Označeno",
          "allow-empty": true,
          "internal-search": false,
          searchable: true,
          loading: !_ctx.objectIsLoad,
          multiple: true,
          "close-on-select": false,
          onSearchChange: _ctx.asyncFind
        }, null, 8, ["modelValue", "options", "loading", "onUpdate:modelValue", "onSearchChange"])
      ])
    ]),
    _: 1
  }, 8, ["details", "errors"]))
}