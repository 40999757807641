import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "history_blocks" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formPackageSchemeVersions, (version, key) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "block",
        key: key
      }, [
        _createElementVNode("div", {
          class: "tab",
          onClick: ($event: any) => (_ctx.changeForm(version))
        }, " Otevřít - " + _toDisplayString(new Date(version.createdDate).toLocaleString()), 9, _hoisted_2)
      ]))
    }), 128))
  ]))
}