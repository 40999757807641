import { ICheckBox } from "../interfaces/ICheckBox";
import { IControl } from "../interfaces/IControl";

export default class CheckboxControl implements IControl<boolean> {
    props: any;
    emit: any;
    
    constructor(props: any, emit: any) {
        this.props = props;
        this.emit = emit;
    }

    checkData(value: boolean): boolean {
        let isValid: boolean = false;

        if (!value)
            return isValid;

        isValid = true;

        return isValid;
    }
}
