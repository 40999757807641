<template>
  <div class="menu_block">
    <div class="title">{{ item.name }}</div>
    <div class="menu_item_container">
      <menu-item
        v-for="(item, index) in item.items"
        :key="index"
        :item="item"
      />
    </div>
  </div>
</template>

<script>
import MenuItem from "./MenuItem.vue";
export default {
  props: {
    item: Object,
  },
  components: { MenuItem },
  name: "MenuBlock",
};
</script>

<style lang="scss" scoped>
.menu_block {
  .title {
    border-top: #c1c1c1 1px solid;
    border-bottom: #c1c1c1 1px solid;
    padding: 8px 0;
    margin: 0 15px;
    color: #aa1f27;
    font-weight: 600;
    font-size: 1.1em;
  }

  .menu_item_container{
    margin: 2px 0;
  }
}
</style>