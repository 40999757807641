
import { defineComponent, onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import "../autocomplete.js";
import { SET_OBJECT_DATA_FIELD } from "@/store/formObjectData/mutation.types";
import objectControl from "./object.js";

export default defineComponent({
  name: "Select",
  props: {
    details: Object,
  },
  setup(props) {
    const store = useStore();
    const selectedValue = ref();

    const field = props.details;
    // console.log(field);
    let pupi = field.objectFieldJoins.find(s => s.objectFieldType == "response");
    console.log(pupi);

    const response = computed(() => {
      let oc = objectControl.getObjectData2(pupi, "response");

      if (!oc || !oc.value)
        return null;

      return oc.value()
    }) as any;

    const setSelectedValue = (event) => {
      const value = event.detail.value;
      console.log("EVENT ", value);

      if (!(field.objectFieldJoins && field.objectFieldJoins.length > 0))
        return;

      let objectField = field.objectFieldJoins.find(s => s.objectFieldType == "storage");

      let model = { [objectField.name]: value[objectField.name] };

      console.log(model);
      console.log(objectField);

      store.commit(SET_OBJECT_DATA_FIELD, {
        type: "storage",
        objectId: objectField.objectId,
        data: value,
      });

      // store.commit(SET_OBJECT_DATA_FIELD, {
      //   type: "storage",
      //   objectId: 1,
      //   data: value,
      // });
    };

    const changeInputValue = (event) => {
      const value = event.detail.value;

      if (!(field.objectFieldJoins && field.objectFieldJoins.length > 0))
        return;

      console.log("change input value", field);
      let objectField = field.objectFieldJoins.find(s => s.objectFieldType == "query");

      objectControl.setObjectData2(objectField, "query", value, store);
    };


    

    const fulllist = computed(() => {
      if (!response) return [];

      if (!response.value) return [];

      return response.value;
    });

    let expression = "(item) => item.name";

    if (field.id == 71)
      expression = "(item) => item.firstName + ' ' + item.lastName + ' (' + item.id + ')'";

    if (field.id == 176 || field.id == 183)
      expression = "(item) => item.obchodniJmeno + ' (' + item.sidlo.textovaAdresa + ')' ";


    let expressionFun = eval(expression);

    return {
      response,
      setSelectedValue,
      selectedValue,
      // fetchResults,
      changeInputValue,
      field,
      fulllist,
      expressionFun,
    };
  },
});

// const setSelectedValue = (event) => {
//   const opt = event.detail.value;
//   console.log("CALLLL", opt);

//    store.commit(SET_OBJECT_DATA_FIELD, {
//     type: "query",
//     objectId: 7,
//     data: { id: 557 },
//   });

//   // if (!(field.objectFieldJoins && field.objectFieldJoins.length > 0))
//   //   return;

//   // const objects = [];

//   // field.objectFieldJoins.forEach((joinField) => {
//   //   if (objects[joinField.objectId] == null)
//   //     objects[joinField.objectId] = {};

//   //   Object.assign(objects[joinField.objectId], opt);
//   // });

//   // objects.forEach((object, index) => {
//   //   console.log("Nastavuji objekt data", object, index);

//   // });
// };

// const fetchResults = (value) => {
//   if (!(field.objectFieldJoins && field.objectFieldJoins.length > 0))
//     return;
//   const objects = [];
//   field.objectFieldJoins.forEach((joinField) => {
//     if (objects[joinField.objectId] == null)
//       objects[joinField.objectId] = {};

//     Object.assign(objects[joinField.objectId], { [joinField.name]: value });
//   });

//   objects.forEach((object, index) => {
//     console.log(object, index);
//     store.commit(SET_OBJECT_DATA_FIELD, {
//       type: "query",
//       objectId: 2,
//       data: object,
//     });
//   });
// };
