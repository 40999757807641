
import { defineComponent, computed, ref, watch, Ref } from "vue";
import { useStore } from "vuex";
import VueMultiselect from 'vue-multiselect'

import controlBase from "./base/controlBase";
import ControlWrapper from "./ControlWrapper.vue";
import MultiSelect from "./types/MultiSelect";
import { objects, changeObjectData } from "../objectServices/formObject2.js";

import '@/assets/styles/vue-multiselect.css';

export default defineComponent({
  name: "MultiSelect",
  components: {
    VueMultiselect,
    ControlWrapper,
  },
  props: {
    details: Object,
    errors: Array,
  },
  emits: ["formDataChange", "formDataSave"],
  setup(props, { emit }) {
    const store = useStore();

    let multiSelectControl: MultiSelect = new MultiSelect(props, emit);

    let {
      field,
      localData,
      save,
    } = controlBase(multiSelectControl);

    const responseData = ref<any>([]) as Ref<any>;

    var joinedObject = field.objectFieldJoins.filter(s => s.objectFieldType == "response")[0];
    const objectIsLoad = computed(() => {
      let objects2 = objects.value;
      if (!objects2)
        return null;

      const object = objects2[joinedObject.objectId];
      if (!object) 
        return null;

      return object.isLoaded;
    });

    const objectData = computed(() => {
      let objects2 = objects.value;
      if (!objects2)
        return null;

      const object = objects2[joinedObject.objectId];
      if (!object) 
        return null;

      return object.data;
    });

 
    watch(
      () => [objectData, objectIsLoad],
      ([data, isLoad]) => {
        if (!isLoad.value)
          return;
        if(data.value && data.value.response) {
          responseData.value = data.value.response;
        }
        else {
          changeObjectData({
            objectSchemeId: joinedObject.objectId,
            objectData: { data: { id: 1 } },
            isHard: true,
            dataType: "query"
          });
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );

    return {
      save,
      localData,
      responseData,
      objectData,
      objectIsLoad,
      objects,
    };
  },
});
