
import { defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import formPackageService from "@/services/formPackage.service.js";

import ModalDialog from "@/views/components/ModalDialog.vue";

const components = {
  ModalDialog,
};

export default defineComponent({
  name: "quick_action_container",
  components,
  setup() {
    const route = useRoute();
    const router = useRouter();

    let result = ref();

    let isOpenListActions = ref(false);

    let createFirstInjuryForm = async function () {
      result.value = (
        await formPackageService.addFormPackageWithFormSchemes({
          formSchemeId: 6,
          FormSchemeVersionId: 6,
        })
      ).data;

      const query = {
        fid: result.value.formId,
        fsvid: result.value.formSchemeVersionId,
        fpfsvid: result.value.formPackageFormSchemeVersionId,
      };

      const params = {
        fpfsid: result.value.formPackageFormSchemeId,
        formPackageId: result.value.formPackageId,
      };

      closeListActions();

      router.push({
        name: "FormSection",
        query: query,
        params: params,
      });
    };

    // let createFirstInjuryForm = function () {
    //   const query = {
    //     fid: "ad481323-d5a9-48a7-8713-77c67ff71091",
    //     fsvid: 6,
    //     fpfsvid: 116,
    //     // sid: 13,
    //   };

    //   const params = {
    //     fpfsid: 116,
    //     formPackageId: 85,
    //   };

    //   closeListActions();

    //   router.push({
    //     name: "FormSection",
    //     query: query,
    //     params: params,
    //   });
    // }

    let toggleListActions = function () {
      isOpenListActions.value = !isOpenListActions.value;
    };

    let closeListActions = function () {
      isOpenListActions.value = false;
    };

    return { createFirstInjuryForm, isOpenListActions, toggleListActions, closeListActions };
  },
});
