
/* eslint-disable */

import { defineComponent } from "vue";
// import Filter from "./filter/Filter.vue";
import Vue, { PropType } from "vue";

import TableSetting from "./TableSetting.vue";
import TableSearch from "./TableSearch.vue";

export default defineComponent({
  name: "Table",
  props: {
    data: {
      type: Array as PropType<Array<any[number]>> | null,
      default: [],
    },
    structure: {
      type: Array as PropType<Array<any>>,
    },
    actions: {
      type: Array as PropType<Array<any>>,
    },
    setting: {
      type: Object,
    },
  },
  emits: ["tableEventAddClick"],
  components: {
    TableSetting,
    TableSearch,
    // Filter,
  },
  methods: {
  },
  mounted() {
    const ele = document.getElementById("table_wrapper");
    const ele2 = document.getElementsByTagName("html")[0];
    // ele.scrollTop = 100;
    // ele.scrollLeft = 150;

    let pos = { top: 0, left: 0, x: 0, y: 0 };

    const mouseDownHandler = function (e) {
      ele.style.cursor = "grabbing";
      ele.style.userSelect = "none";
      pos = {
        // The current scroll
        left: ele.scrollLeft,
        top: ele2.scrollTop,
        // Get the current mouse position
        x: e.clientX,
        y: e.clientY,
      };

      document.addEventListener("mousemove", mouseMoveHandler);
      document.addEventListener("mouseup", mouseUpHandler);
    };

    const mouseMoveHandler = function (e) {
      // How far the mouse has been moved
      const dx = e.clientX - pos.x;
      const dy = e.clientY - pos.y;

      // Scroll the element
      ele.scrollTop = pos.top - dy;
      ele2.scrollTop = pos.top - dy;
      ele.scrollLeft = pos.left - dx;
    };

    const mouseUpHandler = function () {
      ele.style.cursor = "grab";
      ele.style.removeProperty("user-select");

      document.removeEventListener("mousemove", mouseMoveHandler);
      document.removeEventListener("mouseup", mouseUpHandler);
    };
    // Attach the handler
    if (ele)
      ele.addEventListener("mousedown", mouseDownHandler);
  },
});
