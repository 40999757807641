import { ICheckBox } from "../interfaces/ICheckBox";
import { IControl } from "../interfaces/IControl";
import { IMultiCheckboxes } from "../interfaces/IMultiCheckboxes";
import { ISelect } from "../interfaces/ISelect";

export default class MultiSelect implements IControl<ISelect[]> {
    props: any;
    emit: any;

    constructor(props: any, emit: any) {
        this.props = props;
        this.emit = emit;
    }

    checkData(selects: ISelect[]): boolean {
        let isValid: boolean = false;

        if (!selects || selects.length <= 0)
            return isValid;

        isValid = true;
        
        return isValid;
    }
}
