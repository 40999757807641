import {
    SET_FORM_SCHEME,
    SET_FORM_SECTION,
    SET_FORM_SCHEMES_TYPES,
} from "./mutation.types";

const mutations = {
    [SET_FORM_SCHEME](state, formScheme) {
        console.log("nastavuji form scheme (stuktura) ", formScheme);
        state.formScheme = formScheme;
    },
    [SET_FORM_SECTION](state, formSection) {
        state.formSection = formSection;
    },
    [SET_FORM_SCHEMES_TYPES](state, formSchemesTypes){
        state.formSchemesTypes = formSchemesTypes;
    }
}

export default mutations;