import {
    ref,
    computed,
    watch,
} from 'vue';
import {
    GET_OBJECT_DATA,
    SAVE_OBJECT_DATA,
} from "@/store/formObjectData/action.types";
import { SET_OBJECT_DATA_UNION } from "@/store/formObjectData/mutation.types";

import {
    SET_FORM_SECTION_DATA
} from "@/store/formData/mutation.types";

import {
    SAVE_FORM_DATA
} from "@/store/formData/action.types";

import client from "@/helpers/axios.api";
import store from "@/store/index.js";
import route from "@/router/index.ts";

function useFormObjectSearch(objectSchema, controllerChangeObjectData) {
    const formPackage = computed(() => route.currentRoute.value.params.formPackageId);
    const objectsData = computed(() => store.getters.objectData);

    // states
    const objectAll = computed(() => objectsData.value[objectSchema.id]);

    const objectData = computed(() => {
        const object = objectsData.value[objectSchema.id];
        if (!object) return null;

        const objectData = object.data;
        if (!objectData) return null;

        return objectData;
    });

    const objectSetFields = ref([]);
    const fff = ref([]);
    let isLoaded = ref(false);


    let options = {};

    const loadObjectData = (store) => {
        const objectDataId = objectSchema.objectDataId;
        const objectSchemeId = objectSchema.id;

        let req = store.dispatch(
            GET_OBJECT_DATA, {
            formObjectSchemeId: objectSchemeId,
            id: objectDataId, //objectDataId
            formPackageId: formPackage.value
        });

        req.then(() => {
            isLoaded.value = true;
            console.log("Načteno ", objectSchema.id);
        })
    };

    let saveForm = (data) => {
        let formSectionId = route.currentRoute.value.query.sid;
        let formId = route.currentRoute.value.query.fpfsvid;

        store.dispatch(SAVE_FORM_DATA, {
            formId: formId,
            data: data,
            formSectionId: formSectionId,
        });
    }

    const load = () => {
        options.loadObjectData();
    };

    const saveObjectData = () => {
        let model = { ...objectAll.value, id: objectSchema.formObjectDataId, packageId: formPackage.value };
        // const formSchemeRequest = store.dispatch(
        //     SAVE_OBJECT_DATA,
        //     model,
        // );
    }

    for (let key in objectSchema.fields) {
        var field = objectSchema.fields[key];
        var objectFieldJoins = field.objectFieldJoins;

        for (let key2 in objectFieldJoins) {
            var formFieldJoin = objectFieldJoins[key2];

            var objf = {
                to: {
                    ...formFieldJoin
                },
                objectId: field.id,
                objectName: field.name,
            };
            objectSetFields.value.push(objf);
        }
    }

    objectSchema.fields.filter((e) => e.objectFieldType == 'storage').forEach((objectField) => {
        var formFieldJoins = objectField.formFieldJoins;

        formFieldJoins.forEach((formFieldJoin) => {
            var objf = {
                ...formFieldJoin,
                objectId: objectField.id,
                objectName: objectField.name,
            };
            fff.value.push(objf);
        });
    });
    let controller = new AbortController();


    const generateRequest = () => {



        const url = objectSchema.url;
        const queryFields = objectSchema.fields.filter((e) => e.objectFieldType == 'query');

        const requestBody = generateRequestBody(queryFields);

        const request = client.get(url, {
            params: requestBody,
            signal: controller.signal
        });

        request.then((value) => {
            objectData.value["response"] = value.data;
            isLoaded.value = true;
        });
    }

    const generateRequestBody = (queryFields) => {
        let requestBody = {};
        for (const field of queryFields) {
            if (objectData.value["query"][field.name])
                requestBody[field.name] = objectData.value["query"][field.name];
        }
        return requestBody;
    };

    let timeoutId;
    watch(
        () => objectData.value != null ? objectData.value["query"] : null,
        (newVal, oldVal) => {
            if (newVal) {
                controller.abort();
                controller = new AbortController();
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                    // Runs 1 second (1000 ms) after the last change
                    generateRequest();

                }, 500);
            }
        }, {
        deep: true
    }
    );

    Object.byString = function (o, s) {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        var a = s.split('.');
        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (k in o) {
                o = o[k];
            } else {
                return;
            }
        }
        return o;
    }

    let loadedFormScheme = computed(() => store.getters.formSection);
    const formSectionData = computed(() => store.getters.formSectionData);


    let changeStorageInput = () => {
        let changeObject = {};
        console.log(fff.value);
        for (let key in fff.value) {
            var formFieldWatch = fff.value[key];

            if (formFieldWatch.type == 1)
                continue;

            if (!formFieldWatch)
                continue;

            if (!loadedFormScheme.value.fields.some(s => s.id == formFieldWatch.id))
                continue;

            var objectValue = Object.byString(objectData.value.storage, formFieldWatch.objectName);

            if (objectValue == null)
                objectValue = "";

            changeObject[formFieldWatch.name] = objectValue;

            console.log(formFieldWatch);
        }

        if (Object.keys(changeObject).length != 0) {
            console.log("změna form data z objectu cislo: " + objectSchema.id + " : ", changeObject);
            var resultValueFormData = {
                ...formSectionData.value,
                ...changeObject,
            };
            console.log(resultValueFormData);
            store.commit(SET_FORM_SECTION_DATA, {
                formData: resultValueFormData
            });

            saveForm(resultValueFormData);
        }
    };



    watch(
        () => objectData.value != null ? objectData.value["storage"] : null,
        (newVal, oldVal) => {
            if (newVal) {

                if (!newVal)
                    return;

                let uperModel = {};

                console.log(objectSchema.id);
                console.log(newVal);
                console.log(oldVal);

                objectSetFields.value.forEach(fieldIn => {
                    console.log(fieldIn);
                    var objectValue = Object.byString(newVal, fieldIn.objectName);

                    let model = uperModel[fieldIn.to.objectId];

                    if (!model || !model.data) {
                        uperModel[fieldIn.to.objectId] = {
                            objectSchemeId: fieldIn.to.objectId,
                            data: {},
                            type: fieldIn.to.objectFieldType
                        }
                    }

                    uperModel[fieldIn.to.objectId].data[fieldIn.to.name] = objectValue;
                });

                console.log(uperModel);
                for (const [key, value] of Object.entries(uperModel)) {
                    // if (value.type != "storage")
                    //     continue;

                    const initData = {
                        data: value.data
                    };

                    console.log(key);
                    console.log(value);

                    console.log(initData);

                    controllerChangeObjectData({
                        objectSchemeId: key,
                        objectData: initData,
                        isHard: true,
                        dataType: value.type,
                    });

                    // store.commit(SET_OBJECT_DATA_UNION, {
                    //     objectSchemeId: key,
                    //     objectData: initData,
                    // });
                }



                changeStorageInput();

                saveObjectData();
            }
        }, {
        deep: true
    }
    );


    const initEmptyObject = () => {
        const initData = {
            data: {
                storage: {}
            }
        };

        store.commit(SET_OBJECT_DATA, {
            objectSchemeId: objectSchema.id,
            objectData: initData,
        });
    }

    const initEmptyStorage = (DATA_TYPE) => {
        const initDataStorage = {};

        objectData.value[DATA_TYPE] = initDataStorage;
    }

    const changeObjectData = (DATA_TYPE, changeData) => {

        if (DATA_TYPE == "query") {
            isLoaded.value = false;
        }

        // TODO 
        // nestaci pouze objectData? místo objectAll
        if (objectAll.value == null || Object.keys(objectAll.value).length == 0) {
            initEmptyObject();
        }

        if (!objectData.value[DATA_TYPE]) {
            initEmptyStorage(DATA_TYPE);
        }

        if (Array.isArray(changeData) || Array.isArray(objectData.value[DATA_TYPE])) {
            objectData.value[DATA_TYPE] = changeData;
        } else {
            console.log(changeData);
            objectData.value[DATA_TYPE] = {
                ...objectData.value[DATA_TYPE],
                ...changeData,
            };
        }

    }

    let changeData = (model) => {
        console.log("CHANGE DATA", model);
        changeObjectData(model.dataType, model.data);
    }

    return {
        load,
        loadObjectData,
        saveObjectData,
        options,
        changeData,
        isLoaded,
        data: objectData
    };
}

export default {
    useFormObjectSearch,
}