/* eslint-disable */

import axios from "axios";
import {
    API_URL
} from "@/common/config";
import UserLocalStorageService from "@/services/user.local.storage";
import router from "@/router/index";

let isRefreshing = false;
let subscribers = [];

function onRefreshed(authorisationToken) {
    subscribers.map(cb => cb(authorisationToken));
}

function subscribeTokenRefresh(cb) {
    subscribers.push(cb);
}

const userLocalStorageService = UserLocalStorageService.getService();

const client = axios.create({
    baseURL: API_URL,
});

client.interceptors.request.use(req => {
    const user = userLocalStorageService.getUser();
    if (user && user.token) {
        req.headers.authorization = 'Bearer ' + user.token;
    }
    return req;
});

client.interceptors.request.use(req => {
    if (router.currentRoute.value == null)
        return req;

    const company = router.currentRoute.value.params.team;

    if (company == null || company == "")
        return req;

    if (req.params != null) {
        req.params["company"] = company;
    } else {
        req.params = {
            company: company
        };
    }
    
    return req;
});

client.interceptors.request.use(req => {
    if (router.currentRoute.value == null)
        return req;

    const project = router.currentRoute.value.params.projectId;

    if (project == null || project == "")
        return req;

    if (req.params != null) {
        req.params["project"] = project;
    } else {
        req.params = {
            project: project
        };
    }
    
    return req;
});

// createAxiosResponseInterceptor();

// function createAxiosResponseInterceptor() {
client.interceptors.response.use(
    (response) => {
        return response;
    },
    error => {
        const originalRequest = error.config;
        const errorResponse = error.response;
        // Reject promise if usual error
        if (errorResponse == null || errorResponse.status == undefined || errorResponse.status !== 401 || originalRequest._retry) {
            return Promise.reject(error);
        }

        originalRequest._retry = true;

        /* 
         * When response code is 401, try to refresh the token.
         * Eject the interceptor so it doesn't loop in case
         * token refresh causes the 401 response
         */
        // client.interceptors.response.eject(interceptor);

        const requestOptions = {
            headers: {
                'Content-Type': 'application/json'
            },
        };
        const user = userLocalStorageService.getUser();
        if (!isRefreshing) {
            isRefreshing = true;
            return client.post(`/auth-manager/RefreshToken`, {
                RefreshToken: user.refreshToken,
                Token: user.token,
            }, requestOptions).then(response => {
                const user = response.data;
                if (user.errors) {
                    userLocalStorageService.clearUser();
                    router.push("/login");
                    return Promise.reject(user.errors);
                }
                userLocalStorageService.setUser(user);

                const originalConfig = error.config;
                originalConfig.headers = {
                    ...originalConfig.headers,
                    authorization: 'Bearer ' + user.token
                };

                isRefreshing = false;
                onRefreshed(user.token);
                subscribers = [];

                return axios(originalConfig);
            }).catch(error => {
                userLocalStorageService.clearUser();
                router.push("/login");
                return Promise.reject(error);
            })
        }

        return new Promise(resolve => {
            subscribeTokenRefresh(token => {

                const originalConfig = error.config;
                originalConfig.headers = {
                    ...originalConfig.headers,
                    authorization: `Bearer ${token}`
                };

                resolve(axios(originalConfig));
            });
        });

        return Promise.reject(error);
    }
);
// };


function refreshToken() {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json'
        },
    };
    const user = userLocalStorageService.getUser();

    return client.post(`${API_URL}/auth-manager/RefreshToken`, {
            RefreshToken: user.refreshToken,
            Token: user.token,
        }, requestOptions)
        .then(response => {
            const user = response.data;
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                userLocalStorageService.setUser(user);
            }

            return user;
        });

}

export default client;