import {
    computed,
    watch,
    ref,
} from 'vue';

import {
    GET_OBJECT_DATA,
    SAVE_OBJECT_DATA,
} from "@/store/formObjectData/action.types";

import {
    SET_OBJECT_DATA
} from "@/store/formObjectData/mutation.types";

import {
    SET_MULTIPLE_CHANGES,
} from "@/store/formData/mutation.types";

import {
    SET_FORM_SECTION_DATA
} from "@/store/formData/mutation.types";

import {
    SAVE_FORM_DATA
} from "@/store/formData/action.types";

import client from "@/helpers/axios.api";
import store from "@/store/index.js";
import route from "@/router/index.ts";

const useFormObjectLoad = function useFormObjectLoad(objectSchema, controllerChangeObjectData) {
    const formPackage = computed(() => route.currentRoute.value.params.formPackageId);
    const objectsData = computed(() => store.getters.objectData);
    const formSectionData = computed(() => store.getters.formSectionData);
    const objectAll = computed(() => objectsData.value[objectSchema.id]);

    const ff = ref([]);
    const objectFieldJoins = ref([]);

    let isLoaded = ref(false);
    let queue = [];

    const objectData = computed(() => {
        const objects = objectsData.value;
        if (!objects)
            return null;

        const object = objects[objectSchema.id];
        if (!object)
            return null;

        return object["data"];
    });

    let options = {};

    let saveForm = (data) => {
        let formSectionId = route.currentRoute.value.query.sid;
        let formId = route.currentRoute.value.query.fpfsvid;

        store.dispatch(SAVE_FORM_DATA, {
            formId: formId,
            data: data,
            formSectionId: formSectionId,
        });
    }

    const loadObjectData = (store) => {
        const objectDataId = objectSchema.objectDataId;
        const objectSchemeId = objectSchema.id;
        console.log("Nacitam objekt id: " + objectSchema.id + " s data id: " + objectSchema.objectDataId);
        let req = store.dispatch(
            GET_OBJECT_DATA, {
            formObjectSchemeId: objectSchemeId,
            id: objectDataId, //objectDataId
            formPackageId: formPackage.value
        });

        req.then(() => {
            isLoaded.value = true;
            console.log("Načteno ", objectSchema.id);
        })
    };

    const load = () => {
        options.loadObjectData();
    };

    let loadedFormScheme = computed(() => store.getters.formSection);

    const generateRequest = () => {
        console.log("Generuji request pro " + objectSchema.id + " s url: " + objectSchema.url);
        const url = objectSchema.url;

        if (!url)
            return;

        const queryFields = objectSchema.fields.filter((e) => e.objectFieldType == 'query');

        const requestBody = generateRequestBody(queryFields);

        const request = client.get(url, {
            params: requestBody
        });

        request.then((value) => {
            changeData({ dataType: "response", data: value.data });
            isLoaded.value = true;

            // objectData.value["response"] = value.data;
            console.log(objectData.value);

            let changeObject = {};
            for (let key in ff.value) {
                var formFieldWatch = ff.value[key];

                if (formFieldWatch.type == 1)
                    continue;

                if (!formFieldWatch)
                    continue;

                if (!loadedFormScheme.value.fields.some(s => s.id == formFieldWatch.id))
                    continue;

                var objectValue = objectData.value.response[formFieldWatch.objectName];

                if (objectValue == null)
                    objectValue = "";

                changeObject[formFieldWatch.name] = objectValue;
            }


            let uperModel = {};
            objectFieldJoins.value.forEach(fieldIn => {
                console.log(fieldIn);
                var objectValue = Object.byString(objectData.value.response, fieldIn.objectName);

                let model = uperModel[fieldIn.to.objectId];

                if (!model || !model.data) {
                    uperModel[fieldIn.to.objectId] = {
                        objectSchemeId: fieldIn.to.objectId,
                        data: {},
                        type: fieldIn.to.objectFieldType
                    }
                }

                uperModel[fieldIn.to.objectId].data[fieldIn.to.name] = objectValue;
            });

            for (const [key, value] of Object.entries(uperModel)) {
                const initData = {
                    data: value.data
                };

                console.log(key);
                console.log(value);

                console.log(initData);

                controllerChangeObjectData({
                    objectSchemeId: key,
                    objectData: initData,
                    isHard: true,
                    dataType: value.type,
                });
            }

            if (Object.keys(changeObject).length != 0) {
                var resultValueFormData = {
                    ...formSectionData.value,
                    ...changeObject,
                };
                console.log(resultValueFormData);
                store.commit(SET_FORM_SECTION_DATA, {
                    formData: resultValueFormData
                });

                saveForm(resultValueFormData);
            }


            // console.log("DATAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA ", value.data);

        });
    }

    objectSchema.fields.filter((e) => e.objectFieldType == 'response').forEach((objectField) => {
        var formFieldJoins = objectField.formFieldJoins;
        formFieldJoins.forEach((formFieldJoin) => {
            var objf = {
                ...formFieldJoin,
                objectId: objectField.id,
                objectName: objectField.name,
            };
            ff.value.push(objf);
        });
    });

    for (let key in objectSchema.fields) {
        var field = objectSchema.fields[key];
        var objectFieldJoinsFromField = field.objectFieldJoins;

        for (let key2 in objectFieldJoinsFromField) {
            var formFieldJoin = objectFieldJoinsFromField[key2];

            var objf = {
                to: {
                    ...formFieldJoin
                },
                objectId: field.id,
                objectName: field.name,
            };
            objectFieldJoins.value.push(objf);
        }
    }


    objectSchema.fields.filter((e) => e.objectFieldType == 'response').forEach((objectField) => {
        var formFieldJoins = objectField.formFieldJoins;
        formFieldJoins.forEach((formFieldJoin) => {
            var objf = {
                ...formFieldJoin,
                objectId: objectField.id,
                objectName: objectField.name,
            };
            ff.value.push(objf);
        });
    });

    console.log(ff.value);
    const generateRequestBody = (queryFields) => {
        let requestBody = {};
        for (const field of queryFields) {
            requestBody[field.name] = objectData.value["query"][field.name];
        }
        return requestBody;
    };


    let changeData = (model) => {
        console.log("CHANGE DATA", model);
        changeObjectData(model.dataType, model.data);
    }

    const saveObjectData = () => {
        console.log("UKLADAM LOAD OBJECT " + objectSchema.id);
        const objectSchemeId = objectSchema.id;
        const projectId = route.currentRoute.value.params.projectId;

        let model = {
            ...objectAll.value,
            formObjectSchemeId: objectSchemeId,
            formPackageId: formPackage.value,
            project: projectId,
        };
        console.log(objectAll.value);
        console.log(model);
        const formSchemeRequest = store.dispatch(
            SAVE_OBJECT_DATA,
            model,
        );
    }

    // watch(
    //     () => objectData.value,
    //     (newVal, oldVal) => {
    //         console.log("PRVOTNÍ načtení data pro ", objectSchema.id);
    //         console.log("AHA ", queue.value);
    //     }, {
    //     deep: true,
    //     immediate: true,
    // }
    // );

    // update in form data section fields
    // watch(
    //     () => objectData.value ? objectData.value.query : null,
    //     (newVal, oldVal) => {
    //         console.log("NAČÍTAM333", objectSchema.id);
    //         // if (!isLoaded.value) {
    //         //     console.log("NASTAVUJU KURVU " + objectSchema.id, newVal)
    //         //     isLoaded.value = true;
    //         //     return;
    //         // }
    //         if (newVal != null) {
    //             console.log("AJAJAJJAAJ ", newVal)
    //             // generateRequest();

    //             if (isLoaded.value)
    //                 generateRequest();
    //             // else {
    //             //     console.log(newVal);
    //             //     queue.push(newVal);
    //             //     console.log("QUUEUUEUEUE ", queue);
    //             //     console.log(newVal);
    //             // }
    //         }
    //     }, {
    //     deep: true,
    //     immediate: false,
    // }
    // );

    let timeoutId;
    const changeObjectData = (DATA_TYPE, changeData) => {

        if (DATA_TYPE == "query") {
            isLoaded.value = false;
        }

        // TODO 
        // nestaci pouze objectData? místo objectAll
        if (objectAll.value == null || Object.keys(objectAll.value).length == 0) {
            initEmptyObject();
        }

        if (!objectData.value[DATA_TYPE]) {
            initEmptyStorage(DATA_TYPE);
        }

        if (Array.isArray(changeData) || Array.isArray(objectData.value[DATA_TYPE])) {
            objectData.value[DATA_TYPE] = changeData;
        } else {
            console.log(changeData);
            objectData.value[DATA_TYPE] = {
                ...objectData.value[DATA_TYPE],
                ...changeData,
            };
        }

        if (DATA_TYPE == "query") {
            generateRequest();
        }


        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            // Runs 1 second (1000 ms) after the last change
            saveObjectData();
        }, 1000);
    }

    const initEmptyObject = () => {
        const initData = {
            data: {
                storage: {}
            }
        };

        store.commit(SET_OBJECT_DATA, {
            objectSchemeId: objectSchema.id,
            objectData: initData,
        });
    }

    const initEmptyStorage = (DATA_TYPE) => {
        const initDataStorage = {};

        objectData.value[DATA_TYPE] = initDataStorage;
    }

    return {
        load,
        loadObjectData,
        options,
        changeData,
        isLoaded,
        data: objectData
    };
}

export default {
    useFormObjectLoad,
}