/* eslint-disable */

import {
    API_URL
} from "@/common/config";
import client from '@/helpers/axios.api';


const getTeams = async function getTeams() {
    try {
        return await client.get(`${API_URL}/team-hub/my-teams`);
    } catch (e) {
        return null;
    }
};

const getTeam = async function getTeam(teamId) {
    try {
        return await client.get(`${API_URL}/team-hub/teams/${teamId}`);
    } catch (e) {
        return null;
    }
};

export default {
    getTeams,
    getTeam,
};