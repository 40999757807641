/* eslint-disable */

import {
    API_URL
} from "@/common/config";
import client from '@/helpers/axios.api';
import store from '@/store/index.js';

export const menuManager = {
    getUserMenu,
    getCompanyMenu,
};

function getUserMenu(teamKey, applicationKey) {
    let menu = [];

    let appMenu = {
        name: "Aplikace",
        items: [{
            name: `Přehled projektů`,
            link: `/${teamKey}/${applicationKey}/projects`,
            icon: "/icons/menu/home.svg",
        }, ],
    };

    if (applicationKey)
        menu.push(appMenu);

    return menu;
}

function getCompanyMenu(teamKey, applicationKey, projectKey) {
    return [{
            name: "Domů",
            items: [{
                name: "Dashboard",
                link: `/${teamKey}/${applicationKey}/projects/${projectKey}/dashboard`,
                icon: "/icons/menu/home.svg",
            }, ],
        },
        {
            name: "Evidence úrazů",
            items: [{
                    name: "Kniha úrazů",
                    link: `/${teamKey}/${applicationKey}/projects/${projectKey}/knihaurazu`,
                    icon: "/icons/menu/book.svg",
                },
                {
                    name: "Formůláře",
                    link: `/${teamKey}/${applicationKey}/projects/${projectKey}/package`,
                    icon: "/icons/menu/table.svg",
                },
                {
                    name: "Formůlářové data",
                    link: `/${teamKey}/${applicationKey}/projects/${projectKey}/injurydata`,
                    icon: "/icons/menu/table.svg",
                },
            ],
        },
        {
            name: "Projekt",
            items: [{
                    name: "Základní nastavení",
                    link: `/${teamKey}/${applicationKey}/projects/${projectKey}/settings`
                },
                {
                    name: "Uživatelé / pracovníci",
                    link: `/${teamKey}/${applicationKey}/projects/${projectKey}/users`
                },
            ],
        },
    ];
}