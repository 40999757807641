import actions from './actions';
import mutations from './mutations';
import state from './state';

const getters = {
   project(state){
       return state.project;
   },
   projects(state){
       return state.projects;
   }
}

export default {
    state,
    actions,
    mutations,
    getters
};