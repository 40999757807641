import actions from './actions';
import mutations from './mutations';
import state from './state';

const getters = {
    injuries(state) {
        return state.injuries;
    },
    injuryBodyPartTypesStatistics(state) {
        return state.injuryBodyPartTypesStatistics;
    },
}

export default {
    state,
    actions,
    mutations,
    getters
};