/* eslint-disable */

import {
    API_URL
} from "@/common/config";
import client from '@/helpers/axios.api';

let controller = new AbortController();

const exportPDF = function exportPDF(formId) {
    return client.get(`${API_URL}/form-manager/form-export/pdf?formId=${formId}`, {
        signal: controller.signal,
        responseType: 'blob',
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
    });
};

export default {
    exportPDF,
};