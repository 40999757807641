
import { mapActions, mapGetters } from "vuex";
import { defineComponent } from "vue";
import { useRouter, useRoute } from 'vue-router';


import Table from "@/views/table/Table.vue";

import { GET_INJURIES } from "@/store/injury/action.types";
import { GET_FORM_PACKAGES_OBJECTS } from "@/store/formPackage/action.types";

export default defineComponent({
  name: "Login",
  setup() {
    const router = useRouter();
    const route = useRoute();

    let structure = [
      {
        name: "Jméno",
        getLabel: (option) => {
          console.log(option.data);
          return option?.data?.PersonStorage?.name || "";
        },
      },
      {
        name: "Datum narozeni",
        getLabel: (option) => option.data?.PersonStorage?.birthDate || "",
        render: (option) => {
          let birthDateString = option.data?.PersonStorage?.birthDate
          if (!birthDateString)
            return "";

          let birthDate = new Date(birthDateString);
          if (isNaN(birthDate.getTime()))
            return "";

          return birthDate.toLocaleDateString()
        },
      },
      {
        name: "Osobní číslo",
        getLabel: (option) => option.data?.PersonStorage?.personNumber || "",
      },
      {
        name: "Trvalé bydliště",
        getLabel: (option) => option.data?.PersonStorage?.residenceAddress || "",
      },
      {
        name: "Datum úmrtí",
        getLabel: (option) => option.data?.injuryStorage?.DateOfDeath || "",
      },
    ];

    const actions = [
      {
        name: "Otevřít",
        click: (column) => {
          route.params.formPackageId = column.formPackageId;
          router.push({
            name: 'Package',
            params: {
              ...route.params,
            },
          });
        },
      },
    ];

    const tableSetting = {
      title: "Úrazy",
    }

    return {
      structure,
      tableSetting,
      actions,
    };
  },
  // data() {
  //   return {
  //     structure: [
  //       {
  //         name: "Jméno",
  //         getLabel: (option) => option.data?.PersonStorage?.name,
  //       },
  //       // {
  //       //   name: "Datum narozeni",
  //       //   getLabel: (option) => option.data?.PersonStorage?.birthDate,
  //       // },
  //       // {
  //       //   name: "Osobní číslo",
  //       //   getLabel: (option) => option.data?.PersonStorage?.personNumber,
  //       // },
  //       // {
  //       //   name: "Trvalé bydliště",
  //       //   getLabel: (option) => option.data?.PersonStorage?.residenceAddress,
  //       // },
  //       // {
  //       //   name: "Datum úmrtí",
  //       //   getLabel: (option) => option.data?.injuryStorage?.DateOfDeath,
  //       // },
  //     ],
  //   };
  // },
  computed: {
    ...mapGetters(["injuries", "formPackagesObjects"]),
  },
  methods: {
    ...mapActions([GET_INJURIES, GET_FORM_PACKAGES_OBJECTS]),
  },
  components: {
    Table,
  },
  mounted() {
    // this.getInjuries();
    this.getFormPackagesObjects([108, 109]);
  },
});
