
import { mapActions, mapGetters, mapMutations, useStore } from "vuex";
import {
  defineComponent,
  computed,
  watch,
  ref,
  onMounted,
  onUnmounted,
} from "vue";
import { useRoute, useRouter } from "vue-router";

import FormSetting from "./FormSetting.vue";
import FormNavigation from "./FormNavigation.vue";
import FormHistoryTab from "./FormHistoryTab.vue";

import formExportService from "@/services/formExport.service";

import { useNotification } from "@kyvg/vue3-notification";

import { GET_FORM_PACKAGE_SCHEME_VERSION } from "@/store/formPackage/action.types";
import { SET_FORM_PACKAGE_SCHEME_VERSION } from "@/store/formPackage/mutation.types";

import { CHECK_FORM_DATA, SAVE_FORM_DATA } from "@/store/formData/action.types";

import { GET_FORM_SCHEME } from "@/store/formStructure/action.types";

import { pepa, getData } from "./formActions.js";

export default defineComponent({
  name: "Form",
  components: {
    FormSetting,
    FormNavigation,
    FormHistoryTab,
  },
  computed: {
    ...mapGetters([
      "formPackageSchemeVersion",
      "formSectionFirst",
      "objectData",
      "formScheme",
    ]),
  },
  methods: {
    ...mapActions([GET_FORM_PACKAGE_SCHEME_VERSION, GET_FORM_SCHEME]),
    ...mapMutations([SET_FORM_PACKAGE_SCHEME_VERSION]),
  },
  setup() {
    const store = useStore();
    const route = useRoute();


    let options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };

    const lastFormSavedDate = computed(() => {
      let val = store.getters.lastFormSavedDate;
      if (val) {
        return new Date(val).toLocaleString("cs", options);
      }

      return null;
    });

    const sid = computed(() => {
      var routeSid = route.query.sid;

      if (routeSid == null) {
        return 0;
      }

      return routeSid;
    });

    const { notify } = useNotification();

    const fpfsvid = computed(() => route.query.fpfsvid)
    const formDataErrors = computed(() => store.getters.formDataErrors);

    const formSectionData = computed(() => store.getters.formSectionData);
    const formSectionId = computed(() => route.query.sid);
    const formTabId = computed(() => route.query.fpfsvid);

    const pepa2 = async (value) => {
      notify({
        text: "Měním stav formuláře",
        type: "info",
        duration: 3500,
      });

      if (formSectionData.value) {
        let saveFormDataRequest = await store.dispatch(SAVE_FORM_DATA, {
          formId: formTabId.value,
          data: formSectionData.value,
          formSectionId: formSectionId.value,
        });
      }

      let checkDataRequest = await store.dispatch(CHECK_FORM_DATA, fpfsvid.value);

      if (formDataErrors && formDataErrors.value && formDataErrors.value.length > 0) {
        let errorText = "Formulář obsahuje chyby";

        notify({
          text: errorText,
          type: "error",
          duration: 5000,
        });

        return;
      }

      pepa(value);

      let text = "";
      if (value == 1)
        text = "Úraz otevřen."
      else if (value == 2)
        text = "Úraz uzavřen.";
      else if (value == 3)
        text = "Prvotní úraz uzavřen.";

      notify({
        text: text,
        type: "success",
        duration: 5000,
      });

    };

    console.log("NACTI");
    // let cs = loadObjects();

    onMounted(() => {
      console.log("FORM.VUE - mounted");
      // loadObjects();
    });
    onUnmounted(() => {
      console.log("FORM.VUE - UNmounted");
    });

    const fpfsid = computed(() => store.getters.formPackageScheme.id);
    const fsvid = computed(() => route.query.fsvid)

    const exportPdf = () => {
      console.log("CLICK");
      notify({
        text: "Vytvářím PDF formulář 🎉... vyčkejte na stažení.",
        type: "success",
        duration: 5000,
      });
      formExportService.exportPDF(fpfsid.value);
    };

    return {
      exportPdf,
      sid,
      pepa2,
      fsvid,
      pepan: computed(() => getData()),
      lastFormSavedDate,
    };
  },
});
