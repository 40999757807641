
import { defineComponent, computed, onMounted, ref } from "vue";

import ModalDialog from "@/views/components/ModalDialog.vue";

import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

import { GET_FORM_SCHEMES_TYPES } from "@/store/formStructure/action.types.js";
import { ADD_FORM_SCHEME_TO_PACKAGE, GET_FORM_PACKAGE } from "@/store/formPackage/action.types.js";

const components = {
  ModalDialog,
};

export default defineComponent({
  name: "FormAdd",
  components,
  setup() {
    const store = useStore();

    const router = useRouter();
    const route = useRoute();

    const showModal = ref(false);
    const formPackageId = ref();

    const closeModal = function () {
      showModal.value = false;
    };

    onMounted(() => {
      formPackageId.value = route.params.formPackageId;
      store.dispatch(GET_FORM_SCHEMES_TYPES);
    });

    const addFormSchemeVersionToPackage = function (formScheme) {
      const formSchemeId = formScheme.id;
      const formSchemeVersionId = formScheme.latestFormSchemeVersion.id;

      const model = {
        formSchemeId: formSchemeId,
        formSchemeVersionId: formSchemeVersionId,
        formPackageId: formPackageId.value,
      };

      store.dispatch(ADD_FORM_SCHEME_TO_PACKAGE, model).then((evt)=>{
        store.dispatch(GET_FORM_PACKAGE, formPackageId.value)
      });
    };

    return {
      formSchemesTypes: computed(() => store.getters.formSchemesTypes),
      showModal,
      closeModal,
      addFormSchemeVersionToPackage,
    };
  },
});
