import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"width":"500px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!
  const _component_ControlWrapper = _resolveComponent("ControlWrapper")!

  return (_openBlock(), _createBlock(_component_ControlWrapper, {
    details: _ctx.details,
    errors: _ctx.errors
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_VueDatePicker, {
          modelValue: _ctx.localData,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localData) = $event)),
            _ctx.save
          ],
          "enable-time-picker": true,
          "format-locale": _ctx.cs,
          format: _ctx.formatDate,
          cancelText: "Zrušit",
          selectText: "Vybrat",
          utc: "",
          "close-on-scroll": "",
          "auto-apply": "",
          "enable-seconds": ""
        }, null, 8, ["modelValue", "onUpdate:modelValue", "format-locale", "format"])
      ]),
      (_ctx.localData != _ctx.getStorageObjectData && (_ctx.localData != null && _ctx.getStorageObjectData))
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "InputControl__button",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.setDataFromStorage && _ctx.setDataFromStorage(...args)))
          }, "Načíst data ze storage"))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["details", "errors"]))
}