

import { defineComponent, computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from 'vue-router';

import '@/assets/setting.scss';
import ControlWrapper from "../../form/controls/ControlWrapper.vue";



const components = {
    ControlWrapper
}


export default defineComponent({
    name: "UserAddd",
    components: components,
    setup() {
        const store = useStore();

        const router = useRouter();
        const route = useRoute();


        return {

        }
    },
});
