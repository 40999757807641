<template>
  <div class="dashboard_chart_container">
    <div class="dashboard_chart_title card_header">Graf úrazů v měsících</div>
    <div class="dashboard_chart_wrapper">
      <div ref="chartElement"></div>
    </div>
  </div>
</template>

<script lang="js">
/* eslint-disable */

import { defineComponent, ref, watch, onMounted, computed } from "vue";
import { getInjuryCountByMonthWithValue } from "./injuryStatistics.js";
import { useRoute } from "vue-router";

google.charts.load('current', { 'packages': ['corechart'] })

const lineChartOptions = {
  // title: 'Data Line',
  width: '100%',
  height: 300,
  curveType: 'none', // 'function' - The angles of the line will be smoothed.
  legend: { position: 'none' },
  theme: 'maximized',
  chartArea: {
    width: '90%',
    height: '80%',
    // backgroundColor: {
    //     stroke: '#000000',
    //     strokeWidth: 1
    // }
  },
  pointSize: 5,
  hAxis: { minValue: 0 },
  vAxis: {
    textPosition: 'out',
    gridlines: {
      color: '#A2A2A2',
      count: 2
    }
  },
  hAxis: {
    // title: 'Měsíce',
    textPosition: 'out',
    // titleTextStyle: {
    //     color: '#333'
    // },
    // baseline: 0,
    gridlines: {
      color: '#A2A2A2',
      count: 4
    },
    ticks: [{ v: 0, f: 'Leden' }, { v: 1, f: 'Únor' }, { v: 2, f: 'Březen' }, { v: 3, f: 'Duben' }, { v: 4, f: 'Květen' }]
  },
  series: {
    0: { color: "#aa1f27" }
  }
}

export default defineComponent({
  name: "Table",
  setup() {
    const route = useRoute();
    let chartData = ref([]);
    const headings = ['Tiempo', 'Počet zranění'];
    const chartElement = ref(null);
    let chart = ref();
    let injuryData = computed(() => {return getInjuryCountByMonthWithValue(5)});


    watch(
      () => route.params.projectId,
      (projectKey) => {
        if (!projectKey) return;

        google.charts.setOnLoadCallback(() => createChart());

      },
      { immediate: true }
    );

    const createChart = () => {
      chart.value = new google.visualization.LineChart(chartElement.value); // 👈 use ref here
      console.log("tady změna")
    }

    function drawChart() {
      let tempChartArray = [];
      let hAxisTicks = [];

      for (let i = 0; i < injuryData.value.length; i++) {
        const element = injuryData.value[i];

        const monthName = element.fromDate.toLocaleString('default', { month: 'long' });

        tempChartArray.push([{
          v: Number(i),
          f: monthName,
        }, element.value]);

        hAxisTicks.push({
          v: Number(i),
          f: monthName,
        })
      }


      lineChartOptions.hAxis.ticks = [...hAxisTicks];
      chartData.value = [...tempChartArray];

      const dataTable = google.visualization.arrayToDataTable([
        headings,
        ...chartData.value
      ], false); // 👈 don't forget "false" here to indicate the first row as labels

      chart.value.draw(dataTable, lineChartOptions);
    }

    watch([injuryData, chart], ([injuryData, chart]) => {
      console.log(injuryData)
      console.log(chart)
      if (!injuryData || !chart) return;

      console.log(chart);

      console.log("data!!");
      drawChart();
    },
      { immediate: true }
    );

 

    return {
      chartData,
      chartElement,
    }
  },
});
</script>

<style lang="scss">
.dashboard_chart_container {
  height: 100%;
  padding: 10px 25px;

  .dashboard_chart_title {
    padding: 10px 0px;
    font-weight: 700;
  }

  .dashboard_chart_wrapper {
    height: 100%;
  }
}
</style>