/* eslint-disable */

import {
    API_URL
} from "@/common/config";
import client from '@/helpers/axios.api';


const getTeam = async function getTeam(key) {
    if(key != null)
    return {
        name: "pepa",
        key: "CD",
    };
    else
    return null;
    try {
        return await client.get(`${API_URL}/team-manager/teams/${key}`);
    } catch (e) {
        return null;
    }
};

export default {
    getTeam,
};