

import { defineComponent, computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from 'vue-router';

import '@/assets/setting.scss';

import Table from "@/views/table/Table.vue";
import ModalDialog from '@/views/components/ModalDialog.vue';
import TableSetting from "@/views/table/TableSetting.vue";
import TableSearch from "@/views/table/TableSearch.vue";

import ProjectService from "@/services/project.service";

const components = {
  Table,
  ModalDialog,
  TableSearch,
  TableSetting,
}


export default defineComponent({
  name: "UserSetting",
  components: components,
  setup() {
    const store = useStore();

    const router = useRouter();
    const route = useRoute();
    const showModal = ref(false);

    let formPackageAddName = ref();

    let usersData = ref(null);

    const structure = [
      {
        name: "Id uživatele",
        objectName: "id",
        click: (item) => {
          route.params.formPackageId = item.formPackageId;
          router.push({
            name: 'Package',
            params: {
              ...route.params,
            },
          });
        },
      },

      {
        name: "Jméno",
        objectName: "firstName",
      },

      {
        name: "Příjmení",
        objectName: "lastName",
      },
      {
        name: "Datum narození",
        objectName: "birthDate",
        render: (createdDate) => {
          return new Date(createdDate).toLocaleString()
        },
      },

    ];

    const actions = [
      {
        name: "Otevřít",
        click: (column) => {
          route.params.formPackageId = column.formPackageId;
          router.push({
            name: 'Package',
            params: {
              ...route.params,
            },
          });
        },
      },
      {
        name: "Smazat",
        click: (column) => {
          // let formPackageId = column.formPackageId;
          // var addFormPackage = store.dispatch(DELETE_FORM_PACKAGE, formPackageId);
          // addFormPackage.then(() => {
          //   store.dispatch(GET_FORM_PACKAGES);
          // });
        },
      }
    ];


    const closeModal = function () {
      showModal.value = false;
    }

    onMounted(async () => {
      usersData.value = (await ProjectService.getProjectUsers({ limit: 10, skip: 1 })).data;
    });

    const tableSetting = {
      title: "Pracovníci v projektu",
    }

    return {
      usersData,
      structure,
      actions,
      showModal,
      closeModal,
      tableSetting,
      formPackageAddName,
    }
  },
});
