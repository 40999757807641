/* eslint-disable */

import {
    API_URL
} from "@/common/config";
import client from '@/helpers/axios.api';


const getObjectSchemas = function getObjectSchemas(formPackageId) {
    return client.get(`${API_URL}/form-manager/object-schemas`, {
        params: {
            formPackageId: formPackageId
        }
    });
};

const getObjectData = async function (model) {
    try {
        return await client.get(`${API_URL}/form-manager/form-packages/${model.formPackageId}/form-object-scheme/${model.formObjectSchemeId}/form-object-data/${model.id}`);
    } catch (e) {
        return null;
    }
};

const saveObjectData = async function (model) {
    try {
        return await client.post(`${API_URL}/form-manager/form-object-data/1`, model);
    } catch (e) {
        return null;
    }
};

const addObjectDataToPackage = async function (model) {
    try {
        return await client.post(`${API_URL}/form-packages/53/form-object`, model);
    } catch (e) {
        return null;
    }
};

export default {
    getObjectSchemas,
    getObjectData,
    saveObjectData,
    addObjectDataToPackage,
};