<template>
  <div class="head_container">
    <transition name="fade">
      <div class="head_bar">
        <!-- <slot name="menuHamburger"></slot> -->
        <slot name="companySelect"></slot>
        <div class="center" v-if="!isLoading">
          <div class="left">
            <slot name="menuHamburger"></slot>
            <div class="top_menu_container">
              <div class="top_menu">
                <router-link
                  v-if="currentCompany && currentCompany.name"
                  :to="'/' + currentCompany.name + '/nastaveni'"
                  class="top_menu_item"
                  >Nastavení</router-link
                >
                <!-- <router-link :to="{name:'app'}" class="top_menu_item">Všechny projekty</router-link> -->
                <router-link
                  v-if="currentCompany && currentCompany.name"
                  :to="'/' + currentCompany.name + '/package/1'"
                  class="top_menu_item"
                  >Formuláře</router-link
                >
              </div>
            </div>
          </div>
          <div class="right">
            <div class="icon_container">
              <div class="icon" style="width: 20px">
                <HeadNotification />
              </div>
              <div class="icon" style="width: 20px">
                <HeadNavigation />
              </div>
              <div class="icon">
                <HeadAccount />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import HeadNotification from "./HeadNotification.vue";
import HeadAccount from "./HeadAccount.vue";
import HeadNavigation from "./HeadNavigation.vue";

import { mapGetters } from "vuex";

export default {
  name: "Head",
  props: {
    isLoading: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
  },
  components: {
    HeadNotification,
    HeadAccount,
    HeadNavigation,
  },
  computed: {
    ...mapGetters(["currentCompany"]),
  },
};
</script>

<style lang="scss" >
.head_container {
  height: 100%;
  width: 100%;
  background: red;
}

.head_bar {
  .head {
    background: #c1c1c1 !important;
  }

  .center {
    height: 100%;
    .left {
      float: left;
      height: 100%;
      .icon_container {
        height: 100%;
        flex: 1 0 auto;
        display: inline-flex;
        align-items: center;
        .hamburger {
          width: 20px;

          cursor: pointer;
          svg {
            path {
              fill: white;
            }
          }
        }
      }

      .top_menu_container {
        display: inline-block;
        .top_menu {
          margin-left: 10px;
          .top_menu_item {
            display: inline-block;
            color: white;
            margin-left: 25px;
            cursor: pointer;
          }
        }
      }
    }

    .right {
      float: right;
      height: 100%;
      .icon_container {
        height: 100%;
        flex: 1 0 auto;
        display: flex;
        align-items: center;

        .icon {
          display: inline-block;
          margin-right: 15px;
          position: relative;
          svg {
            path {
              fill: white;
            }
          }
        }
      }
    }
  }
}
</style>