import {
    computed,
    onMounted,
    ref,
    watch,
    watchEffect,
    Ref,
} from "vue";
import { useStore } from "vuex";
import { DELETE_FORM_DATA_ERROR } from "@/store/formData/mutation.types";

import { IControl } from "../interfaces/IControl";

export default function useBase<T, R>(control: IControl<T>) {

    const store = useStore();


    const field = control.props.details;

    const refData = computed(() => {
        if (!store.getters.formSectionData) return null;

        return store.getters.formSectionData[field.name];
    });


    const isFormSectionDataLoaded = computed(() => {
        let formSectionData = store.getters.formSectionData;

        if (formSectionData == null)
            return false;
        else
            return true;
    });




    let objectData = computed(() => store.getters.objectData);

    // type 1 is get? (i think)
    const getStorageObject = field.objectFieldJoins.filter(s => s.type == 1 && s.objectFieldType == "storage")[0];

    let getStorageObjectData = computed(() => {
        if (!getStorageObject || !getStorageObject.name)
            return null;

        let dataStorage = objectData.value[getStorageObject.objectId]?.data?.storage;

        if (!dataStorage || !dataStorage[getStorageObject.name])
            return null;

        return dataStorage[getStorageObject.name];
    });

    const setDataFromStorage = () => {
        localData.value = getStorageObjectData.value;
        save();
    };




    const localData = ref<R>(null) as Ref<R>;

    const previousData = ref<R>(localData.value) as Ref<R>;

    const save = () => {
        if (localData.value == null) return;

        // if new data is same as previous
        if (previousData.value == localData.value) return;

        console.log("ukladam data ", field);

        changeData();

        control.emit("formDataSave");

        // set new data as previous
        previousData.value = localData.value;
    };

    const changeData = () => {
        control.emit("formDataChange", {
            name: field.name,
            value: localData.value,
        });
    }


    const errors = computed((): Array<object> => control.props.errors as Array<Object>);

    const hasError = computed((): boolean => {
        return errors != null && errors.value.length != null && errors.value.length > 0
    });

    watch(localData, (val: R) => {
        if (!hasError.value)
            return;

        if (control.checkData(val)) {
            store.commit(DELETE_FORM_DATA_ERROR, { fieldId: field.id });
        }
    }, {
        deep: true,
    });


    watchEffect(() => {
        if (refData.value != null) {
            console.log("ZM2NA ", refData.value);
            localData.value = refData.value;
        }
    });


    return {
        field,
        getStorageObjectData,
        refData,
        errors,
        localData,
        save,
        hasError,
        setDataFromStorage,
        changeData,
        isFormSectionDataLoaded,
    }
}