import { createStore } from 'vuex'
import Authentication from "./authentication/index.js";
import Injury from "./injury/index.js";
import FormStructure from "./formStructure/index.js";
import FormData from  "./formData/index.js";
import FormPackage from "./formPackage/index.js";
import Company from "./company/index.js";
import FormObjectStructure from "./formObjectStructure/index.js";
import FormObjectData from './formObjectData/index.js';
import Project from './project/index.js';

export default createStore({
  modules: {
    Authentication,
    Injury,
    FormStructure,
    FormData,
    Company,
    FormPackage,
    FormObjectStructure,
    FormObjectData,
    Project,
  }
})
