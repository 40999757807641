import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "injury_table_container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.injuries, (injury, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "injury_table_container__block",
        key: index
      }, [
        _createElementVNode("pre", null, "        " + _toDisplayString(injury) + "\n      ", 1)
      ]))
    }), 128))
  ]))
}