import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "block_container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjuryStatisticsCard = _resolveComponent("InjuryStatisticsCard")!
  const _component_InjuryStatisticsCardLoad = _resolveComponent("InjuryStatisticsCardLoad")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.injuryStatistics)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.injuryStatistics, (injuryStatisticsModel, index) => {
          return (_openBlock(), _createBlock(_component_InjuryStatisticsCard, {
            key: index,
            injuryStatisticsModel: injuryStatisticsModel
          }, null, 8, ["injuryStatisticsModel"]))
        }), 128))
      : _createCommentVNode("", true),
    (!_ctx.injuryStatistics)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, _renderList(5, (index) => {
          return _createVNode(_component_InjuryStatisticsCardLoad, { key: index })
        }), 64))
      : _createCommentVNode("", true)
  ]))
}