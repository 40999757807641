import actions from './actions';
import mutations from './mutations';
import state from './state';

const getters = {
   currentCompany(state){
       return state.currentCompany;
   },
   myUserOperations(state){
       return state.myUserOperations;
   }
}

export default {
    state,
    actions,
    mutations,
    getters
};