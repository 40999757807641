import { IControl } from "../interfaces/IControl";

export default class TextControl implements IControl<string> {
    props: any;
    emit: any;

    value: string;

    constructor(props: any, emit: any) {
        this.props = props;
        this.emit = emit;
    }

    checkData(text: string): boolean {
        let isValid: boolean = false;

        if (!text)
            return isValid;

        if (text.trim() == "")
            return isValid;

        isValid = true;

        return isValid;
    }
}
