import {
    SET_FORM_PACKAGE,
    SET_FORM_PACKAGE_SCHEME,
    SET_FORM_PACKAGE_SCHEMES,
    SET_FORM_PACKAGE_SCHEME_VERSION,
    SET_FORM_PACKAGES,
    SET_FORM_TAB_OBJECTS,
    SET_FORM_PACKAGES_OBJECTS,
} from "./mutation.types";
import {
    GET_FORM_PACKAGE,
    GET_FORM_PACKAGE_SCHEME,
    GET_FORM_PACKAGE_SCHEMES,
    GET_FORM_PACKAGE_SCHEME_VERSION,
    GET_FORM_PACKAGES,
    ADD_FORM_PACKAGE,
    ADD_FORM_SCHEME_TO_PACKAGE,
    DELETE_FORM_PACKAGE,
    DELETE_FORM_PACKAGE_SCHEME,
    GET_FORM_TAB_OBJECTS,
    GET_FORM_PACKAGES_OBJECTS,
} from "./action.types";

import formPackageServiceApi from "@/services/formPackage.service";


const actions = {
    async [GET_FORM_PACKAGE]({ commit }, formPackageId) {
        const formPackageRequest = formPackageServiceApi.getPackage(formPackageId);

        formPackageRequest
            .then(function (response) {
                commit(SET_FORM_PACKAGE, response.data);
            })
            .catch(function (err) {
                commit(SET_FORM_PACKAGE, {});
            });
    },
    async [GET_FORM_PACKAGE_SCHEME]({ commit }, formPackageFormSchemeId) {
        console.log("jedu GET_FORM_PACKAGE_SCHEME: ", formPackageFormSchemeId);
        const formPackageRequest = formPackageServiceApi.getPackageScheme(formPackageFormSchemeId);

        formPackageRequest
            .then(function (response) {
                commit(SET_FORM_PACKAGE_SCHEME, response.data);
            })
            .catch(function (err) {
                commit(SET_FORM_PACKAGE_SCHEME, { schemeVersions: [] });
            });

        return formPackageRequest;
    },
    async [GET_FORM_PACKAGE_SCHEME_VERSION]({ commit }, formPackageFormSchemeVersionId) {
        const formPackageRequest = formPackageServiceApi.getPackageSchemeVersion(formPackageFormSchemeVersionId);

        formPackageRequest
            .then(function (response) {
                commit(SET_FORM_PACKAGE_SCHEME_VERSION, response.data);
            })
            .catch(function (err) {
                commit(SET_FORM_PACKAGE_SCHEME_VERSION, {});
            });
    },
    async [GET_FORM_PACKAGES]({ commit }) {
        const formPackageRequest = formPackageServiceApi.getPackages();

        formPackageRequest
            .then(function (response) {
                commit(SET_FORM_PACKAGES, response.data);
            })
            .catch(function (err) {
                commit(SET_FORM_PACKAGES, {});
            });
    },
    async [ADD_FORM_PACKAGE]({ commit }, formPackage) {
        const formPackageRequest = formPackageServiceApi.addFormPackage(formPackage);

        formPackageRequest
            .then(function (response) {
                // commit(SET_FORM_PACKAGES, response.data);
            })
            .catch(function (err) {
                // commit(SET_FORM_PACKAGES, {});
            });

        return formPackageRequest;
    },
    async [ADD_FORM_SCHEME_TO_PACKAGE]({ commit }, model) {
        const formAddFormSchemeToPackageRequest = formPackageServiceApi.addFormSchemeToPackage(model);

        formAddFormSchemeToPackageRequest
            .then(function (response) {
                // commit(SET_FORM_PACKAGES, response.data);
            })
            .catch(function (err) {
                // commit(SET_FORM_PACKAGES, {});
            });

        return formAddFormSchemeToPackageRequest;
    },
    async [DELETE_FORM_PACKAGE]({ commit }, formPackageId) {
        const deleteFormPackageResult = await formPackageServiceApi.deleteFormPackage(formPackageId);
        return deleteFormPackageResult;
    },
    async [DELETE_FORM_PACKAGE_SCHEME]({ commit }, formPackageFormSchemeId) {
        const deletePackageSchemeResult = await formPackageServiceApi.deletePackageScheme(formPackageFormSchemeId);
        return deletePackageSchemeResult;
    },
    async [GET_FORM_TAB_OBJECTS]({ commit }, formTabId) {
        const formTabObjectsResult = await formPackageServiceApi.getFormTabObjects(formTabId);

        if (formTabObjectsResult == null)
            commit(SET_FORM_TAB_OBJECTS, []);
        else
            commit(SET_FORM_TAB_OBJECTS, formTabObjectsResult.data);

        return formTabObjectsResult;
    },
    async [GET_FORM_PACKAGES_OBJECTS]({ commit }, formPackagesId) {
        const formPackagesObjectsResult = await formPackageServiceApi.getObjects(formPackagesId);
console.log(formPackagesObjectsResult);
        if (formPackagesObjectsResult == null)
            commit(SET_FORM_PACKAGES_OBJECTS, []);
        else
            commit(SET_FORM_PACKAGES_OBJECTS, formPackagesObjectsResult.data);

        return formPackagesObjectsResult;
    },
}

export default actions;