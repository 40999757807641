import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "dashboard_injury_table" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Table = _resolveComponent("Table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Table, {
      structure: _ctx.structure,
      data: _ctx.formPackagesObjects,
      actions: _ctx.actions,
      setting: _ctx.tableSetting
    }, null, 8, ["structure", "data", "actions", "setting"]),
    (false && _ctx.formPackagesObjects)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("pre", null, "        " + _toDisplayString(_ctx.formPackagesObjects) + "\n      ", 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}