/* eslint-disable */

import {
    API_URL
} from "@/common/config";
import client from '@/helpers/axios.api';

/**
 * 
 * @param {*} formSchemeVersionId 
 * @returns Form scheme details with sections
 */
const getScheme = function getScheme(formSchemeVersionId, controller) {
    return client.get(`${API_URL}/form-manager/form-schemes/${formSchemeVersionId}`, {signal: controller.signal});
};

/**
 * 
 * @param {*} formSchemeVersionId 
 * @returns Form sections with details
 */
const getSections = function getSections(formSchemeVersionId) {
    // cancel the request
    // controller.abort();
    // controller = new AbortController();

    return client.get(`${API_URL}/form-manager/form-schemes/${formSchemeVersionId}/sections`);
    // return client.get(`${API_URL}/form-manager/form-schemes/${formSchemeVersionId}/sections`, {signal: controller.signal});
};

/**
 * 
 * @param {*} formSchemeVersionId 
 * @param {*} sectionId 
 * @returns Form section details with fields
 */
const getSection = function getSection(formSchemeVersionId, sectionId) {
    return client.get(`${API_URL}/form-manager/form-schemes/${formSchemeVersionId}/sections/${sectionId}`);
};

/**
 * 
 * @returns 
 */
const getSchemesTypes = function getSchemesTypes() {
    return client.get(`${API_URL}/form-manager/form-schemes-types`);
};

export default {
    getScheme,
    getSections,
    getSection,
    getSchemesTypes,
};
