<template>
  <div class="dashboard_injury_table">
    <Table :structure="structure" :setting="tableSetting" :data="data" />
  </div>
</template>

<script>
import { mapActions, mapGetters, useStore } from "vuex";
import {
  defineComponent,
  computed,
  onMounted,
  onBeforeUnmount,
  ref,
} from "vue";
import { useRouter, useRoute } from "vue-router";

import Table from "@/views/table/Table.vue";

// import { GET_PROJECTS } from "@/store/project/action.types.js";

export default defineComponent({
  name: "ApplicationTable",
  components: {
    Table,
  },
  setup() {
    const store = useStore();

    const router = useRouter();
    const route = useRoute();

    let windowWidth = ref(window.innerWidth);
    const onResize = function () {
      windowWidth.value = window.innerWidth;
    };

    onMounted(() => {
      window.addEventListener("resize", onResize);

      // store.dispatch(GET_PROJECTS, "zeman");
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", onResize);
    });

    const user = computed(() => store.getters.user);

    const tableSetting = {
      title: `Projekty pro uživatele ${user.value.profile.firstName} ${user.value.profile.lastName}`,
      showCheck: false,
    };

    const projects = computed(() => store.getters.projects);
    const data = projects;

    // const data = [
    //   {
    //     name: "Safeko úrazový model testovaci",
    //     license: {
    //       name: "Software pro big businesssssss",
    //     },
    //     projectSiteAdmin: "Michal Zeman",
    //     team: "Safeko s.r.o.",
    //     key: "safeko-s.r.o.",
    //   },
    //   {
    //     name: "Úrazy naší společnosti",
    //     license: {
    //       name: "Software pro big business",
    //     },
    //     projectSiteAdmin: "Petr Pavel",
    //     team: "Ortodoncie MUDr. Pavel Michalík s.r.o.",
    //   },
    //   {
    //     name: "Formulaře pro úrazy test",
    //     license: {
    //       name: "Software pro big business",
    //     },
    //     projectSiteAdmin: "Michal Zeman",
    //     team: "Safeko s.r.o.",
    //   },
    // ];

    let windowHasSmallWidth = windowWidth.value < 800;

    const structure = [
      {
        name: "Název",
        objectName: "name",
        getLabel: (option) => option.name,
        click: (neco) => {
          router.push({ name: "dashboard", params: { projectId: neco.key } });
        },
        color: "#005aff",
      },
      {
        name: "Typ / Licence",
        getLabel: (option) => option.license.name,
        objectName: "license",
        hidden: windowHasSmallWidth,
      },
      {
        name: "Vedoucí",
        getLabel: (option) => option.projectSiteAdmin,
        objectName: "projectSiteAdmin",
        hidden: windowHasSmallWidth,
      },
      {
        name: "Operation",
        getLabel: (option) => option.team,
        objectName: "team",
        hidden: windowHasSmallWidth,
      },
    ];

    return {
      tableSetting,
      data,
      structure,
      windowWidth,
    };
  },
});
</script>

<style>
</style>