/* eslint-disable */

import {
    API_URL
} from "@/common/config";
import client from '@/helpers/axios.api';
import { config } from "chai";

let controller = new AbortController();
let controller2 = new AbortController();

const getData = function getData(formId, formSectionId) {
    console.log("GET DATAAAAAAAAAAAAAA");
    return client.get(`${API_URL}/form-manager/form-data/${formId}/section/${formSectionId}`, {
        signal: controller2.signal
    });
};

const saveData = function saveData({
    formId,
    formSectionId,
    data
}) {
    console.log("SAVEEEEEEEEEEEEEEEEEEEEEEEE");
    // cancel the request
    controller.abort();
    controller = new AbortController();
    return client.post(`${API_URL}/form-manager/form-data/${formId}/section/${formSectionId}`, {
        formData: data
    }, {signal: controller.signal});

}

const checkFormData = async function checkFormData(formTabId) {
    try {
        return await client.get(`${API_URL}/form-manager/form-data/check-field-data/${formTabId}`);
    } catch (e) {
        return null;
    }
};

export default {
    getData,
    saveData,
    checkFormData,
};