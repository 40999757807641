<template>
  <component :is="layout">
    <notifications style="top: 42px;" />
    <router-view v-slot="{ Component }" >
      <!-- <transition name="fade" mode="out-in"> -->
        <component :is="Component" />
      <!-- </transition> -->
    </router-view>
  </component>
</template>

<script>
import { defineComponent, onMounted, ref, reactive, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

const defaultLayout = "Default";

export default defineComponent({
  computed: {
    layout() {
      return "Layout" + (this.$route.meta.layout || defaultLayout);
    },
  },
});
</script>

<style lang="scss">
// .fade-enter-active,
// .fade-leave-active {
//   transition: opacity 0.3s ease;
// }

// .fade-enter-from,
// .fade-leave-to {
//   opacity: 0;
// }
html,
body {
  /* Positioning */
  padding: 0;
  margin: 0;

  /* Other */
  color: #6d6d6d;
  background-color: #eee;
  background-color: #eff1f5;
  font-size: 12px;
  overflow: hidden
}

input,
textarea {
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
}

body {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #5b5b5b;
}

* {
  box-sizing: border-box;
}
</style>

