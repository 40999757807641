import { ICheckBox } from "../interfaces/ICheckBox";
import { IControl } from "../interfaces/IControl";
import { IMultiCheckboxes } from "../interfaces/IMultiCheckboxes";

export default class MultiCheckboxes implements IMultiCheckboxes, IControl<ICheckBox[]> {
    props: any;
    emit: any;
    checkboxes: ICheckBox[];

    constructor(props: any, emit: any) {
        this.props = props;
        this.emit = emit;
    }

    checkData(checkboxes: ICheckBox[]): boolean {
        let isValid : boolean = false;

        if(!checkboxes || checkboxes.length <= 0)
            return isValid;
            
        checkboxes.forEach((checkbox : ICheckBox) => {
            if(checkbox.isChecked)
                isValid = true;
        });


        return isValid;
    }
}
