import InjuryServiceApi from "@/services/injury.service";
import { ref } from 'vue';


let data = ref(null);

export async function loadData(projectKey) {
    const startDateOfCurrentMonth = new Date();
    const endDateOfCurrentMonth = new Date();

    const startDateOfLastMonth = new Date();
    const endDateOfLastMonth = new Date();

    // const injuryStatisticsComplete = getData(null, endDateOfCurrentMonth);
    // const injuryStatisticsCurrentMonth = getData(startDateOfCurrentMonth, endDateOfCurrentMonth);
    // const injuryStatisticsLastMonth = getData(startDateOfLastMonth, endDateOfLastMonth);


    const injuryStatisticsComplete = await getData(projectKey, null, null);

    data.value = injuryStatisticsComplete;

    return injuryStatisticsComplete;
}

async function getData(projectKey, dateFrom, dateTo) {
    let statisticsData = await InjuryServiceApi.getStatisticsData({
        projectKey,
        dateFrom,
        dateTo
    });

    return statisticsData.data;
}

export function getDataArray(statisticsData) {
    return [{
            count: statisticsData.totalCountInjuries,
            name: "Celkový počet úrazů",
        },
        {
            count: 0,
            name: "Počet zaměstnanců v neschopnosti",
        },
        {
            count: 0,
            name: "Celkový počet úrazů",
        },
        {
            count: 0,
            name: "Celkový počet úrazů",
        },
        {
            count: 0,
            name: "Celkový počet úrazů",
        },
    ]
}

export function getInjuryCountByMonthWithValue(monthCount) {
    // if(!data || !data.value)
    //     return null;

    let date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth();

    let monthArray = [];

    for (let i = 0; i < monthCount; i++) {
        let firstDay = new Date(y, m, 1);
        let lastDay = new Date(y, m + 1, 0);
        // let value = getRandomInt(15);
        let value = getInjuryCountValue(m+1); // +1 because in js start months index 0

        monthArray.push({fromDate: firstDay, toDate: lastDay, value: value});

        if (m == 0) {
            y = y - 1;
            m = 11;
        } else {
            m = m - 1;
        }
    }

    return monthArray;
}

const getInjuryCountValue = (month) => {
    if(!data.value || !data.value.injuryMonthCounts)
        return 0;

    var monthInjury = data.value.injuryMonthCounts.find(item => item.month == month);
    
    if(monthInjury)
        return monthInjury.count;

    return 0;      
}

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }