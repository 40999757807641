import {
    SET_OBJECT_DATA
} from "./mutation.types";
import {
    GET_OBJECT_DATA,
    SAVE_OBJECT_DATA,
} from "./action.types";
import FormObjectStructureServiceApi from "@/services/formObjectStructure.service";

const actions = {
    async [GET_OBJECT_DATA]({
        commit
    }, model) {
        const formObjectGetDataRequest = await FormObjectStructureServiceApi.getObjectData(model);
        let objectData = formObjectGetDataRequest.data;

        if (!objectData)
            objectData = {};

        commit(SET_OBJECT_DATA, { objectSchemeId: model.formObjectSchemeId, objectData: objectData});
        return formObjectGetDataRequest;
    },
    async [SAVE_OBJECT_DATA]({
        commit
    }, model) {
        const formObjectSaveDataRequest = await FormObjectStructureServiceApi.saveObjectData(model);
        // commit(SET_OBJECT_DATA, {objectSchemeId: formObjectSaveDataRequest.data.formObjectSchemeId, objectData: formObjectSaveDataRequest.data});
        return formObjectSaveDataRequest;
    },
}

export default actions;