import {
    ref,
    computed,
    watch,
    watchEffect,
    reactive,
} from 'vue';
import {
    GET_OBJECT_DATA,
    SAVE_OBJECT_DATA,
} from "@/store/formObjectData/action.types";
import {
    SET_OBJECT_DATA
} from "@/store/formObjectData/mutation.types";

import {
    SET_FORM_SECTION_DATA
} from "@/store/formData/mutation.types";

import {
    SAVE_FORM_DATA
} from "@/store/formData/action.types";

import store from "@/store/index.js";
import route from "@/router/index.ts";


let useFormObjectStorage = function (objectSchema) {
    let debug = reactive(true);

    let date = new Date();
    const formPackage = computed(() => route.currentRoute.value.params.formPackageId);
    const objectsData = computed(() => store.getters.objectData);
    let isDataStorageLoaded = ref(false);
    let loadedFormScheme = computed(() => store.getters.formSection);
    const formSectionData = computed(() => store.getters.formSectionData);

    // states
    // console.log(objectSchema);
    const objectData = computed(() => {
        console.log(objectsData.value);
        console.log(objectSchema.id);
        const object = objectsData.value[objectSchema.id];
        if (!object) return {};

        const objectData = object.data;
        if (!objectData) return {};

        return objectData;
    });
    const objectAll = computed(() => {
        const object = objectsData.value[objectSchema.id];
        if (!object) return null;

        return object;
    });

    let options = {};

    let saveForm = (data) => {
        let formSectionId = route.currentRoute.value.query.sid;
        let formId = route.currentRoute.value.query.fpfsvid;

        store.dispatch(SAVE_FORM_DATA, {
            formId: formId,
            data: data,
            formSectionId: formSectionId,
        });
    }


    const loadObjectData = () => {
        const objectDataId = objectSchema.objectDataId;
        const objectSchemeId = objectSchema.id;

        store.dispatch(
            GET_OBJECT_DATA, {
            formObjectSchemeId: objectSchemeId,
            id: objectDataId, //objectDataId
            formPackageId: formPackage.value
        });
    };

    const load = () => {
        options.loadObjectData();
    };

    const saveObjectData = () => {
        const objectSchemeId = objectSchema.id;
        const projectId = route.currentRoute.value.params.projectId;
        console.log("######################################################################################", objectAll.value);
        let model = {
            ...objectAll.value,
            formObjectSchemeId: objectSchemeId,
            formPackageId: formPackage.value,
            project: projectId,
        };
        const formSchemeRequest = store.dispatch(
            SAVE_OBJECT_DATA,
            model,
        );
    }


    const objectFormFieldJoins = ref([]);

    objectSchema.fields.forEach(field => {
        var formFieldJoins = field.formFieldJoins;

        formFieldJoins.forEach(formFieldJoin => {

            // 1 je typ get .. chceme jen 2 což je set (nastavovani do form fieldu)
            if (formFieldJoin.type == 1)
                return;

            var objf = {
                ...formFieldJoin,
                objectId: field.id,
                objectName: field.name,
            };

            objectFormFieldJoins.value.push(objf);

        });
    });


    let changeData = (model) => {

        changeObjectData(model.dataType, model.data);
        checkAndSaveWithForm(model);
    }

    const checkAndSaveWithForm = (model) => {

        let changeObject = {};

        objectFormFieldJoins.value.forEach(formFieldWatch => {
            if (!formFieldWatch)
                return;

            // check if value is in a current loaded form scheme
            let isLoadedSectionForm = loadedFormScheme.value.fields.some(s => s.id == formFieldWatch.id);
            if (!isLoadedSectionForm)
                return; // if not skip it - no need to get that value

            var formValue = null;

            if (formSectionData != null && formSectionData.value != null) {
                formValue = formSectionData.value[formFieldWatch.name];
            }

            var objectValue = model.data[formFieldWatch.objectName];

            if ((formValue == null || formValue == "" || model.isHard) && (objectValue != null)) {
                changeObject[formFieldWatch.name] = objectValue;
            }
        });


        if (Object.keys(changeObject).length != 0) {
            var resultValueFormData = {
                ...formSectionData.value,
                ...changeObject,
            }

            store.commit(SET_FORM_SECTION_DATA, {
                formData: resultValueFormData
            });

            saveForm(resultValueFormData);
        }
    };


    // catch form section data change + watch for that data
    const formSectionDataJsonString = computed(() => JSON.stringify(formSectionData.value));
    // update in form data section fields
    watch(
        formSectionDataJsonString,
        (newValString, oldValString) => {
            debug && console.log("změna", objectSchema.id, date);
            debug && console.log(!isDataStorageLoaded.value)
            if (isDataStorageLoaded.value == false)
                return;

            debug && console.log("TUUUU");

            if (!newValString)
                return;

            let newVal = JSON.parse(newValString);
            let oldVal = JSON.parse(oldValString);
            debug && console.log(newVal);
            debug && console.log(oldVal);
            if (!newVal)
                return;

            // if (Object.keys(oldVal).length == 0)
            //     return;

            let changeObject = {};
            objectFormFieldJoins.value.forEach(formFieldWatch => {
                if (!formFieldWatch)
                    return;

                if (newVal == null || newVal[formFieldWatch.name] == null)
                    return;

                var fieldNewValue = newVal[formFieldWatch.name];
                var fieldOldValue = oldVal != null ? oldVal[formFieldWatch.name] : null;

                if (fieldNewValue != fieldOldValue) {
                    changeObject[formFieldWatch.objectName] = fieldNewValue;
                }
            });

            if (Object.keys(changeObject).length != 0) {
                const DATA_TYPE = "storage";
                debug && console.log("CHANGE OBJECT FORM DATA STORAGE ", changeObject);
                changeObjectData(DATA_TYPE, changeObject);
            }

        }, {
        deep: true
    }
    );


    // toto je pouze pro první načtení
    let unwatch = watch(
        [objectData, loadedFormScheme, formSectionData],
        () => {
            if (objectData.value && loadedFormScheme.value && formSectionData.value) {

                debug && console.log("a ještě tu")
                if (!loadedFormScheme.value || Object.keys(loadedFormScheme.value).length == 0) {
                    isDataStorageLoaded.value = true;

                    if (unwatch)
                        unwatch();
                    return;
                }

                if (!objectData.value.storage || Object.keys(objectData.value.storage).length == 0) {
                    isDataStorageLoaded.value = true;

                    if (unwatch)
                        unwatch();
                    return;

                }

                let objectValue = objectData.value.storage;

                let model = {
                    data: objectValue,
                    isHard: false,
                    dataType: "storage"
                }

                checkAndSaveWithForm(model);

                isDataStorageLoaded.value = true;

                unwatch();
            }

        });



    let timeoutId;
    const changeObjectData = (DATA_TYPE, changeData) => {
        // TODO 
        // nestaci pouze objectData? místo objectAll
        if (objectAll.value == null || Object.keys(objectAll.value).length == 0) {
            initEmptyObject();
        }

        if (!objectData.value[DATA_TYPE]) {
            initEmptyStorage(DATA_TYPE);
        }

        console.log("ULYP", objectData.value.citizenship);
        objectData.value[DATA_TYPE] = {
            ...objectData.value[DATA_TYPE],
            ...changeData,
        };
        console.log("ULYP", objectData.value);


        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            // Runs 1 second (1000 ms) after the last change
            saveObjectData();
        }, 1000);
    }

    const initEmptyObject = () => {
        const initData = {
            data: {
                storage: {}
            }
        };

        store.commit(SET_OBJECT_DATA, {
            objectSchemeId: objectSchema.id,
            objectData: initData,
        });
    }

    const initEmptyStorage = (DATA_TYPE) => {
        const initDataStorage = {};

        objectData.value[DATA_TYPE] = initDataStorage;
    }


    return {
        load,
        loadObjectData,
        saveObjectData,
        options,
        changeData,
        // destroyIt,
    };
}

export default {
    useFormObjectStorage,
}