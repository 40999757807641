import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ProjectCard" }
const _hoisted_2 = { class: "ProjectCard__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: '/' + _ctx.team.nameUrl + '/' + _ctx.application.nameUrl + '/projects/' + _ctx.project.key
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.project.name), 1)
        ]),
        _: 1
      }, 8, ["to"])
    ])
  ]))
}