
import { mapActions, mapGetters } from "vuex";
import { defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import { LOGIN } from "@/store/authentication/action.types";
import teamService from "@/services/team.service.js";

export default defineComponent({
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      submited: false,
      isLoading: false,
      error: "",
    };
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    let applicationKey = ref();
    let teamKey = ref();

    let team = ref(null);
    let application = ref(null);
    let project = ref(null);

    const TEAM = "team";
    const APPLICATION = "application";
    const PROJECT = "project";

    let loginProps = ref({
      [TEAM]: {
        data: team,
        parent: null,
      },
      [APPLICATION]: {
        data: application,
        parent: TEAM,
      },
      [PROJECT]: {
        data: project,
        parent: APPLICATION,
      },
    });

    // find team details by team key
    let getTeam = async function (key) {
      let team = await teamService.getTeam(key);
      return team;
    };

    // find team details by team key
    let getApplication = async function (key) {
      let team = await teamService.getTeam(key);
      return team;
    };

    onMounted(async () => {
      teamKey.value = route.query.team;
      applicationKey.value = route.query.application;

      // find team details by team key from the query param
      team.value = await getTeam(teamKey.value);

      // find application details by team key from the query param
      application.value = await getApplication(applicationKey.value);
    });

    // is called when delete team or application
    // from query (click X on block of login props)
    watch(
      () => route.query,
      (newVal) => {
        if (!newVal) return;

        teamKey.value = route.query.team;
        applicationKey.value = route.query.application;
      },
      { immediate: true }
    );

    let loginPropsRemoveChildren = function (listName, name) {
      let loginProp = loginProps.value[name];

      if (loginProp.data == null) return listName;

      loginProp.data = null;

      let hasChild = hasChildLoginProp(name);
      if (hasChild != null) {
        listName = loginPropsRemoveChildren(listName, hasChild);
      }

      return { ...listName, [name]: undefined };
    };

    let removeLoginPropsQuery = function (name) {
      let listName = {};

      let newListName = loginPropsRemoveChildren(listName, name);
      listName = { ...listName, ...newListName };

      let query = { ...route.query, ...listName };

      router.replace({
        ...router.currentRoute,
        query: query,
      });
    };

    let hasChildLoginProp = function (name) {
      for (const [key, value] of Object.entries(loginProps.value)) {
        if (value.parent == name) return key;
      }
    };

    let handleLoginSubmit = function () {
      this.error = "";
      this.submitted = true;
      const { username, password } = this;

      if (!username || !password) {
        this.error = "Email a heslo musí být vyplněno.";
        return false;
      }

      this.isLoading = true;
      this.login({ username, password })
        .then(
          (user) => {
            if (user == null) return;

            let returnUrl = route.query.returnUrl as string;
            if (returnUrl != null) {
              router.push(decodeURIComponent(returnUrl));
            } else {
              redirectUserByLoginProps();
            }
          },
          (error) => {
            this.isLoading = false;
            this.error = error.response.data.errors.join(",");
          }
        )
        .catch((error) => {
          console.log("Chyba", error);
        });
    };

    let redirectUserByLoginProps = function () {
      let teamUrl = loginProps.value[TEAM]?.data?.name;
      let applicationKeyUrl = loginProps.value[APPLICATION]?.data?.key;

      if (teamUrl != null && applicationKeyUrl != null) {
        router.push(`/${teamUrl}/${applicationKeyUrl}/projects`); // redirect to app page for select which app user want administrate
      } else {
        router.push("/");
      }
    };

    return {
      teamKey,
      applicationKey,
      removeLoginPropsQuery,
      team,
      application,
      loginProps,
      TEAM,
      APPLICATION,
      PROJECT,
      handleLoginSubmit,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions([LOGIN]),
  },
});
