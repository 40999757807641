
import { mapGetters, useStore } from "vuex";
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "FormNavigation",
  data() {
    return {
      open: false,
      fid: this.$route.query.fid,
      sid: this.$route.query.sid,
    };
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.fid = to.query.fid;
        this.sid = this.$route.query.sid;
      }
    },
  },
  computed: {
    ...mapGetters(["formSections", "formSection", "formSectionData"]),
  },
  methods: {
    querySection: function (sid) {
      const query2 = Object.assign({}, this.$route.query, {
        sid: sid,
      });

      return query2;
    },
    isFormDataFieldValid(field) {
      if (
        this.formSectionData &&
        Object.keys(this.formSectionData).length === 0 &&
        this.formSectionData.constructor === Object
      )
        return false;

      if (!this.formSectionData || this.formSectionData.length >= 0)
        return false;

      const fieldName = field.name;
      const formData = this.formSectionData[fieldName];

      if (formData == null || formData == "") return false;

      return true;
    },
    findInput(field) {
      let offsetElement: any = document.querySelector("#" + field.name);
      if (offsetElement != null) {
        let offset = offsetElement.getBoundingClientRect().top;

        offsetElement.style.transition = "1s";
        offsetElement.style.background = "#dddddd";

        setTimeout(() => {
          offsetElement.style.background = "white";
        }, 1750);

        let content = document.querySelector(".content");
        let contentScrollTop = content.scrollTop;

        let input = offsetElement.querySelectorAll("input, textarea")[0];
        // var x = content.scrollX,
        //   y = content.scrollY;
        input.focus();
        // content.scrollTo(x, y);

        content.scrollTo({
          top: offset - 42 + contentScrollTop - 250, // your adjustments
          behavior: "smooth",
        });
      }
    },
  },

  setup() {
    const store = useStore();
    const route = useRoute();

    const formSectionId = computed(() => route.query.sid);
    const formDataErrors = computed(() => store.getters.formDataErrors);

    const getFieldErrors = (field) => {
      let errors = formDataErrors.value.filter(s => s.sectionId == formSectionId.value && s.fieldId == field.id);
      return errors;
    };

    const hasFieldError = (field) => {
      let fieldErrors = getFieldErrors(field);
      return fieldErrors.length != null && fieldErrors.length > 0;
    };

    const getSectionErrors = (section) => {
      let errors = formDataErrors.value.filter(s => s.sectionId == section.id);
      return errors;
    };

    const hasSectionError = (section) => {
      let sectionErrors = getSectionErrors(section);
      return sectionErrors.length != null && sectionErrors.length > 0;
    }

    return {
      hasFieldError,
      hasSectionError,
    };
  }
});
