
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  watch,
  computed,
} from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "FormHistoryTab",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const fpfsvid = ref();

    const formPackageSchemeVersions = computed(
      () => store.getters.formPackageSchemeVersions
    );

    onMounted(() => {
      fpfsvid.value = route.query.fpfsvid;
    });

    watch(
      () => route.params,
      (from, to) => {
        fpfsvid.value = route.query.fpfsvid;
      }
    );

    const changeForm = (version) => {
      router.push({
        name: "FormSection",
        query: {
          fpfsvid: version.id,
          fsvid: version.formSchemeVersionId,
          fid: version.formId,
        },
      });
    };

    return {
      formPackageSchemeVersions,
      changeForm,
      fpfsvid,
    };
  },
});
