
import { defineComponent } from "vue";

export default defineComponent({
  name: "HeadNavigation",
  data() {
    return {
      open: false,
    };
  },
  methods: {
    closeWindow() {
      this.open = false;
    },
  },
});
