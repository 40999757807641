import { IControl } from "../interfaces/IControl";

export default class NumberControl implements IControl<Number> {
    props: any;
    emit: any;

    value: number;

    constructor(props: any, emit: any) {
        this.props = props;
        this.emit = emit;
    }

    checkData(number: Number): boolean {
        let isValid: boolean = false;

        if (!number)
            return isValid;

        isValid = true;

        return isValid;
    }
}
