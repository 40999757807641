import actions from './actions';
import mutations from './mutations';
import state from './state';

const getters = {
    objectData(state) {
        return state.objectData;
    },
    objectDataLoading(state) {
        return state.objectDataLoading;
    },
}

export default {
    state,
    actions,
    mutations,
    getters
};