
/* eslint-disable */

import { defineComponent, computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from 'vue-router';

import Table from "@/views/table/Table.vue";
import ModalDialog from '@/views/components/ModalDialog.vue';

import { GET_FORM_PACKAGES, ADD_FORM_PACKAGE, DELETE_FORM_PACKAGE } from "@/store/formPackage/action.types";

import ControlWrapper from "./controls/ControlWrapper.vue";

const components = {
  ModalDialog,
  Table,
  ControlWrapper,
};

export default defineComponent({
  name: "FormPackages",
  components,
  setup() {
    const store = useStore();

    const router = useRouter();
    const route = useRoute();
    const showModal = ref(false);

    let formPackageAddName = ref();

    const structure = [
      {
        name: "Id",
        objectName: "formPackageId",
        click: (item) => {
          route.params.formPackageId = item.formPackageId;
          router.push({
            name: 'Package',
            params: {
              ...route.params,
            },
          });
        },
      },
      {
        name: "Datum vytvoření",
        objectName: "createdDate",
        render: (createdDate) => {
          return new Date(createdDate).toLocaleString()
        },
      },
      {
        name: "Název",
        objectName: "name",
      },
      {
        name: "Autor",
        objectName: "author",
        render: (author) => {
          return author.firstName + " " + author.lastName;
        },
      },
    ];

    const actions = [
      {
        name: "Otevřít",
        click: (column) => {
          route.params.formPackageId = column.formPackageId;
          router.push({
            name: 'Package',
            params: {
              ...route.params,
            },
          });
        },
      },
      {
        name: "Smazat",
        click: (column) => {
          let formPackageId = column.formPackageId;
          var addFormPackage = store.dispatch(DELETE_FORM_PACKAGE, formPackageId);
          addFormPackage.then(() => {
            store.dispatch(GET_FORM_PACKAGES);
          });
        },
      }
    ];

    const addFormPackage = function () {
      const project = route.params.projectId;
      // showModal.value = true;

      let packageName = formPackageAddName.value ?? "Defaultni nazev";

      var addFormPackage = store.dispatch(ADD_FORM_PACKAGE, { name: packageName, project: project });
      addFormPackage.then((response) => {
        var packageId = response.data;

        route.params.formPackageId = packageId;
        router.push({
          name: 'Package',
          params: {
            ...route.params,
          },
        });
      })
    }

    const closeModal = function () {
      showModal.value = false;
    }

    onMounted(() => {
      store.dispatch(GET_FORM_PACKAGES);
    });

    const tableSetting = {
      title: "Formulářové balíčky",
    }

    return {
      formPackages: computed(() => store.getters.formPackages),
      structure,
      actions,
      addFormPackage,
      showModal,
      closeModal,
      tableSetting,
      formPackageAddName,
    }
  }
});
