<template>
  <teleport to="body">
    <transition name="fade4" mode="out-in">
      <div id="popup_target" v-if="showModal">
        <div class="dialog_container">
          <div class="dialog_wrapper" role="dialog" ref="modal">
            <slot></slot>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>
<script>
import { ref, watch } from "vue";

import useClickOutside from "@/composables/useClickOutside.js";

const props = {
  show: {
    type: Boolean,
    default: false,
  },
};
export default {
  name: "ModalDialog",
  props,
  emits: ['close'],
  setup(props, context) {
    const showModal = ref(false);
    const modal = ref(null);
    const { onClickOutside } = useClickOutside();

    watch(
      () => props.show,
      (show) => {
        showModal.value = show;
      }
    );

    const closeModal = function () {
      context.emit("close");
    };

    onClickOutside(modal, () => {
      if (showModal.value === true) {
        closeModal();
      }
    });

    return {
      showModal,
      modal,
      closeModal,
    };
  },
};
</script>

<style lang="scss">
.fade4-enter-active,
.fade4-leave-active {
  transition: opacity 0.2s ease;
}

.fade4-enter-from,
.fade4-leave-to {
  opacity: 0;
}

#popup_target {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  z-index: 999999999;

  .dialog_container {
    text-align: center;
    margin-top: 40px;

    .dialog_wrapper {
      background: white;
      display: inline-block;
      padding: 25px;
    }
  }
}
</style>