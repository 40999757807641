
import { defineComponent, toRefs } from "vue";

export default defineComponent({
  name: "InjuryStatisticsCard",
  props: {
    injuryStatisticsModel: Object,
  },
  setup(props) {
    // turn `props` into an object of refs, then destructure
    const { injuryStatisticsModel } = toRefs(props);

    return {
      injuryStatisticsModel,
    };
  },
});
