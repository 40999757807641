import {
    SET_CURRENT_COMPANY,
    SET_MY_USER_OPERATIONS,
} from "./mutation.types";

const mutations = {
    [SET_CURRENT_COMPANY](state, opeartion) {
        state.currentCompany = opeartion;
    },
    [SET_MY_USER_OPERATIONS](state, operations){
        state.myUserOperations = operations;
    }
}

export default mutations;