<template>
  <div v-bind:class="{ open: false }">
    <Menu :menu="menu" />
    <Head v-on:toggleMenu="toggleMenu">
      <template #companySelect>
        <div class="head">
          <HeadCompanySelect />
        </div>
      </template>
    </Head>
    <transition name="fade">
      <div class="content">
        <Breadcrumb />
        <div class="container">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Breadcrumb from "./breadcrumb/Breadcrumb.vue";

import Head from "./head/Head.vue";
import HeadCompanySelect from "./head/HeadCompanySelect.vue";

import Menu from "./menu/Menu.vue";

import { GET_PROJECT } from "@/store/project/action.types.js";
import { SET_PROJECT } from "@/store/project/mutation.types.js";
import { mapActions, mapGetters, mapMutations } from "vuex";

import { menuManager } from "@/router/menuManager";

import { useDetectMobileDevice } from "@/common/detectMobileDevice.js";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  setup() {
    const { isMobileDevice, detectMobileDevice } = useDetectMobileDevice();
    let isOpen = ref(true);

    onMounted(() => {
      // check if device is mobile
      detectMobileDevice();

      // if it is mobile device, close menu on page load
      if (isMobileDevice.value) {
        isOpen.value = false;
      }
    });

    return {
      isMobileDevice,
      isOpen,
    };
  },
  computed: {
    ...mapGetters(["currentCompany"]),
    menu() {
      let teamKey = this.$route.params.team;
      let applicationKey = this.$route.params.application;

      return menuManager.getUserMenu(teamKey, applicationKey);
    },
  },
  methods: {
    ...mapActions([GET_PROJECT]),
    ...mapMutations([SET_PROJECT]),
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
  },
  components: { Menu, Head, Breadcrumb, HeadCompanySelect },
  watch: {
    $route(to, from) {
      // this.isOpen = false;
    },
  },
  created() {
    this.setProject({});
  },
});
</script>

<style lang="scss">
html,
body {
  /* Positioning */
  padding: 0;
  margin: 0;

  /* Other */
  color: #6d6d6d;
  background-color: #eff1f5;
  font-size: 12px;
}

input {
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
}

a {
  text-decoration: none;
  color: #6d6d6d;
}

#app {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #5b5b5b;
}

// .fade-enter-active,
// .fade-leave-active {
//   transition: opacity 0.4s;
// }
// .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
//   opacity: 0;
// }
</style>
