import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "Description" }
const _hoisted_3 = {
  class: "Description__title",
  for: "inputText"
}
const _hoisted_4 = {
  key: 0,
  class: "Description__text"
}
const _hoisted_5 = {
  key: 1,
  class: "Description__text"
}
const _hoisted_6 = { class: "InputControl" }
const _hoisted_7 = {
  key: 0,
  class: "InputControl_errors"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "input_container",
    id: _ctx.details.name
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.details.title), 1)
      ]),
      (_ctx.details.description)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.details.description), 1))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, " Lorem ipsum dolor sit amet, consectetur "))
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", {
        class: _normalizeClass(["InputControl__center", { 'error': _ctx.hasError }])
      }, [
        _renderSlot(_ctx.$slots, "default"),
        (_ctx.hasError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors.map(s => s.error), (item, i) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "InputControl__error",
                  key: i
                }, _toDisplayString(item), 1))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ], 2)
    ])
  ], 8, _hoisted_1))
}