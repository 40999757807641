
import { defineComponent, ref, computed, watch } from "vue";
import { mapGetters, mapActions, useStore } from "vuex";

import { LOGOUT } from "@/store/authentication/action.types";

import { userService } from "@/services/user.service.js";

export default defineComponent({
  name: "HeadAccount",
  setup() {
    const store = useStore();

    let user = computed(() => store.getters.user);
    let userProfile = ref();

    watch(user,
      async (userVal) => {
        userProfile.value = (await userService.getPerson(userVal.userId)).data;
      },
      {
        immediate: true,
      });


    let open = ref(false);

    let closeWindow = () => {
      open.value = false;
    }

    let logout = () => {
      store.dispatch(LOGOUT);
    };

    return {
      open,
      user,
      userProfile,
      closeWindow,
      logout,
    }
  },
});
