
import { defineComponent, ref, onMounted } from "vue";

import TeamCard from "./TeamCard.vue";

import teamHubService from "@/services/teamHub.service.js";

export default defineComponent({
  name: "Form",
  components: { TeamCard },
  setup() {
    let myTeams = ref();
    let teams = ref([]);

    onMounted(async () => {
      myTeams.value = (await teamHubService.getTeams()).data;

      // parallel
      await Promise.all(
        myTeams.value.map(async (item, i) => {

          let team = await findTeam(item.id);
          teams.value[i] = team;
        })
      );
    });

    const findTeam = async function (teamId) {
      let team = (await teamHubService.getTeam(teamId)).data;
      return team;
    };

    return {
      teams,
    };
  },
});
