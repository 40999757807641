import actions from './actions';
import mutations from './mutations';
import state from './state';

const getters = {
    formSectionData(state) {
        return state.formSectionData.formData;
    },
    multipleChanges(state) {
        return state.multipleChanges;
    },
    formDataErrors(state) {
        return state.formDataErrors;
    },
    formDataErrors2(state) {
        return state.formDataErrors2;
    },
    lastFormSavedDate(state) {
        return state.lastFormSavedDate;
    },
}

export default {
    state,
    actions,
    mutations,
    getters
};