<template>
	<div>
		<div v-if="USA && (injuryBodyPartTypesStatistics && injuryBodyPartTypesStatistics.length > 0)">
			<svg-map :bodyPartTypes="injuryBodyPartTypesStatistics" :map="USA" :location-class="getLocationClass"
				@mouseover="pointLocation" @mouseout="unpointLocation" @mousemove="moveOnLocation" />
			<div class="tooltip" :style="tooltipStyle">
				{{ pointedLocation }}
				<span v-show="!pointedLocation">Nespecifikováno</span>
			</div>
		</div>
	</div>
</template>

<script>
import {
	defineComponent,
	computed,
	watch,
	ref,
	onMounted,
	onUnmounted,
	defineProps,
	toRefs,
} from "vue";

import SvgMap from "./svg-map.vue";
import USA from "./front";

export default defineComponent({
	components: {
		SvgMap,
	},
	props: {
		injuryBodyPartTypesStatistics: Array
	},
	setup(props) {
		let pointedLocation = ref();
		let tooltipStyle = ref();

		const { injuryBodyPartTypesStatistics } = toRefs(props)

		const pointLocation = (event) => {
			console.log(getLocationName(event.target));
			pointedLocation.value = getLocationName(event.target)
		};


		const getLocationName = (node) => {
			console.log(node);
			if (!node || !node.attributes.name || !node.attributes.name.value)
				return "";
			return node && node.attributes.name.value;
		}

		const unpointLocation = (event) => {
			pointedLocation.value = null
			tooltipStyle.value = { display: 'none' }
		};
		const moveOnLocation = (event) => {
			console.log(event);
			tooltipStyle.value = {
				display: 'block',
				top: `${event.clientY + 10}px`,
				left: `${event.clientX - 100}px`,
			}
		};
		const getLocationClass = (location, index) => {
			// Generate heat map
			return `svg-map__location svg-map__location--heat${index % 4}`
		};

		// // onMounted(() => {

		// // });

		return {
			injuryBodyPartTypesStatistics,
			pointLocation,
			unpointLocation,
			moveOnLocation,
			getLocationClass,
			USA,
			pointedLocation,
			tooltipStyle,
		}
	},

});

</script>

<style lang="scss">
.red {
	fill: #aa1f27 !important;
}


.tooltip {
	position: fixed;
	width: 200px;
	padding: 10px;
	border: 1px solid darkgray;
	background-color: white;
}
</style>