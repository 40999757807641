import {
    LOGIN_FAILURE,
    LOGIN_SUCCESS,
    LOGOUT_REQUEST,
} from "./mutation.types";

const mutations = {
    [LOGIN_FAILURE](state, error) {
        state.status = {};
        state.user = null;
    },
    [LOGIN_SUCCESS](state, user) {
        state.status = {
            loggedIn: true
        };
        state.user = user;
    },
    [LOGOUT_REQUEST](state) {
        state.status = {};
        state.user = null;
    }
}

export default mutations;