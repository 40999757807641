
import { defineComponent, ref } from "vue";

import ProjectCard from "./ProjectCard.vue";

const props = {
  application: {
    type: Object,
  },
  team: {
    type: Object,
  },
};

export default defineComponent({
  name: "Form",
  components: { ProjectCard },
  props,
  setup(props) {
    const application = ref(props.application);
    const team = ref(props.team);

    return {
      application,
      team,
    };
  },
});
