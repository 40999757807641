<template>
	<svg xmlns="http://www.w3.org/2000/svg" :viewBox="map.viewBox" :aria-label="map.label" class="svg-map">
		<slot name="before" />
		<path v-for="(location, index) in map.locations" :id="location.id" :key="location.id" :name="getName(location)"
			:d="location.path"
			:class="[(isLocationClassFunction ? locationClass(location, index) : locationClass), (bodyPartTypes.some(s => location.dataId == null ? false : location.dataId.split(',').map(item => parseInt(item) ? parseInt(item) : item).includes(s.id)) ? 'red' : 'nered')]"
			:tabindex="isLocationTabindexFunction ? locationTabindex(location, index) : locationTabindex"
			:role="locationRole" :aria-label="location.name"
			:aria-checked="isLocationSelected && isLocationSelected(location, index)"
			v-bind="getLocationCustomProperties(location)" :style="{ 'fill-opacity': getColorOpacity(location) }">
			{{ getColorOpacity(location) }}
		</path>
		<slot name="after" />
	</svg>
</template>

<script>
const LOCATION_DEFAULT_PROPERTIES = ['id', 'name', 'path']
export default {
	name: 'SvgMap',
	props: {
		map: {
			type: Object,
			required: true,
			// validator(map) {
			// 	return typeof map.viewBox === 'string' &&
			// 		Array.isArray(map.locations) &&
			// 		map.locations.every(location => typeof location.path === 'string' && typeof location.id === 'string')
			// },
		},
		locationClass: {
			type: [String, Function],
			default: null,
		},
		locationTabindex: {
			type: [String, Function],
			default: null,
		},
		locationRole: {
			type: String,
			default: null,
		},
		isLocationSelected: {
			type: Function,
			default: null,
		},
		bodyPartTypes: {
			type: Array,
			default: [],
		},
	},
	computed: {
		isLocationClassFunction() {
			return typeof this.locationClass === 'function'
		},
		isLocationTabindexFunction() {
			return typeof this.locationTabindex === 'function'
		},
		// bodyPartTypesWithCount() {
		// 	console.log(this.bodyPartTypes);
		// 	if (!this.bodyPartTypes || this.bodyPartTypes.length <= 0)
		// 		return {};

		// 	let sortedDistincValues = this.bodyPartTypes.map(item => item.count).filter((value, index, array) => array.indexOf(value) === index).sort((a, b) => b - a);

		// 	return sortedDistincValues.map(s=> s / sortedDistincValues.length);
		// }
	},
	methods: {
		/**
		 * Get custom properties of a location to add custom attributes to <path>
		 *
		 * @param {Object} location - Location to parse
		 * @returns {Object} Custom properties
		 */
		getLocationCustomProperties(location) {
			// Filter default properties to avoid invalid/duplicated attributes
			return Object.fromEntries(
				Object.entries(location).filter(([key]) => !LOCATION_DEFAULT_PROPERTIES.includes(key))
			)
		},
		getCount(location) {
			let foundObject = this.bodyPartTypes.filter(s => location.dataId == null ? false : location.dataId.split(',').map(item => parseInt(item) ? parseInt(item) : item).includes(s.id));

			if (foundObject == null || foundObject.length <= 0)
				return null;

			let highestValueObj = foundObject.sort((a, b) => b.count - a.count)[0];

			return highestValueObj.count;
			// return foundObject.map(item => item.count).reduce((prev, next) => prev + next);
		},
		getName(location) {
			var count = this.getCount(location);

			if (!location.dataName)
				return null;

			if (count)
				return location.dataName + " (" + count + ")";

			return location.dataName;
		},
		getColorOpacity(location) {
			// console.log(this.bodyPartTypes);
			// console.log(location);
			let foundObject = this.bodyPartTypes.filter(s => location.dataId == null ? false : location.dataId.split(',').map(item => parseInt(item) ? parseInt(item) : item).includes(s.id));

			let highestValueObj = foundObject.sort((a, b) => b.count - a.count)[0];

			if (!highestValueObj)
				return 0.1;

			console.log(foundObject);
			console.log(location);

			let highestValue = highestValueObj.count;

			console.log(highestValue);

			let sortedDistincValues = this.bodyPartTypes.map(item => item.count).filter((value, index, array) => array.indexOf(value) === index).sort((a, b) => b - a);
			console.log(sortedDistincValues);

			let opacityValue = highestValue / sortedDistincValues.length;
			console.log(opacityValue);

			return opacityValue;
		}
	},
}
</script>
