
import { defineComponent } from "vue";
import Table from "@/views/table/Table.vue";

export default defineComponent({
  name: "CompanyTable",
  data() {
    return {
      structure: [
        {
          name: "Datum Založení",
          objectName: "dateFrom",
          render: (datum) => {
            return datum + "a";
          },
        },
        {
          name: "Název",
          objectName: "name",
        },
        {
          name: "Licence",
          objectName: "licenseType",
        },
        {
          name: "IČO",
          objectName: "ico",
        },
        {
          name: "Adresa",
          objectName: "address",
        },
      ],
      data: [
          {
              dateFrom: "24.4.2020",
              name: "Safeko s.r.o.",
              licenseType: "Basic",
              ico: "444 5757 8879",
              address: "Uliční lice přeslice, Na Mažonu",
          },
          {
              dateFrom: "24.4.2020",
              name: "Safeko s.r.o.",
              licenseType: "Basic",
              ico: "444 5757 8879",
              address: "Uliční lice přeslice, Na Mažonu",
          },
          {
              dateFrom: "24.4.2020",
              name: "Safeko s.r.o.",
              licenseType: "Basic",
              ico: "444 5757 8879",
              address: "Uliční lice přeslice, Na Mažonu",
          },
          {
              dateFrom: "24.4.2020",
              name: "Safeko s.r.o.",
              licenseType: "Basic",
              ico: "444 5757 8879",
              address: "Uliční lice přeslice, Na Mažonu",
          }
      ]
    };
  },
  components: {
    Table,
  },
});
