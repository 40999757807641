
import { defineComponent, ref, computed, watch, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "FormHistoryBlocks",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const fpfsvid = ref();


    onMounted(() => {
      console.log("history blocks is mounted");
    });

    onUnmounted(() => {
      console.log("history blocks is UN mounted");
    });

    const formPackageSchemeVersions = computed(
      () => store.getters.formPackageSchemeVersions
    );

    const changeForm = (version) => {
      router.replace({
        name: "FormSection",
        query: {
          fpfsvid: version.id,
          fsvid: version.formSchemeVersionId,
          fid: version.formId,
        },
      });
    };

    // if there is only one version of form
    // immediately redirect without asking
    watch(
      formPackageSchemeVersions,
      (newval, oldval) => {
        if (newval == null) return;

        console.log("CS", newval);

        if (newval.length == 1) {
          changeForm(newval[0]);
        }
      },
      {
        immediate: true,
      }
    );

    return {
      formPackageSchemeVersions,
      changeForm,
    };
  },
});
