
import { defineComponent, onMounted, ref } from "vue";
import { useRouter, useRoute } from 'vue-router';

import Table from "@/views/table/Table.vue";

import formPackageService from "@/services/formPackage.service";

export default defineComponent({
  name: "InjuryTable",
  setup() {
    const router = useRouter();
    const route = useRoute();

    let injuries = ref();

    onMounted(async () => {
      let injuryRequest = await formPackageService.getObjects();
      if (injuryRequest != null && injuryRequest.data != null) {
        injuries.value = injuryRequest.data;
      }
    });

    const structure = [
      {
        name: "Jméno",
        getLabel: (option) => {
          console.log(option.data);
          return option?.data?.PersonStorage?.name || "";
        },
      },
      {
        name: "Datum úrazu",
        getLabel: (option) => option.data?.injuryStorage?.Date || "",
      },
      {
        name: "Datum narozeni",
        getLabel: (option) => option.data?.PersonStorage?.birthDate || "",
      },
      {
        name: "Osobní číslo",
        getLabel: (option) => option.data?.PersonStorage?.personNumber || "",
      },
      {
        name: "Trvalé bydliště",
        getLabel: (option) => option.data?.PersonStorage?.residenceAddress || "",
      },
      {
        name: "Datum úmrtí",
        getLabel: (option) => option.data?.injuryStorage?.DateOfDeath || "",
      },
      {
        name: "Form package",
        getLabel: (option) => option.formPackageId,
        click: (column) => {
          route.params.formPackageId = column.formPackageId;
          router.push({
            name: 'Package',
            params: {
              ...route.params,
            },
          });
        },
      },
    ];

    return {
      injuries,
      structure,
    };
  },
  components: {
    Table,
  },
});
