import actions from './actions';
import mutations from './mutations';
import state from './state';

const getters = {
    user(state) {
        return state.user;
    }
}

export default {
    state,
    actions,
    mutations,
    getters
};