import { SET_OBJECT_DATA_FIELD } from "@/store/formObjectData/mutation.types";
import { computed } from "vue";
import {
  SAVE_OBJECT_DATA,
} from "@/store/formObjectData/action.types";

import route from "@/router/index.ts";
import store from "@/store/index.js";

export const pepa = (value) => {
  console.log(store);
  console.log(store.getters);

  const objectSchemes = computed(() => store.getters.formTabObjects);

  let objectField = objectSchemes.value.find((s) => s.id == 11).fields.find(s => s.id == 70);

  store.commit(SET_OBJECT_DATA_FIELD, {
    type: "storage",
    objectId: objectSchemes.value.find((s) => s.id == 11).id,
    data: { [objectField.name]: value },
  });

  saveData(objectSchemes.value.find((s) => s.id == 11));
};

const saveData = (objectSchema) => {
  const objectAll = computed(() => {
    const objectData = store.getters.objectData;
    if (!objectData) return null;

    return objectData[objectSchema.id];
  });
  const formPackage = route.currentRoute.value.params.formPackageId;
  const projectId = route.currentRoute.value.params.projectId;

  let model = {
    ...objectAll.value,
    formObjectSchemeId: objectSchema.id,
    formPackageId: formPackage,
    project: projectId,
  };
  const formSchemeRequest = store.dispatch(
    SAVE_OBJECT_DATA,
    model,
  );

};

export const getData = () => {
  let formTabObjects = store.getters.formTabObjects;

  if (!formTabObjects || formTabObjects.length <= 0)
    return null;

  let objectSchema = formTabObjects.find((s) => s.id == 11);

  if (!objectSchema)
    return null;

  let objectField = objectSchema.fields.find(s => s.id == 70);

  const objectData = store.getters.objectData;
  if (!objectData)
    return null;

  let objectAll = objectData[objectSchema.id];

  if (!objectField || !objectAll || !objectAll["data"] || !objectAll["data"]["storage"])
    return null;

  return objectAll["data"]["storage"][objectField.name];
};
