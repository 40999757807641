<template>
  <div class="breadcrumb">
    <div class="breadcrumb_container">
      <div class="item">
        <span v-for="(route, index) in $route.matched" :key="index">

          <router-link :to="{name: route.name}">
              <span> {{ route.meta.breadcrumb }} </span>

    </router-link>
          <span v-if="index != $route.matched.length-1"> > </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.breadcrumb {
  height: 45px;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  background: #fff;
  line-height: 45px;
  flex: 0;

  .breadcrumb_container {
    margin-left: 15px;
    font-size: 0.9em;
    font-weight: 600;
  }
}
</style>