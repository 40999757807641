<template>
  <div class="project_setting_container">
    <div class="CardFormPage">
      <div class="CardFormPage__title">Informace o projektu</div>
      <div class="CardFormPage__description">
        Informace o projektu, ve kterém se právě nacházíte
      </div>
    </div>
    <div class="CardForm UserBasics">
      <div class="CardForm__title">Základní informace</div>
      <div class="CardForm__description"></div>
      <div class="control">
        <ControlWrapper :details="{ name: 'name', title: 'Název', description: '' }" :errors="[]">
          <input class="FormControl" type="text" v-model="name" @input="updateProject" />
        </ControlWrapper>
      </div>
      <div class="control">
        <ControlWrapper :details="{ name: 'key', title: 'Klíč', description: 'Jedinečný klíč projektu' }" :errors="[]">
          <input class="FormControl" type="text" :value="project.key" disabled />
        </ControlWrapper>
      </div>
      <div class="control">
        <ControlWrapper
          :details="{ name: 'key', title: 'Název organizace', description: 'Název organizace, ke které daný projekt patří' }"
          :errors="[]">
          <input class="FormControl" type="text" :value="project.team.name" disabled />
        </ControlWrapper>
      </div>
    </div>
    <div class="CardForm UserBasics">
      <div class="CardForm__title">Informace o subjektu</div>
      <div class="CardForm__description"></div>
      <div class="control">
        <ControlWrapper
          :details="{ name: 'formPackageAddName', title: 'Vyhledávání subjektu', description: 'Zadejte název firmy a vyberte jednu z možností' }"
          :errors="[]">
          <lit-autocomplete :fulllist.prop="fulllist" :label.prop="'Vyhledávání'" :textFilterPropFun="expressionFun"
            v-on:selected-autocomplete="setSelectedValue" v-on:input-change-autocomplete="changeInputValue">
          </lit-autocomplete>
        </ControlWrapper>
      </div>
      <div class="control">
        <ControlWrapper :details="{ name: 'name', title: 'Název', description: '' }" :errors="[]">
          <input class="FormControl" type="text" v-model="companyName" @input="updateProjectCompany" />
        </ControlWrapper>
      </div>
      <div class="control">
        <ControlWrapper :details="{ name: 'ico', title: 'IČO', description: '' }" :errors="[]">
          <input class="FormControl" type="text" v-model="companyIco" @input="updateProjectCompany" />
        </ControlWrapper>
      </div>
      <div class="control">
        <ControlWrapper :details="{ name: 'address', title: 'Adresa', description: '' }" :errors="[]">
          <input class="FormControl" type="text" v-model="companyAddress" @input="updateProjectCompany" />
        </ControlWrapper>
      </div>
    </div>
  </div>
</template>

<script lang="js">
/* eslint-disable */

import { computed, defineComponent, ref } from "vue";

import '@/assets/setting.scss';
import { useStore } from "vuex";

import { UPDATE_PROJECT, UPDATE_PROJECT_COMPANY } from "@/store/project/action.types";
import { SET_PROJECT_COMPANY, UPDATE_PROJECT_COMPANY_FIELD } from "@/store/project/mutation.types";

import "../../form/autocomplete.js";

import ControlWrapper from "../../form/controls/ControlWrapper.vue";

import aresServiceApi from "@/services/ares.service";

const components = {
  ControlWrapper,
};

export default defineComponent({
  name: "ProjectSetting",
  components,
  setup() {
    const store = useStore();

    let fulllist = ref(null);
    // let company = ref({});

    const project = computed(() => store.getters.project);

    Object.byString = function (o, s) {
      s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
      s = s.replace(/^\./, '');           // strip a leading dot
      var a = s.split('.');
      for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
          o = o[k];
        } else {
          return;
        }
      }
      return o;
    }

    const useFormFields = (initialValues) => {

      if (!initialValues)
        return {};

      const formFields = {};

      // Pomocná funkce pro rekurzivní vytváření Computed Properties
      const createComputedFields = (fieldObj, fieldPrefix = '') => {
        if (!fieldObj)
          return;
        for (const key of Object.keys(fieldObj)) {
          let fieldPath = fieldPrefix ? `${fieldPrefix}.${key}` : key;
          let fieldObjectPath = fieldPrefix ? `${fieldPrefix}.${fieldObj[key]}` : fieldObj[key];

          if (typeof fieldObj[key] === 'object' && !Array.isArray(fieldObj[key])) {
            createComputedFields(fieldObj[key], fieldPath);
          } else {

            let computedValue = computed({
              get: () => Object.byString(store.getters.project, fieldObjectPath),
              set: (value) => {
                console.log("PRACUJU NE");
                store.commit(UPDATE_PROJECT_COMPANY_FIELD, { fieldObjectPath, value });
              },
            });

            formFields[fieldPath] = computedValue;

          }
        }
      };

      createComputedFields(initialValues);

      return formFields;
    }

    let aha = useFormFields(
      {
        id: "id",
        name: "name",
        key: "key",
        companyId: "company.id",
        companyName: "company.name",
        companyIco: "company.ico",
        companyAddress: "company.address",
      }
    );


    let jarda = Object.keys(aha)
      .map(function (key) {
        return { [key]: aha[key] };
      });
    console.log(jarda);
    console.log(...jarda);
    // console.log(aha.value.company);

    let updateProjectTimeoutId;
    const updateProject = (event) => {
      // store.commit(SET_PROJECT, { ...project.value, name: event.target.value });

      clearTimeout(updateProjectTimeoutId);
      updateProjectTimeoutId = setTimeout(() => {
        store.dispatch(UPDATE_PROJECT, project.value);
      }, 1000);
    }

    let updateProjectCompanyTimeoutId;
    const updateProjectCompany = () => {
      clearTimeout(updateProjectCompanyTimeoutId);
      updateProjectCompanyTimeoutId = setTimeout(() => {
        store.dispatch(UPDATE_PROJECT_COMPANY, project.value);
      }, 500);
    }

    const setSelectedValue = (event) => {
      const value = event.detail.value;

      let companyTemp = {
        ico: value.icoId,
        address: value.sidlo.textovaAdresa,
        name: value.obchodniJmeno,
      };

      let companyProject = { ...project.value.company, ...companyTemp };

      store.commit(SET_PROJECT_COMPANY, companyProject);

      updateProjectCompany();
    };

    let timeoutId;
    const makeRequest = async (key) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(async () => {
        let request = await aresServiceApi.searchCompany(key);
        fulllist.value = request.data;
      }, 500);
    }

    const changeInputValue = async (event) => {
      const value = event.detail.value;
      await makeRequest(value);
    };

    let expression = "(item) => item.obchodniJmeno + ' (' + item.sidlo.textovaAdresa + ')' ";
    let expressionFun = eval(expression);

    return {
      project,
      updateProject,
      updateProjectCompany,
      expressionFun,
      setSelectedValue,
      changeInputValue,
      fulllist,
      ...aha,
    }
  },
});
</script>

<style lang="scss">
.project_setting_container {
  background: white;
  width: 800px;
  padding: 25px;
  margin-left: 50px;

  .control {
    margin-top: 25px;
  }

  .FormControl {
    width: 100% !important;
  }
}
</style>