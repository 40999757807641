
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "Form",
  components: {},
  computed: {
    ...mapGetters(["formPackage"]),
  },
});
