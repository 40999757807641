import {
    SET_FORM_SECTION_DATA,
    SET_FORM_DATA_ERRORS,
    FORM_DATA_SAVED,
} from "./mutation.types";
import {
    SAVE_FORM_DATA,
    GET_FORM_DATA,
    CHECK_FORM_DATA,
} from "./action.types";

import formDataServiceApi from "@/services/formData.service";

const actions = {
    async [SAVE_FORM_DATA]({ commit }, formData) {
        const fromDataSaveRequest = formDataServiceApi.saveData(formData);
        commit(FORM_DATA_SAVED);
        return fromDataSaveRequest;
    },
    async [GET_FORM_DATA]({ commit }, { formSchemeVersionId, sectionId }) {
        commit(SET_FORM_SECTION_DATA, { formData: {} });

        const formDataRequest = formDataServiceApi.getData(formSchemeVersionId, sectionId);

        formDataRequest.then(function (response) {
            if (!response.data || !response.data.formData)
                commit(SET_FORM_SECTION_DATA, { formData: {} });
            else
                commit(SET_FORM_SECTION_DATA, response.data);

        })
            .catch(function (err) {
                commit(SET_FORM_SECTION_DATA, { formData: {} });
            });

        return formDataRequest;
    },
    async [CHECK_FORM_DATA]({ commit }, formId) {
        const checkFromDataRequest = formDataServiceApi.checkFormData(formId);

        checkFromDataRequest
            .then((response) => {
                commit(SET_FORM_DATA_ERRORS, response.data)
            })
            .catch((err) => {
                commit(SET_FORM_DATA_ERRORS, []);
            });
        return checkFromDataRequest;
    },
}

export default actions;