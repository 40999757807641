import actions from './actions';
import mutations from './mutations';
import state from './state';

import router from "@/router/index.ts";

const getters = {
    formScheme(state) {
        return state.formScheme;
    },
    formSection(state) {
        return state.formSection;
    },
    formSections(state) {
        return state.formScheme.sections;
    },
    formSectionFirst(state) {
        if (state.formScheme == null || state.formScheme.sections == null || router.currentRoute.value.query.fpfsvid == null)
            return null;

        if (router.currentRoute.value.query.sid == null) {
            return state.formScheme.sections[0];
        }

        return state.formScheme.sections.find(element => element.id == router.currentRoute.value.query.sid);
    },
    formSchemesTypes(state) {
        return state.formSchemesTypes;
    }
}

export default {
    state,
    actions,
    mutations,
    getters
};