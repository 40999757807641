
import { computed, defineComponent, onMounted } from "vue";
import { mapGetters, useStore } from "vuex";

import FormHistoryBlocks from "./FormHistoryBlocks.vue";

export default defineComponent({
  name: "FormSchemeInfo",
  setup() {
    const store = useStore();

    const formPackageScheme = computed(() => store.getters.formPackageScheme);

    onMounted(() => {
      console.log("formSchemeInfo is mounted");
    });

    return {
      formPackageScheme,
    };
  },
  components: {
    FormHistoryBlocks,
  },
});
