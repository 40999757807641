
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import { GET_PROJECTS } from "@/store/project/action.types.js";

export default defineComponent({
  name: "HeadCompanySelect",
  data() {
    return {
      open: false,
    };
  },
  methods: {
    closeWindow() {
      this.open = false;
    },
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const teamUrlName = ref();

    watch(
      () => route.params.team,
      (team) => {
        teamUrlName.value = team;

        if (!team)
          return;

        store.dispatch(GET_PROJECTS, teamUrlName.value);
      }, {
      immediate: true,
    });


    onMounted(() => {
    });

    return {
      myUserOperations: computed(() => store.getters.projects),
      project: computed(() => store.getters.project),
      teamUrlName,
    };
  },
});
