import {
    SET_CURRENT_COMPANY,
    SET_MY_USER_OPERATIONS,
} from "./mutation.types";
import {
   GET_CURRENT_COMPANY,
   GET_MY_USER_OPERATIONS,
} from "./action.types";
import OrganizationServiceApi from "@/services/organization.service";

const actions = {
    async [GET_CURRENT_COMPANY]({commit}, normalizedUrlName){
        const getOperationRequest = OrganizationServiceApi.getOperation(normalizedUrlName);
        getOperationRequest
            .then((response) => {
                commit(SET_CURRENT_COMPANY, response.data)
            })
            .catch((err)=>{
                commit(SET_CURRENT_COMPANY, {});
            });
        return getOperationRequest;
    },
    async [GET_MY_USER_OPERATIONS]({commit}){
        const getMyUserOperationsRequest = OrganizationServiceApi.getMyUserOperations();
        
        getMyUserOperationsRequest
            .then((response) => {
                commit(SET_MY_USER_OPERATIONS, response.data)
            })
            .catch((err)=>{
                commit(SET_MY_USER_OPERATIONS, []);
            });
        return getMyUserOperationsRequest;
    },
}

export default actions;