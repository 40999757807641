<template>
  <div class="menu">
    <!-- <div class="head">
      <div class="logo">
        SAFEKO LOGO
        <img src="@/assets/image/logo.svg" alt="" />
      </div>
    </div> -->
    <transition name="fade">
      <div class="menu_block_container" v-if="!isLoading">
        <menu-block v-for="(item, index) in menu" :key="index" :item="item" />
      </div>
    </transition>
  </div>
</template>

<script>
import MenuBlock from "./MenuBlock.vue";
import client from "@/helpers/axios.api";

import { useRoute } from "vue-router";
import { onMounted, ref, reactive, watch } from "vue";

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: false,
    },
    menu: {
      type: Array,
    }
  },
  setup(props) {
    const route = useRoute();
    const menu = ref();
    const breadcrumb = ref();
    let isLoading2 = ref(props.isLoading);

    onMounted(() => {
      menu.value = route.meta.menu;
      breadcrumb.value = route.meta.breadcrumb;
    });

    watch(
      () => route.path,
      () => {
        menu.value = route.meta.menu;
        breadcrumb.value = route.meta.breadcrumb;
        // isLoading2.value = true;
      }
    );

    return {
      // menu,
      breadcrumb,
      isLoading2,
    };
  },
  name: "Menu",
  components: {
    MenuBlock,
  },
  methods: {
    // test() {
    //   client.get("/person/index").then((r) => {
    //     console.log(r);
    //   });
    // },
  },
};
</script>

<style lang="scss">
.menu {
  padding-top: 35px;
  box-shadow: 0 10px 45px rgba(0, 0, 0, 0.1);
}

/*
 * Variables
 */

:root {
  --card-height: 100%;
  --card-skeleton: linear-gradient(
    rgb(255, 255, 255) var(--card-height),
    transparent 0
  );

  --menu-item-height: 40px;
  --menu-item-skeleton: linear-gradient(
    lightgrey var(--menu-item-height),
    transparent 0
  );
  --menu-item-padding: 15px;

  --menu-item-3-width: calc(100% - calc(3 * var(--menu-item-padding)));
  --menu-item-3-position: var(--menu-item-padding) 0;

  --blur-width: 300px;
  --blur-size: var(--blur-width) var(--card-height);
}

/*
 * Card Skeleton for Loading
 */

.menu {
  height: var(--card-height);
  background: white;

  &:empty::after {
    content: "";
    background: white;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 6px;

    background-image: linear-gradient(
        90deg,
        rgba(rgb(255, 255, 255), 0) 0,
        rgba(rgb(255, 255, 255), 0.8) 50%,
        rgba(rgb(255, 255, 255), 0) 100%
      ),
      //animation blur
      var(--menu-item-skeleton),
      //desc3
      var(--card-skeleton) //card
;

    background-size: var(--blur-size),
      var(--menu-item-3-width) var(--menu-item-height), 100% 100%;

    background-position: -300px 0px,
      //animation
      var(--menu-item-3-position),
      //desc3
      0 0 //card
;

    background-repeat: no-repeat;
    animation: loading 2.5s infinite;
  }
}

@keyframes loading {
  to {
    background-position: 300px 0px, var(--menu-item-3-position), 0 0;
  }
}
</style>