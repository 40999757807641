export const GET_FORM_PACKAGE = "getFormPackage";
export const GET_FORM_PACKAGE_SCHEMES = "getFormPackageSchemes";
export const GET_FORM_PACKAGE_SCHEME = "getFormPackageScheme";
export const GET_FORM_PACKAGE_SCHEME_VERSION = "getFormPackageSchemeVersion";
export const GET_FORM_PACKAGES = "getFormPackages";
export const ADD_FORM_PACKAGE = "addFormPackage";
export const ADD_FORM_SCHEME_TO_PACKAGE = "addFormSchemeToPackage";
export const DELETE_FORM_PACKAGE = "deleteFormPackage";
export const DELETE_FORM_PACKAGE_SCHEME = "deleteFormPackageScheme";
export const GET_FORM_TAB_OBJECTS = "getFormTabObjects";
export const GET_FORM_PACKAGES_OBJECTS = "getFormPackagesObjects";