<template>
  <div v-bind:class="{ open: isOpen }">
    <Menu :isLoading="isCompanyLoading" :menu="menu" />
    <Head :isLoading="isCompanyLoading">
      <template #companySelect>
         <div class="head">
          <HeadCompanySelect v-if="!isCompanyLoading" />
        </div>
      </template>
      <template #menuHamburger>
        <div class="icon_container">
          <div
            class="hamburger"
            v-html="require(`../../assets/icons/layout/head/hamburger.svg`)"
            v-on:click="toggleMenu"
          ></div>
        </div>
      </template>
    </Head>
    <!-- <transition name="fade"> -->
    <div class="content" v-if="!isCompanyLoading">
      <Breadcrumb />
      <div class="container">
        <slot />
      </div>
    </div>
    <QuickAction />
    <!-- </transition> -->
  </div>
</template>

<script>
import Breadcrumb from "./breadcrumb/Breadcrumb.vue";

import Head from "./head/Head.vue";
import HeadCompanySelect from "./head/HeadCompanySelect.vue";

import Menu from "./menu/Menu.vue";
import QuickAction from "./quickAction/QuickAction.vue";

import { GET_PROJECT } from "@/store/project/action.types.js";
import { SET_PROJECT } from "@/store/project/mutation.types.js";

import { mapActions, mapGetters, mapMutations } from "vuex";

import { menuManager } from "@/router/menuManager";

import { useDetectMobileDevice } from "@/common/detectMobileDevice.js";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  setup() {
    const { isMobileDevice, detectMobileDevice } = useDetectMobileDevice();
    let isOpen = ref(true);

    onMounted(() => {
      // check if device is mobile
      detectMobileDevice();

      // if it is mobile device, close menu on page load
      if (isMobileDevice.value) {
        isOpen.value = false;
      }
    });

    return {
      isMobileDevice,
      isOpen,
    };
  },
  computed: {
    isCompanyLoading() {
      console.log("PEDUMPADAUM", this.project);
      console.log(this.project &&
        Object.keys(this.project).length === 0 &&
        (this.project.constructor === Object) != null);
        console.log( (this.project.constructor === Object) != null);
        console.log(  Object.keys(this.project).length === 0 );
      return (
        this.project &&
        Object.keys(this.project).length === 0 &&
        (this.project.constructor === Object) != null
      );
    },
    ...mapGetters(["project"]),
    menu() {
      let teamKey = this.$route.params.team;
      let applicationKey = this.$route.params.application;
      let projectId = this.$route.params.projectId;

      return menuManager.getCompanyMenu(teamKey, applicationKey, projectId);
    },
  },
  methods: {
    ...mapActions([GET_PROJECT]),
    ...mapMutations([SET_PROJECT]),
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
  },
  components: { Menu, Head, Breadcrumb, QuickAction, HeadCompanySelect },
  mounted() {
    this.$router.isReady().then(() => {
      const projectId = this.$route.params.projectId;
      if (projectId != null) {
        this.getProject(projectId);
      }
    });
  },
  watch: {
    $route: {
      handler: function (to, from) {
        const projectId = to.params.projectId;
        if (projectId != null && to.meta.layout != "App") {
          this.getProject(projectId);
        }

        // if it is mobile device, close menu on page load
        if (this.isMobileDevice) {
          this.isOpen = false;
        }
      },
      immediate: true,
    },
  },
  created() {
    this.setProject({});
  },
});
</script>

<style lang="scss">
html,
body {
  /* Positioning */
  padding: 0;
  margin: 0;

  /* Other */
  color: #6d6d6d;
  background-color: #eff1f5;
  font-size: 12px;
}

input {
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
}

a {
  text-decoration: none;
  color: #6d6d6d;
}

#app {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #5b5b5b;
}

// .fade-enter-active,
// .fade-leave-active {
//   transition: opacity 0.4s;
// }
// .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
//   opacity: 0;
// }
</style>
