
import InjuryStatisticsCard from "./InjuryStatisticsCard.vue";
import InjuryStatisticsCardLoad from "./InjuryStatisticsCardLoad.vue";
import { loadData, getDataArray } from "./injuryStatistics.js";

import { defineComponent, watch, ref } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "Home",
  components: {
    InjuryStatisticsCard,
    InjuryStatisticsCardLoad,
  },
  async setup() {
    const route = useRoute();
    let injuryStatistics = ref(null);
    let loading = ref(true);
    let statisticsData = ref();

      watch(
      () => route.params.projectId,
      async (projectKey) => {
        if (!projectKey) return;

        loading.value = true;

        statisticsData.value = await loadData(projectKey);
        setTimeout(()=> injuryStatistics.value = getDataArray(statisticsData.value) , 500)

        loading.value = false;

      },
      { immediate: true }
    );

    return {
      injuryStatistics,
      loading,
      statisticsData,
    };
  },
});
