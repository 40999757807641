import {
    SET_OBJECT_SCHEMAS
} from "./mutation.types";
import {
    GET_OBJECT_SCHEMAS
} from "./action.types";
import FormObjectStructureServiceApi from "@/services/formObjectStructure.service";

const actions = {
    async [GET_OBJECT_SCHEMAS]({ commit }, model) {
        const formObjectGetSchemasRequest = FormObjectStructureServiceApi.getObjectSchemas(model.formPackageId);

        formObjectGetSchemasRequest
            .then(function (response) {
                commit(SET_OBJECT_SCHEMAS, response.data);
            })
            .catch(function (err) {
                commit(SET_OBJECT_SCHEMAS, []);
            });

        return formObjectGetSchemasRequest;
    },
}

export default actions;