import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "TeamCard" }
const _hoisted_2 = { class: "TeamCard__content" }
const _hoisted_3 = { class: "TeamCard__title" }
const _hoisted_4 = { class: "TeamCard__setting_link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ApplicationCard = _resolveComponent("ApplicationCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.team.name) + " (TEAM)", 1),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_router_link, {
          to: { name: 'teamSetting', params: { team: _ctx.team.name } },
          target: "_blank"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Nastavení ")
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.team.applications, (item, key) => {
      return (_openBlock(), _createElementBlock("div", { key: key }, [
        _createVNode(_component_ApplicationCard, {
          application: item,
          team: _ctx.team
        }, null, 8, ["application", "team"])
      ]))
    }), 128))
  ]))
}